import React from 'react';
import YouTubeIcon from 'icons/youtube';
import FacebookIcon from 'icons/facebook';
import PinterestIcon from 'icons/pinterest';
import InstagramIcon from 'icons/instagram';
import NewsPaperIcon from 'icons/newspaper';
import LinkedInIcon from 'icons/linkedin';
import styled, { css } from 'react-emotion';
import * as Colors from 'base/colors';

export const NewsPaper = () => (
  <NewsPaperIcon
    className={css`
      width: 25px;
      fill: ${Colors.ctaGreen};
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
    `}
  />
);

export const CommunitySocialIcon = css`
  height: 20px;
  fill: ${Colors.white};
`;

export const YouTube = () => <YouTubeIcon className={CommunitySocialIcon} />;

export const Pinterest = () => (
  <PinterestIcon className={CommunitySocialIcon} />
);

export const Instagram = () => (
  <InstagramIcon className={CommunitySocialIcon} />
);

export const Facebook = () => <FacebookIcon className={CommunitySocialIcon} />;

export const LinkedIn = () => <LinkedInIcon className={CommunitySocialIcon} />;

export const CommunitySubNavWrapper = styled('div')`
  position: absolute;
  top: 42px;
  max-width: 1120px;
  width: calc(100% - 40px);
  left: 50%;
  transform: ${props =>
    props.show ? 'scaleY(1) translateX(-50%)' : 'scaleY(0) translateX(-50%)'};
  transition: transform 0.25s ease-in-out, height 0.25s ease-in-out,
    opacity 0.25s;
  opacity: ${props => (props.show ? '1' : '0')};
  transform-origin: top center;
  z-index: 2;
`;

export const CommunitySubNav = styled('div')`
  background-color: ${Colors.white};
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  max-width: 650px;
  width: 100%;
  margin-left: auto;
  position: relative;

  > div {
    display: flex;
  }
`;

export const CommunitySubNavListContainer = styled('div')`
  width: 40%;
  padding: 20px;
  display: inline-block;
  box-sizing: border-box;
  overflow-y: auto;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    &:first-child {
      a {
        padding-top: 0;
      }
    }

    &:last-child {
      a {
        padding-bottom: 0;
      }
    }
  }

  a {
    color: ${Colors.lightTextGrey};
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    padding: 10px 0;
    display: block;
    transition: color 0.25s;

    &:hover {
      color: ${Colors.ctaGreen};
    }
  }
`;

export const CommunitySubNavTabContainer = styled('div')`
  width: 60%;
  padding: 20px;
  display: inline-block;
  box-sizing: border-box;
  overflow-y: auto;
`;

export const CommunitySubNavTitle = styled('span')`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
  display: block;
`;

export const CommunitySocialIconList = styled('ul')`
  list-style: none;
  padding: 0;
  display: flex;
  margin-top: 40px;

  li {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    background-color: #333333;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: background-color 0.25s;

    &:hover {
      background-color: ${Colors.ctaGreen};
    }
  }
`;

export const CommunitySocialText = styled('p')`
  font-family: 'Hind Siliguri', sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${Colors.brandHighLightPurple};
`;

export const CommunitySubNavTabTitle = styled(({ active, ...props }) => (
  <span {...props} />
))`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 20px;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
  transition: border-color 0.25s;

  &:hover {
    border-color: ${Colors.ctaGreen};
  }

  ${props => (props.active ? `border-color: ${Colors.ctaGreen}` : null)};
`;

export const CommunitySubNavTabList = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -5px;

  li {
    width: calc(50% - 10px);
    margin: 5px;
    position: relative;
    height: 160px;

    a {
      display: block;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      font-size: 0;

      &:after {
        content: '';
        padding-top: 100%;
        display: block;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`;
