import { graphql } from "react-apollo";
import mutator from "shared/gql/mutate";
import { query as listQuery } from "../../../gql/queries/get";
import { query as listQuery2 } from 'shared/blocks/products/tabs/card/image/save/queries/boards';
import gql from "graphql-tag.macro";

export const query = gql`
  mutation CreateBoard($input: CreateBoardInput!) {
    createBoard(input: $input) {
      id
      name
      description
      vision_board_project_id
      user_id
      template
      textarea
      collaborators {
        id
        name
        phone
        email
        board_id
      }
      images {
        id
        url
      }
      colors {
        id
        hex_code
      }
      items {
        item_id
        id
        name
        slug
        image {
          thumbnail
        }
      }
    }
  }
`;
export default graphql(query, {
  props: ({ mutate, ...props }) => ({
    ...props,
    create: input =>
      mutator(() =>
        mutate({
          variables: { input },
          update: (store, { data: { createBoard } }) => {
            let data;
            if(props.ownProps.go) {
                data = store.readQuery({ query: listQuery, variables: { input: { status: 'active', vision_board_project_id: props.ownProps.vision_board_project_id }} });
            }else {
                data = store.readQuery({ query: listQuery2, variables: { input: { status: 'active' }} });
            }
            
            data.boards.push(createBoard);

            store.writeQuery({ query: listQuery, data });
          }
        })
      )
  })
});
