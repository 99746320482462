import React from 'react';
import makeQuery from './make-query';
import search from 'shared/elastic-search';

export default component =>
  class Search extends React.Component {
    constructor({ match }) {
      super();
      this.state = { match };
    }

    componentDidMount() {
      this.fetchResults();
    }

    componentDidUpdate({ location, must }) {
      if (location.search !== this.props.location.search)
        return this.fetchResults();
      if (location.pathname !== this.props.location.pathname)
        this.fetchResults();
      if(this.props.board && must[0].value !== this.props.must[0].value)
        this.fetchResults();
    }

    async fetchResults() {
      let { match = {}, must, type, collectionsPage, allRecords } = this.props;
      try {
        let { result, total } = await search(
          makeQuery({
            must,
            category: match.params ? match.params.category : null,
            type,
            collectionsPage,
            allRecords
          }),
        );

        if (!result.length) this.setState({ noResults: true });

        this.setState({ products: result, total });
      } catch (e) {
        console.log(e, `Couldn't connect to elastic search`);
        this.setState({ noResults: true });
      }
    }
    render() {
      let { type } = this.props;
      let { total, products, noResults } = this.state;

      return React.createElement(component, {
        total,
        type,
        products,
        noResults,
        ...this.props,
      });
    }
  };
