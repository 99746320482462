import React from 'react';
import Field from './field';
import Caret from 'icons/caret';
import styled from 'react-emotion';
import Select, { Creatable } from 'react-select';
import { ctaGreen } from 'base/colors';

const ArrowContainer = styled('div')`
  align-self: center;
  padding-top: 2px;
`;

export default ({
  items,
  placeholder,
  width,
  onChange,
  value,
  big,
  backgroundColor,
  label,
  type,
  error,
  success,
  name,
  create,
  beforeChange,
  hideLabel,
  ...props
}) => {
  let select = React.createElement(create ? Creatable : Select, {
    ...props,
    className: big ? 'Select-big' : null,
    value,
    clearable: false,
    placeholder,
    onChange: item => {
      if (beforeChange) beforeChange(item.value, onChange);
      onChange({ target: { name, value: item.value } });
    },
    options: items,
    arrowRenderer: ({ isOpen }) => (
      <ArrowContainer>
        <Caret
          rotate={isOpen ? '-90' : '90'}
          color={ctaGreen}
          width={big ? 10 : 7}
          height={big ? 17 : 14}
        />
      </ArrowContainer>
    ),
  });

  return (
    <Field {...{ label, type, error, success, hideLabel }}>{select}</Field>
  );
};
