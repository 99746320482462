import { graphql } from "react-apollo";
import mutator from "shared/gql/mutate";
import { query as listQuery } from 'shared/blocks/products/tabs/card/image/save/queries/projects';
import gql from "graphql-tag.macro";

export const query = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      vision_board_project_id
      name
      description
      user_id
    }
  }
`;
export default graphql(query, {
  props: ({ mutate, ...props }) => ({
    ...props,
    create: input =>
      mutator(() =>
        mutate({
          variables: { input },
          update: (store, { data: { createProject } }) => {
            const data = store.readQuery({ query: listQuery, variables: { input: { status: 'active' } } });

            data.vbProjects.push(createProject);

            store.writeQuery({ query: listQuery, data });
          }
        })
      )
  })
});
