import React from 'react';
import Form from './form';
import { Button } from '../style';
import Modal from 'shared/modals/desktop';

class Save extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { product, children, data } = this.props;
    let { show } = this.state;

    const toggle = () => this.setState({ show: !show });

    return (
      <React.Fragment>
        {children ? (
          React.cloneElement(children, { onClick: toggle })
        ) : (
          <Button onClick={toggle}>add to vision board</Button>
        )}
        <Modal show={show} title="Save to Vision board" onClose={toggle}>
          <Form product={product} close={toggle} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default Save;
