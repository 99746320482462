import React from 'react';
import state from 'shared/state';
import {
  WarningContainer,
  ButtonWhite,
  WarningWrapper,
} from 'shared/layout/base/base';
import Icon from 'icons';

export default class Error extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    state.on('error', message => {
      this.setState({ message });
      //setTimeout(() => this.setState({ message: null }), 10000);
    });
  }

  render() {
    let { message } = this.state;
    if (!message) return null;

    return (
      <WarningContainer>
        <WarningWrapper>
          <Icon name="notification" />
          <div>{message}</div>
        </WarningWrapper>
        <ButtonWhite onClick={() => this.setState({ message: null })}>
          close
        </ButtonWhite>
      </WarningContainer>
    );
  }
}
