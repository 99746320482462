export default [
  { value: 'ARCH', label: 'Architect' },
  { value: 'ARCHMW', label: 'Architectural Mill Worker' },
  { value: 'ASI DIST', label: 'ASI Distributor' },
  { value: 'ASI REP', label: 'ASI Representative' },
  { value: 'ASSOC', label: 'Association' },
  { value: 'BLD', label: 'Builder' },
  { value: 'BUSINESS GROUP', label: 'Business Group' },
  { value: 'CM/PM', label: 'Construction/Project Manager' },
  { value: 'COMPETITOR', label: 'Competitor' },
  { value: 'CONSULTANT', label: 'Consultant' },
  { value: 'DES', label: 'Designer' },
  { value: 'DES/BLD', label: 'Designer/Builder' },
  { value: 'DEV', label: 'Developer' },
  { value: 'DIST', label: 'Distributor' },
  { value: 'EDU', label: 'Education/Student' },
  { value: 'EMPLOYEE', label: 'Employee' },
  { value: 'FC Flooring', label: 'Contractor' },
  { value: 'FRANCHISE', label: 'Franchise' },
  { value: 'GC', label: 'General Contractor' },
  { value: 'HLTHCR', label: 'Healthcare' },
  { value: 'HOSP', label: 'Hospitality' },
  { value: 'IC', label: 'Interior Contractor' },
  { value: 'INSPECTOR', label: 'Inspector' },
  { value: 'LANDSCAPER', label: 'Landscaper' },
  { value: 'LD', label: 'Lighting Designer' },
  { value: 'MAG/PUB', label: 'Magazine/Publisher' },
  { value: 'MFG', label: 'Manufacturer' },
  { value: 'MG', label: 'Metal Glass Installer' },
  { value: 'NA', label: 'National Accountant' },
  { value: 'HOMEOWNER', label: 'Home Owner' },
  { value: 'PA/OR', label: 'Purchasing Agent & Owners Rep' },
  { value: 'PC', label: 'Painting Contractor' },
  { value: 'PROJECT MANAGER', label: 'Project Manager' },
  { value: 'REP', label: 'Representative' },
  { value: 'RS', label: 'Retail Store' },
  { value: 'SC', label: 'Stone Contractor' },
  { value: 'VENDOR', label: 'Vendor' },
];
