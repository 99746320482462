import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans, Tablet, Montserrat } from 'shared/layout/base/base';

export const Wrapper = styled(({ loading, ...props }) => <div {...props} />)`
  width: 100%;
  opacity: ${props => (props.loading ? 0.5 : 1)};
  transform: opacity 0.5s;

  @media (${Tablet}) {
    width: calc(100% - 295px);
    margin-right: 30px;
  }

  .Select-control {
    height: 50px;
    background-color: #fafafa;
  }

  .Select-placeholder {
    display: flex;
    align-items: center;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 300;
    color: ${Colors.titleGrey};
  }
`;

export const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled('h3')`
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;

  @media (${Tablet}) {
    font-size: 35px;
  }

  &:after {
    width: 40px;
    height: 2px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${Colors.brandHighLightPurple};

    @media (${Tablet}) {
      width: 80px;
    }
  }
`;
