import React from "react";
import { css } from "emotion";
import styled from "react-emotion";
import { titleGrey } from "base/colors";
import { EncodeSans } from "shared/layout/base/base";

const Label = css`
  font-family: ${EncodeSans};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${titleGrey};
`;

const P = styled(({ color, ...props }) => <p {...props} />)`
  margin: 0px;
`;
export const Error = styled(P)`
  color: ${props => props.color};
`;

const Container = styled(({ reversed, hideLabel, ...props }) => (
  <div {...props} />
))`
  ${props => (props.hideLabel ? null : `padding: 5px 0px;width: 100%`)};
  ${props =>
    props.reversed
      ? `display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;`
      : null};
  ${props => (props.center ? `align-items: center;` : null)};
  ${props => (props.center ? `label { margin-bottom: 0px;}` : null)};
`;

export default ({
  label,
  error,
  success,
  children,
  reversed,
  name,
  hideLabel,
  center
}) => {
  let color = success ? "green" : error ? "red" : null;

  return (
    <Container reversed={reversed} hideLabel={hideLabel} center={center}>
      {hideLabel ? null : (
        <label className={Label} htmlFor={name}>
          <P>{label}</P>{" "}
          {error || success ? (
            <Error color={color}>{error || success}</Error>
          ) : null}
        </label>
      )}

      {React.cloneElement(children, {
        style: { borderColor: color, color }
      })}
    </Container>
  );
};
