import React from "react";
import getCollectionsByCategory from "./gql/getCollectionsByCategory";
import { CollectionWrapper } from "./style";
import Collection from "./item";

const CategoryCollections = ({ data }) => {
  if (!data.getCollectionsByCategory) return false;

  return (
    <CollectionWrapper>
      {data.getCollectionsByCategory.map((item, index) => (
        <Collection key={index} item={item} />
      ))}
    </CollectionWrapper>
  );
};

export default getCollectionsByCategory(CategoryCollections);
