import React from 'react';
import Icon from 'icons';

import { Button } from './style';

export default class ButtonWrapper extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { loading } = this.state;
    let { icon, children, saving, onClick, ...props } = this.props;
    return (
      <Button
        {...props}
        onClick={async () => {
          if (!onClick) return;

          this.setState({ loading: true });
          await onClick();
          this.setState({ loading: false });
        }}
      >
        <Icon name={icon} />
        {loading ? saving : children}
      </Button>
    );
  }
}
