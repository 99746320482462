import React from 'react';
import styled from 'react-emotion';
import { EncodeSans } from 'shared/layout/base/base';
import * as Colors from 'base/colors';

export const IntroText = styled(({ red, ...props }) => <p {...props} />)`
  font-family: ${EncodeSans};
  font-size: 15px;
  line-height: 1.53;
  color: ${props => (props.red ? 'red' : Colors.lightTextGrey)};
`;
