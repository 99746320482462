import { getQuery } from 'shared/query-string';

export default (filter, category) => {
  let name = `${filter.name.toLowerCase()}.terms.${category.taxonomy}.term_id`;
  let query = getQuery();

  let isFirst = Object.keys(query).filter(key => key !== 'first').length === 0;

  //check if active in url
  let active =
    query[name] && query[name].includes(category.term_taxonomy_id.toString());
  let value = [category.term_taxonomy_id];

  //if it is in url
  if (query[name]) {
    //if its already active, remove it
    if (active)
      value = query[name].filter(
        item => item !== category.term_taxonomy_id.toString(),
      );
    //if not active, add it
    else value = [...query[name], category.term_taxonomy_id];
  }

  return { name, value, isFirst, query, active };
};
