import storage from 'shared/storage';
import { graphql } from 'react-apollo';
import card from 'shared/gql/fragments/product-card';
import gql from 'graphql-tag.macro';
export const query = gql`
  query Products($input: MultipleProductsInput!) {
    multipleProducts(input: $input) {
      ...product
      minimumQty
    }
  }
  ${card}
`;
export default graphql(query, {
  options: props => {
    let id = storage.get('recent-products');

    return {
      variables: { input: { id, limit: 5, atLeast: props.project ? 4 : 5 } },
    };
  },
});
