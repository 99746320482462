import { graphql } from "react-apollo";
import { query as boardsQuery } from "../queries/boards";

import gql from "graphql-tag.macro";
export const query = gql`
  mutation CreateBoard($input: CreateBoardInput!) {
    createBoard(input: $input) {
      id
      name
      description
      user_id
      template
      textarea
      collaborators {
        id
        name
        phone
        email
        board_id
      }
      images {
        id
        url
      }
      colors {
        id
        hex_code
      }
      items {
        item_id
        id
        name
        slug
        image {
          thumbnail
        }
      }
    }
  }
`;
export default graphql(query, {
  props: ({ mutate }) => ({
    createBoard: async input => {
      try {
        return await mutate({
          variables: { input },
          refetchQueries: [{ query: boardsQuery }]
        });
      } catch (e) {
        return null;
      }
    }
  })
});
