import React, { Component } from 'react';
import { SiteContainer, Button } from 'shared/layout/base/base';
import { Container, Title, Subtext } from './style';
import Image from './404.png';
import Flex from 'shared/layout/flex';
import { Link } from 'react-router-dom';
import Head from 'shared/head';

class ErrorPage extends Component {
  render() {
    return (
      <SiteContainer>
        <Head title="404 - Not Found" />
        <Container>
          <img src={Image} alt="404 Page" />
          <Title>OOPS!</Title>
          <Subtext>We cant seem to find the page your looking for.</Subtext>
          <Flex>
            <Link to="/">
              <Button>ASI HOME PAGE</Button>
            </Link>
            <Link to="/products">
              <Button>BROWSE MATERIALS</Button>
            </Link>
          </Flex>
        </Container>
      </SiteContainer>
    );
  }
}
export default ErrorPage;
