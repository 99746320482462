import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Montserrat, Tablet, Phone } from 'shared/layout/base/base';

export const Container = styled(({ loading, ...props }) => <div {...props} />)`
  display: flex;
  margin: 40px;
  transition: opacity 0.25s;
  opacity: ${props => (props.loading ? 0.5 : 1)};

  @media (${Phone}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  @media (${Tablet}) {
    flex-direction: row;
  }
`;

export const Image = styled(({ loading, ...props }) => <div {...props} />)`
  ${props => (props.loading ? 'background-color: #00000029' : '')};
  height: 254px;
  width: 254px;
  overflow: hidden;

  @media (${Phone}) {
    margin: 20px;
  }
  @media (${Tablet}) {
    margin-right: 20px;
  }
`;

export const Content = styled('div')`
  width: 86%;  
`;

export const Title = styled('div')`
  font-family: ${Montserrat};
  font-size: 26px;
  font-weight: 200;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 20px 40px;
  border-top: 1px solid ${Colors.borderColor};

  @media (${Tablet}) {
    flex-direction: row;
  }

  button {
    padding: 16px 20px;
    & + button {
      margin-top: 10px;

      @media (${Tablet}) {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }
`;
