import React from 'react';
import { ListItem, Board } from '../style';

export default class Community extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <ListItem>
        <a href="/account/boards">
          <Board />
          VISION BOARDS
        </a>
      </ListItem>
    );
  }
}
