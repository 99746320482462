import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans, Tablet, Siliguri, Button } from 'shared/layout/base/base';

export const FormWrapper = styled('div')`
  width: 100%;

  .Select-control,
  input {
    background-color: #fafafa;
  }
`;

export const Label = styled('div')`
  font-family: ${Siliguri};
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.titleGrey};
  margin-bottom: 15px;

  @media (${Tablet}) {
    font-size: 18px;
  }
`;

export const Section = styled(({ noPaddingTop, ...props }) => (
  <div {...props} />
))`
  display: block;
  padding: 15px 0;
  ${props => (props.noPaddingTop ? 'padding-top: 0' : ' ')};
  ${props => (props.noPaddingBottom ? 'padding-bottom: 0' : ' ')};
  width: 100%;

  @media (${Tablet}) {
    padding: 30px 0;
    ${props => (props.noPaddingTop ? 'padding-top: 0' : ' ')};
    ${props => (props.noPaddingBottom ? 'padding-bottom: 0' : ' ')};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const FieldWrapper = styled(({ twoColumn, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: column;

  @media (${Tablet}) {
    flex-direction: row;
  }

  input {
    flex-grow: 1;
  }

  .Select {
    width: 100%;
    flex-grow: 1;

    .Select-input input {
      padding: 0;
      line-height: 40px;
      height: auto;
    }

    .Select-option {
      box-sizing: border-box;
      background-color: #fff;
      color: #333;
      cursor: pointer;
      display: block;
      padding: 8px 10px;
    }

    .Select-option.is-selected {
      /* background-color: #f5faff; */
      /* Fallback color for IE 8 */
      background-color: rgba(0, 126, 255, 0.04);
      color: #333;
    }
    .Select-option.is-focused {
      /* background-color: #ebf5ff; */
      /* Fallback color for IE 8 */
      background-color: rgba(0, 126, 255, 0.08);
      color: #333;
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    max-width: 80%;
    line-height: 34px;
  }

  .Select-placeholder,
  .Select-value {
    padding: 7px;
  }

  div {
    &:first-child {
      margin-right: ${props => (props.twoColumn ? '20px' : '0')};
    }
  }
`;

export const List = styled('ul')`
  padding: 0;
  list-style: none;

  li {
    display: flex;
    margin-bottom: 15px;

    div {
      align-items: center;
      padding: 0;
    }

    label {
      margin-bottom: 0;

      p {
        font-family: ${EncodeSans};
        font-size: 13px;
        font-weight: 500;

        svg {
          width: 70px;
          display: block;
        }
      }
    }
  }
`;

export const CardNumberWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  @media (${Tablet}) {
    align-items: flex-end;
    flex-direction: row;
  }

  input {
    flex-grow: 1;
  }
`;

export const CardImages = styled('ul')`
  list-style: none;
  padding: 0;
  display: flex;
  width: 196px;

  @media (${Tablet}) {
    margin-left: 20px;
    margin-bottom: 25px;
  }

  li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    img {
      display: block;
      width: 34px;
      height: 23px;
    }
  }
`;

export const CardInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  @media (${Tablet}) {
    flex-direction: row;
  }
`;

export const ExpirationWrapper = styled('div')`
  display: flex;
  align-items: flex-end;

  div {
    &:first-child {
      margin-right: 10px;
    }

    @media (${Tablet}) {
      &:first-child {
        margin-right: 20px;
      }
      margin-right: 20px;
      width: 192px;
    }
  }
`;

export const FormButton = styled(Button)`
  padding: 16px;
  margin-top: 20px;

  @media (${Tablet}) {
    min-width: 127px;
  }
`;
