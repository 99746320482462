import React from 'react';
import { BaseForm } from 'react-validify';
import Modal from 'shared/modals/desktop';
import { withRouter } from 'react-router-dom';
import Input from 'shared/layout/form/input';
import createMutations from './mutations/create';
import Textarea from 'shared/layout/form/text-area';
import { Wrapper, Container, ButtonContainer, CreateButton } from '../../create/modal/style';

class CreateModal extends React.Component {
  constructor() {
    super();
    this.state = { values: {name: "",description: ""} };
  }

  async create() {
    let { values } = this.state;
    let { onClose, create, go, history, setProjectData } = this.props;

    this.setState({ loading: true });
    let response = await create(values);

    if (response && response.errors)
      return this.setState({
        loading: false,
        errors: response.errors,
      });
  
    setProjectData(response);
    
    onClose();
    if (go) history.push(`/account/boards/${response.data.createProject.vision_board_project_id}`);
    else
      this.setState({
        loading: false,
        values: {},
      });
  }
  render() {
    let { show, onClose } = this.props;
    let { values, errors, loading } = this.state;
    return (
      <Modal show={show} onClose={onClose} title="New project">
        <Wrapper loading={loading}>
          <Container>
            <BaseForm
              values={values}
              errors={errors}
              rules={{
                name: 'required|min:5|max:45'
              }}
              onValues={values => this.setState({ values })}
              onErrors={errors => this.setState({ errors })}
            >
              <Input name="name" placeholder="Project Name" />
              <Textarea
                name="description"
                placeholder="Add project notes"
              />
              <ButtonContainer submit onClick={values => this.create(values)}>
                <CreateButton>Create</CreateButton>
              </ButtonContainer>
            </BaseForm>
          </Container>
        </Wrapper>
      </Modal>
    );
  }
}

export default createMutations(withRouter(CreateModal));
