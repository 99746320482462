import { graphql } from 'react-apollo';
import mutator from 'shared/gql/mutate';
import { query as shippingQuery } from '../queries/shipping';
import address from '../queries/address';

import gql from 'graphql-tag.macro';
export const query = gql`
  mutation UpdateUserAddress($input: UpdateAddressInput!) {
    updateUserAddress(input: $input) {
      ...address
    }
  }
  ${address}
`;
export default graphql(query, {
  props: ({ mutate }) => ({
    update: ({ __typename, ...input }) => {
      delete input.fullAddress;
      return mutator(() =>
        mutate({
          variables: { input },
          update: (store, { data: { updateUserAddress } }) => {
            const data = store.readQuery({ query: shippingQuery });

            let addresses = data.user.shipping.map(
              address =>
                address.id === updateUserAddress.id
                  ? { ...updateUserAddress, email: 'test' }
                  : address,
            );

            data.user.shipping = addresses;

            store.writeQuery({ query: shippingQuery, data });
          },
        }),
      );
    },
  }),
});
