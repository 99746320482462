import React from 'react';
import Button from './button';
import Dropdown from 'shared/layout/form/dropdown';
import {
  Container,
  Wrapper,
  Heading,
  DropdownWrapper,
  HeadingWrapper,
  Label
} from './style';

export default ({
  heading,
  children,
  button,
  customButton,
  icon,
  filter,
  items,
  selected,
  onChange,
  searchBar
}) => (
  <Container>
    {heading ? (
      <HeadingWrapper>
        <Heading>{heading}</Heading>
        {customButton ? (
          customButton
        ) : button ? (
          <Button icon={icon}>{button}</Button>
        ) : (
          false
        )}

        {filter ? (
          <DropdownWrapper>
            <Label>{filter}</Label>
            <Dropdown
              selected={selected}
              placeholder="All Years"
              items={items}
              hideLabel
              value={selected(items)}
              onChange={onChange}
            />
          </DropdownWrapper>
        ) : (
          false
        )}
      </HeadingWrapper>
    ) : null}
    {searchBar}
    <Wrapper>{children}</Wrapper>
  </Container>
);
