import styled from 'react-emotion';

export const ProductsContainer = styled('div')`
  width: 100%;

  @media (min-width: 768px) {
    max-width: 833px;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1119px) {
    padding-right: 20px;
    width: 100%;
    overflow: hidden;
  }
`;
