import styled from 'react-emotion';
import { backgroundGrey } from 'base/colors';
import { Phone } from 'shared/layout/base/base';

export const Container = styled('div')`
  border: solid 1px #d6d6d6;
  background-color: ${backgroundGrey};
  width: 100vw;
  height: 70px;
`;

export const DropdownContainer = styled('div')`
  margin-right: 10px;
  min-width: ${props => props.width}px;
  width: 100%;
  overflow: hidden;

  .Select-control {
    height: 40px;
  }

  .Select-input {
    height: 24px;

    input {
      line-height: 25px;
      padding: 0;
    }
  }

  .Select-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Select-menu-outer {
    position: relative;
    z-index: 9999;
  }

  .Select-control > *:last-child {
    padding-right: 0;
  }

  .Select-arrow-zone {
    padding-right: 0;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 26px;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
`;

export const Text = styled('p')`
  font-family: 'Encode Sans';
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 10px;
`;

export const Wrapper = styled('div')`
  display: flex;
  overflow-x: auto;
  padding: 15px 0 15px 20px;
  -webkit-overflow-scrolling: touch;
  z-index: 9;
  position: relative;

  @media (${Phone}) {
    overflow-x: scroll;
  }
`;
