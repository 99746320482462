import styled from "react-emotion";
import * as Colors from "base/colors";
import { EncodeSans, Tablet } from "shared/layout/base/base";

export const ItemDetails = styled("div")`
  display: flex;
  margin-right: 10px;

  @media (${Tablet}) {
    margin-right: 0;
  }

  img {
    width: 70px;
    height: 70px;
    display: block;
    padding: 5px;
    margin-right: 8px;
    border: 1px solid #e0dfdf;
    min-width: 70px;

    @media (${Tablet}) {
      width: 112px;
      height: 110px;
      min-width: 112px;
      margin-right: 20px;
    }
  }

  a {
    color: ${Colors.ctaGreen};
  }
`;

export const Details = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Name = styled("span")`
  display: block;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  color: ${Colors.titleGrey};
  margin-bottom: 5px;
`;

export const SubTitle = styled("span")`
  font-family: ${EncodeSans};
  font-size: 13px;
  color: ${Colors.titleGrey};
  margin-bottom: 10px;

  @media (${Tablet}) {
    margin-bottom: 20px;
  }
`;

export const ID = styled("span")`
  display: block;
  font-family: ${EncodeSans};
  font-size: 12px;
  color: ${Colors.lightTextGrey};
  margin-bottom: 5px;
`;

export const Type = styled("span")`
  display: block;
  font-family: ${EncodeSans};
  font-size: 12px;
  line-height: 23px;
`;

export const Description = styled("p")`
  display: block;
  font-family: ${EncodeSans};
  font-size: 12px;
  line-height: 16px;
  width: 60%;
  margin: 0;
`;
