import { injectGlobal } from "emotion";
import { white } from "./colors";
import "normalize.css";
injectGlobal`
    * {
      box-sizing: border-box;
      -webkit-print-color-adjust: exact;
    }

    @page {
      size: portrait;
 
    }

 

    .DayPickerInput{
      width: 100%;
    }

    html {
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 100%;
      -webkit-font-smoothing: antialiased;
    }

    body {
      background-color: ${white};
      height: 100%;
      font-family: 'Encode Sans', sans-serif;
      margin: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    ul {
        margin: 0;
    }

  button {
    border: none;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

.alignleft {
    float: left;
    margin: 20px 20px 20px 0;

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
}

  .gutenburg .wp-block-image.alignleft {
    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .gutenburg .wp-block-image.alignright {
    float: left;
    margin: 20px 20px 20px 0;

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .gutenburg .wp-block-image.alignright {
    float: right;
    margin: 20px 0 20px 20px;

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .gutenburg .wp-block-image.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    clear: both;

    @media (max-width: 767px) {
      margin: 0;
    }

    img {
      margin: 0 auto;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .edit-post-visual-editor ul:not(.wp-block-gallery) {
    list-style: none;
  }

  .slick-track,
  .slick-list {
    -webkit-perspective: 2000 !important;
    -webkit-backface-visibility: hidden !important;
  }

  .wp-block-image .aligncenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wp-block-image .alignright {
    float: right;
  }

  .wp-block-image .alignleft {
    float: left;
  }

  p.has-text-align-center {
    text-align: center !important;
  }



`;
