import React from 'react';
import styled from 'react-emotion';
import { Tablet } from 'shared/layout/base/base';

export const Wrapper = styled(({ collectionsPage, ...props }) => (
  <div {...props} />
))`
  display: block;
  margin: 20px;

  @media (${Tablet}) {
    margin: 0 0 20px 0;
  }
`;

export const Container = styled('div')`
  padding: 40px;
  min-height: 260px;
`;
