import { graphql } from 'react-apollo';
import gql from 'graphql-tag.macro';
import address from './address';

export const query = gql`
  query User {
    user {
      id
      email
      shipping {
        ...address
      }

      billing {
        ...address
      }
    }

    validation(rules: "users/shipping") {
      name
      value
    }
  }
  ${address}
`;
export default graphql(query, {
  props: ({ data, ownProps }) => ({
    data,
    addresses: data.user ? data.user[ownProps.type] : null,
    validation: data.validation,
  }),
});
