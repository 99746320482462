import React from "react";
import Caret from "icons/caret";
import styled from "react-emotion";
import Select from "react-select";
import { ctaGreen } from "base/colors";

const ArrowContainer = styled("div")`
  align-self: center;
  padding-top: 2px;
`;

const borderColor = (validation, backgroundColor) => {
  if (true === validation) {
    return {
      backgroundColor,
      borderColor: "green"
    };
  }

  if (false === validation) {
    return {
      backgroundColor,
      borderColor: "red"
    };
  }

  return { backgroundColor, borderColor: "#e0dfdf" };
};

export default ({
  items,
  placeholder,
  width,
  onChange,
  value,
  big,
  backgroundColor,
  validation,
  isDisabled,
  isSearchable,
  ...props
}) => (
  <Select
    {...props}
    isSearchable={isSearchable}
    className={big ? "Select-big" : null}
    value={value}
    clearable={false}
    placeholder={placeholder}
    onChange={onChange}
    options={items}
    style={borderColor(validation, backgroundColor)}
    arrowRenderer={({ isOpen }) => (
      <ArrowContainer>
        <Caret
          rotate={isOpen ? "-90" : "90"}
          color={ctaGreen}
          width={big ? 10 : 7}
          height={big ? 17 : 14}
        />
      </ArrowContainer>
    )}
  />
);
