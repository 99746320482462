import qs from 'qs';
import React from 'react';
import Paginate from './paginate';
import { dropdownGrey } from 'base/colors';
import { withRouter } from 'react-router-dom';
import Dropdown from 'shared/layout/form/dropdown';
import { Container, TitleContainer, DropdownContainer, Title } from './style';

export default withRouter(
  ({ total, history, hideDropdown, collectionsPage }) => {
    let query = qs.parse(window.location.search.substring(1));
    return (
      <React.Fragment>
        <TitleContainer>
          <Title collectionsPage={collectionsPage}>{total} Results</Title>
        </TitleContainer>

        <Container collectionPage>
          {hideDropdown ? null : (
            <DropdownContainer>
              <Dropdown
                big
                searchable={false}
                marginLeft="0px"
                backgroundColor={dropdownGrey}
                onChange={e => {
                  history.push(
                    `${window.location.pathname}?${qs.stringify({
                      ...query,
                      sort: e.target.value,
                    })}`,
                  );
                }}
                placeholder="Sort By"
                value={query.sort}
                items={[{ label: "What's New", value: 'new' }]}
                hideLabel
              />
            </DropdownContainer>
          )}
          {!collectionsPage ? <Paginate total={total} /> : null}
        </Container>
      </React.Fragment>
    );
  },
);
