import React from 'react';
import { Link } from 'react-router-dom';
import { ctaGreen } from 'base/colors';
import Caret from 'icons/caret';
import { Request } from './style';

const Samples = ({ hasSamples, sku }) =>
  hasSamples ? (
    <Link to={`/product/${sku}`}>
      <Request>
        Request a Sample
        <Caret color={ctaGreen} style={{ marginLeft: '5px' }} />
      </Request>
    </Link>
  ) : null;

export default Samples;
