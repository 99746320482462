import React, { Component } from "react";
import rules from "shared/rules";
import SelectAddress from "./select";
import Container from "../container";
import AddButton from "../add-button";
import Button from "../container/button";
import { BaseForm } from "react-validify";
import Input from "shared/layout/form/input";
import Dropdown from "shared/layout/form/dropdown";
import Checkbox from "shared/layout/form/checkbox";
import { Section, FormWrapper, Label, FieldWrapper } from "../form-style";

import { compose } from "react-apollo";
import shippingQuery from "./queries/shipping";
import updateMutation from "./mutations/update";
import createMutation from "./mutations/create";
import Loader from "views/account/loader";
class MyAccount extends Component {
  constructor({ value }) {
    super();
    this.state = { id: value };
  }

  componentDidUpdate(prevProps) {
    let { addresses, onSelect } = this.props;

    if (
      !prevProps.addresses &&
      addresses &&
      addresses.length &&
      !this.state.id &&
      !onSelect
    )
      this.setState({ id: addresses[0].id });
  }
  render() {
    let { values, id, errors, show, saved } = this.state;
    let {
      addresses,
      validation,
      update,
      create,
      title,
      label,
      onSelect,
      alwaysOpen,
      collapsed = true,
      hideHeading,
      buttonText,
      error,
      data,
      fieldsLabel,
      extraData = {},
      userInfo,
      onSiteContact,
      updateCart,
      value,
      isDisabled
    } = this.props;

    if (!addresses || !data.user) return <Loader />;
    //when no addresses, always show full fields
    if (!addresses.length) collapsed = false;
    let address = addresses.find(address => address.id === id) || {};
    let test = addresses.find(address => address.id === value) || {};

    return (
      <Container heading={hideHeading ? null : title}>
        <FormWrapper>
          <Section>
            {title ? <Label>{title} Address</Label> : null}
            {onSiteContact ? (
              <Input
                label="Name of On-Site Contact*"
                name="on_site_contact"
                error={""}
                defaultValue={data.cart ? data.cart.on_site_contact : ""}
                onBlur={e => updateCart({ on_site_contact: e.target.value })}
              />
            ) : null}
            {isDisabled ? (
              <Input
                type="text"
                readOnly
                value={test ? test.fullAddress : ""}
              />
            ) : addresses.length ? (
              <FieldWrapper>
                <Dropdown
                  isDisabled
                  error={error}
                  name="address"
                  label={label || "Your Addresses"}
                  items={addresses.map(address => ({
                    label: `${address.company ? address.company : null}, ${
                      address.first_name ? address.first_name : null
                    } ${address.last_name ? address.last_name : null}, ${
                      address.fullAddress ? address.fullAddress : null
                    }`,
                    value: address.id
                  }))}
                  onChange={({ target }) => {
                    if (onSelect) onSelect(target.value);
                    this.setState({
                      id: target.value,
                      show: false,
                      values: {
                        ...addresses.find(
                          address => address.id === target.value
                        )
                      }
                    });
                  }}
                  value={id || value}
                />
                {id || (collapsed && !show) ? (
                  <AddButton
                    text="New Address"
                    onClick={() =>
                      this.setState({ values: {}, id: null, show: true })
                    }
                  />
                ) : null}
              </FieldWrapper>
            ) : null}

            {!collapsed || show ? (
              <React.Fragment>
                {fieldsLabel ? (
                  <Label style={{ marginTop: 10 }}>{fieldsLabel}</Label>
                ) : null}
                <BaseForm
                  errors={errors}
                  values={
                    userInfo ||
                    values || {
                      ...address,
                      email: data.user.email,
                      ...extraData
                    }
                  }
                  rules={{
                    ...rules(validation),
                    placeid: "required",
                    company: "required"
                  }}
                  errorMessages={{
                    "regex.phone": "Phone format must be XXX-XXX-XXXX",
                    "required.placeid": "Please select an address"
                  }}
                  onValues={values => this.setState({ values })}
                  onErrors={errors => this.setState({ errors })}
                >
                  <FieldWrapper twoColumn>
                    <Input name="first_name" label="First Name*" />
                    <Input name="last_name" label="Last Name*" />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Input name="company" label="Company Name*" />
                  </FieldWrapper>

                  <FieldWrapper twoColumn>
                    <SelectAddress
                      name="placeid"
                      placeid={address.placeid}
                      selectedAddress={
                        values ? values.fullAddress : address.fullAddress
                      }
                    />
                    <Input name="line_2" label="Address line 2" />
                  </FieldWrapper>

                  <FieldWrapper twoColumn>
                    <Input name="email" label="Email*" />
                    <Input
                      name="phone"
                      label="Phone*"
                      mask={[
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                    />
                  </FieldWrapper>

                  <FieldWrapper twoColumn>
                    <Checkbox
                      name="isDefault"
                      label={`Set as default ${
                        title ? `${title.toLowerCase()} ` : ""
                      }address`}
                    />
                  </FieldWrapper>

                  <Button
                    submit
                    onClick={async values => {
                      if (id) {
                        let updated = await update({ id, ...values });
                        if (updated.errors)
                          return this.setState({
                            errors: updated.errors
                          });
                        return;
                      }

                      let response = await create({ ...values, ...extraData });
                      if (response.errors)
                        return this.setState({
                          errors: response.errors
                        });
                      if (!response.data || !response.data.createUserAddress)
                        return;

                      let value = response.data.createUserAddress.id;
                      if (onSelect) onSelect(value);
                      this.setState({
                        id: value,
                        saved: true,
                        show:
                          collapsed || (!alwaysOpen && !addresses.length)
                            ? false
                            : true
                      });

                      setTimeout(() => this.setState({ saved: false }), 3000);
                    }}
                    saving={id ? "Saving..." : "Creating..."}
                  >
                    {saved
                      ? "Saved!"
                      : buttonText
                      ? buttonText
                      : id
                      ? "Save Address"
                      : "Add Address"}
                  </Button>
                </BaseForm>
              </React.Fragment>
            ) : null}
          </Section>
        </FormWrapper>
      </Container>
    );
  }
}
export default compose(
  shippingQuery,
  updateMutation,
  createMutation
)(MyAccount);
