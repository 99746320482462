export const black = '#000';
export const white = '#fff';
export const brandPink = '#DD4E75';
export const brandBeige = '#F3DBB7';
export const brandPurple = '#9D4976';
export const brandHighLightPurple = '#AD619D';
export const backgroundGrey = '#F3F2F2';
export const ctaGreen = '#61A60D';
export const titleGrey = '#434343';
export const lightTextGrey = '#7D7C7C';
export const dropdownGrey = '#fbfafa';
export const borderColor = '#e0dfdf';
export const facebook = '#3b5998';
export const twitter = '#1da1f2';
export const pinterest = '#bd081c';
export const youtube = '#ff0000';
export const instagram = '#e1306c';
export const linkedin = '#0077b5';

export default {
  black,
  white,
  brandPink,
  brandBeige,
  brandPurple,
  brandHighLightPurple,
  backgroundGrey,
  ctaGreen,
  titleGrey,
  lightTextGrey,
  dropdownGrey,
};
