import React from "react";
import state from "shared/state";
import storage from "shared/storage";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { setContext } from "apollo-link-context";
import { persistCache } from "apollo-cache-persist";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

export const {
  NetworkStatusNotifier,
  link: networkStatusNotifierLink
} = createNetworkStatusNotifier();

const cache = new InMemoryCache();

if (process.env.REACT_APP_ENABLE_CACHE)
  persistCache({
    cache,
    storage: window.localStorage
  });

const authLink = setContext((_, { headers }) => {
  const { jwt } = storage.get("user", {});

  return {
    headers: {
      ...headers,
      authorization: jwt ? `Bearer ${jwt}` : ""
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(message => {
      if (message.code === 404) {
        if (window.location.search !== "?404")
          return (window.location.search = "?404");
      }

      if (message && message.code === 401) {
        storage.clear();
        if (
          window.location.pathname.match(
            /contact-us|customization|project-spotlight/
          )
        )
          return;

        return (window.location = `/auth/login?redirect=${
          window.location.pathname
        }`);
      }
      if (!message.field && (message.path || message.message))
        state.send(
          "error",
          message.message ||
            `There was a problem loading \`${message.path[0]}\` `
        );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (
    networkError &&
    (networkError.statusCode === 401 || networkError.statusCode === 403)
  ) {
    storage.clear();
    if (
      window.location.pathname.match(
        /contact-us|customization|project-spotlight/
      )
    )
      return;
    return (window.location = `/auth/login?redirect=${
      window.location.pathname
    }`);
  }
});

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink({ uri: process.env.REACT_APP_GRAPHQL }),
  new HttpLink({ uri: process.env.REACT_APP_GRAPHQL })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    networkStatusNotifierLink,
    httpLink
  ]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    }
  }
});

export default ({ children, ...props }) => (
  <ApolloProvider client={client}>
    {React.cloneElement(children, { ...props })}
  </ApolloProvider>
);
