import React from 'react';

export default ({ error, label, type, ...props }) => (
  <fieldset>
    <input
      {...props}
      type={type || 'text'}
      className={props.value ? 'has-value' : null}
      style={{ backgroundColor: error ? 'red' : '#8f4f81' }}
    />
    <label>{error || label}</label>
  </fieldset>
);
