import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
import qs from "qs";
export const query = gql`
  query Boards($input: GetBoardsInput) {
    boards(input: $input) {
      id
      name
      description
      user_id
      template
      textarea
      preview_image
      vision_board_project_id
      status
      project {
        vision_board_project_id
        name
        description
        status
        user_id    
      }
      user { 
        user_nicename
      }
      collaborators {
        id
        name
        phone
        email
        board_id
      }
      images {
        id
        url
      }
      colors {
        id
        hex_code
      }
      items {
        item_id
        id
        name
        slug
        image {
          thumbnail
        }
      }
    }
  }
`;
export default graphql(query, {
  options: props => {

    let { status } = qs.parse(window.location.search.substring(1));
    let input = { status: status || 'active', vision_board_project_id: parseInt(props.match.params.id)};

    return {
      variables: { input },
      fetchPolicy: "cache-and-network"
    };
  }
});
