import React from "react";
import { Section } from "views/checkout/shared/style";
import Checkbox from "shared/layout/form/checkbox";
import { Label, List } from "views/checkout/shared/style";

export default ({ Delivery_Requirements, update, isDisabled }) => {
  return (
    <React.Fragment>
      <Section>
        <Label>Other delivery services (Standard Delivery: Commercial)</Label>
        <List>
          {isDisabled ? (
            Delivery_Requirements === "commercial" ? (
              <li>Commercial</li>
            ) : (
              <li>Residential</li>
            )
          ) : (
            <React.Fragment>
              <li>
                <Checkbox
                  isDisabled
                  name="commercial"
                  value={Delivery_Requirements === "commercial"}
                  label="Commercial"
                  onChange={() => update("commercial")}
                />
              </li>
              <li>
                <Checkbox
                  isDisabled
                  name="residential"
                  value={Delivery_Requirements === "residential"}
                  label="Residential"
                  onChange={() => update("residential")}
                />
              </li>
            </React.Fragment>
          )}
        </List>
      </Section>
    </React.Fragment>
  );
};
