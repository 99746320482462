import React from "react";
import Head from "shared/head";
import NotFound from "views/404";
import renderBlocks from "./types";
import blockQuery from "./queries/blocks";
/* globals Rollbar */

export class BlockRenderer extends React.Component {
  constructor({ id }) {
    super();
    this.state = { id };
  }

  componentDidMount() {
    if (this.props.blocks) this.loadBlocks();
  }

  componentDidUpdate({ blocks, id }) {
    if (this.props.blocks !== blocks || this.props.id !== id) this.loadBlocks();
  }

  async loadBlocks() {
    let { blocks, slug, id, extraProps = {}, wrappers } = this.props;
    try {
      let components = await Promise.all(
        blocks.map((block, index) =>
          renderBlocks(block, index, extraProps, wrappers)
        )
      );

      this.setState({ components, slug, id, blocks });
    } catch (e) {
      if (process.env.REACT_APP_ENABLE_CACHE) Rollbar.error(e);

      console.log(e);
      console.log("Blocks not found :/");
    }
  }

  render() {
    let {
      blocks,
      title,
      description,
      setTitle,
      loading,
      id,
      slug,
      extraProps = {},
      loadingComponent
    } = this.props;
    if (
      this.state.slug !== slug ||
      this.state.id !== id ||
      this.state.blocks !== blocks
    )
      return null;
    if (!blocks && !loading) return <NotFound />;

    let { components } = this.state;
    if (!components || components.length !== blocks.length)
      return loadingComponent || null;

    return (
      <div className="gutenburg">
        {setTitle && title ? (
          <Head title={title} description={description} />
        ) : null}
        {blocks.map((block, index) => {
          if (!components[index]) return null;
          if (components[index].type) return components[index];
          return React.createElement(components[index].default, {
            key: index,
            pageTitle: title,
            ...block.attrs,
            ...extraProps
          });
        })}
      </div>
    );
  }
}

export default blockQuery(BlockRenderer);
