import React from 'react';
import {
  ItemWrapper,
  ItemTitle,
  ItemMeta,
  ItemMetaText,
  ItemMeasurement,
  Item,
  ItemImage,
  ShoppingButton,
  Info,
  Label,
} from './style';
import { Link } from 'react-router-dom';
import storage from "shared/storage";

export default (props) => {
  let { samples, products } = props;
  let project = window.location.pathname.includes("board/") ? storage.get("VbProject") : ""
  return (<React.Fragment>
    {samples.length ? (
      <ItemWrapper>
        <Label>Samples</Label>
        {samples.map(item => (
          <Item key={item.id} href={`/product/${item.sku}`}>
            <ItemImage
              src={item.image ? item.image.thumbnail : null}
              alt={item.name}
            />
            <Info>
              <ItemTitle>{item.name}</ItemTitle>
              <ItemMeta>
                <ItemMetaText>Quanity: {item.qty}</ItemMetaText>
                <ItemMeasurement>Type: {item.type}</ItemMeasurement>
              </ItemMeta>
              <ItemMeta>
                <ItemMetaText>Spec ID# {item.sku}</ItemMetaText>
              </ItemMeta>
            </Info>
          </Item>
        ))}
        <Link to={{pathname: "/checkout/sample", state: {VbProject: project} }}>
          <ShoppingButton>Checkout</ShoppingButton>
        </Link>
      </ItemWrapper>
    ) : null}

    {products.length ? (
      <ItemWrapper>
        <Label>Products</Label>
        {products.map(item => (
          <Item key={item.id} href={`/product/${item.sku}`}>
            <ItemImage src={item.image.thumbnail} alt={item.name} />
            <Info>
              <ItemTitle>{item.name}</ItemTitle>
              <ItemMeta>
                <ItemMetaText>Quanity: {item.qty}</ItemMetaText>
                <ItemMeasurement>SF</ItemMeasurement>
              </ItemMeta>
              <ItemMeta>
                <ItemMetaText>Spec ID# {item.sku}</ItemMetaText>
              </ItemMeta>
            </Info>
          </Item>
        ))}
        <Link to="/checkout/main">
          <ShoppingButton>Checkout</ShoppingButton>
        </Link>
      </ItemWrapper>
    ) : null}
  </React.Fragment>)
};
