import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ title, description, image }) => (
  <Helmet>
    {title ? <title>ASI - {title}</title> : null}
    <link rel="canonical" href={window.location.href} />
    {description ? <meta name="description" content={description} /> : null}
    <meta property="og:title" content={title} />
    <meta
      name="twitter:card"
      content={image ? 'summary_large_image' : 'summary'}
    />
    <meta name="twitter:site" content="@archsystems" />
    {description ? (
      <meta property="og:description" content={description} />
    ) : null}
    {image ? <meta property="og:image" content={image} /> : null}
    <meta property="og:url" content={window.location.href} />
  </Helmet>
);
