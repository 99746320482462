import React from "react";
import styled from "react-emotion";
import { Tablet } from "shared/layout/base/base";

export const Cards = styled(({ searchPage, ...props }) => <div {...props} />)`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  clear: both;

  @media (${Tablet}) {
    padding: 0;
    margin: 20px -5px 0;
    ${props => (props.searchPage ? `margin-top: 0;` : ``)};
    ${props => (props.searchPage ? `` : `justify-content: center;`)};
  }

  @media (min-width: 1120px) {
    margin: 20px -16px 0;
    ${props => (props.searchPage ? `margin-top: 0;` : ``)};
  }
`;

export const HeaderWrapper = styled("div")`
  display: flex;
`;

export const Wrapper = styled(({ noSidebar, ...props }) => <div {...props} />)`
  width: calc(50% - 10px);
  margin: 0 5px 10px;
  display: flex;

  ${props =>
    props.noSidebar
      ? `
      @media (min-width: 768px) {
        width: calc(33.33% - 32px);
        margin: 0 16px 32px;
      }
    `
      : `
      @media (min-width: 1119px) {
        width: calc(33.33% - 32px);
        margin: 0 16px 32px;
      }
      `};

  ${props =>
    props.project
      ? `
      /* NOTE: Project cards on desktop have a different aspect ratio than in mobile */
    > div {
      > div {
        &:after {
          padding-top: 83%;
        }
      }
    }
    `
      : null};
`;

export const Container = styled("div")`
  margin: 20px 0;

  @media (min-width: 768px) {
    margin: 40px auto 0;
    max-width: 1120px;
  }

  @media (max-width: 1119px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`;
