import elasticsearch from 'elasticsearch';

const client = new elasticsearch.Client({
  host: process.env.REACT_APP_ELASTICSEARCH_HOST,
});

const mapItems = posts =>
  posts
    .map(hit => {
      hit = { ...hit._source, ...hit._source.meta, id: hit._source.ID };
      Object.keys(hit).forEach(key => {
        if (Array.isArray(hit[key]) && hit[key][0] && hit[key][0].value)
          hit[key] = hit[key][0].value;
      });
      return hit;
    })
    .map(hit => ({
      ...hit,
      id: hit.ID,
      slug: hit.post_name,
      title: hit.post_title,
      excerpt: hit.post_excerpt,
      name:
        hit.meta.woo_product_display_name &&
        hit.meta.woo_product_display_name[0]
          ? hit.meta.woo_product_display_name[0].value || hit.post_title
          : hit.post_title,
      sku: hit.post_title,
      reviewStars: hit.woo_product_review_stars,
      hasSamples:
        hit.meta.hasSamples &&
        hit.meta.hasSamples[0] &&
        hit.meta.hasSamples[0].value === '1',
      hasColors:
        hit.meta.hasColors &&
        hit.meta.hasColors[0] &&
        hit.meta.hasColors[0].value === '1',
      gallery:
        hit.meta.arch_gallery_images && hit.meta.arch_gallery_images[0]
          ? JSON.parse(hit.meta.arch_gallery_images[0].value).map(
              thumbnail => ({
                thumbnail,
                full: thumbnail,
              }),
            )
          : hit.meta.featured_images &&
            hit.meta.featured_images[0] &&
            hit.meta.featured_images[0].value
            ? [
                {
                  full: hit.meta.featured_images[0].value,
                  thumbnail: hit.meta.featured_images[0].value,
                },
              ]
            : [],
      image:
        hit.meta.featured_images &&
        hit.meta.featured_images[0] &&
        hit.meta.featured_images[0].value
          ? { thumbnail: hit.meta.featured_images[0].value }
          : [],
      award: hit.meta.arch_gallery_award
        ? hit.meta.arch_gallery_award[0].value
        : null,

      concept: hit.meta.arch_gallery_concept
        ? hit.meta.arch_gallery_concept[0].value
        : null,

      location: hit.meta.arch_gallery_location
        ? hit.meta.arch_gallery_location[0].value
        : null,
      category: {
        name: hit.terms.category
          ? hit.terms.category[0].name
          : hit.terms.arch_project_cat && hit.terms.arch_project_cat[0]
            ? hit.terms.arch_project_cat[0].name
            : null,
        slug:
          hit.terms.arch_project_cat && hit.terms.arch_project_cat[0]
            ? hit.terms.arch_project_cat[0].slug
            : null,
      },
      collection: {
        name: hit.terms.collection_tax
          ? `${hit.terms.collection_tax[0].name}${
              hit.terms.arch_parent_product
                ? `, ${hit.terms.arch_parent_product[0].name}`
                : ''
            }`
          : '',
      },
    }));

export default async body => {
  let data = await client.search({
    index: process.env.REACT_APP_ELASTICSEARCH_INDEX,
    body,
  });

  let aggregations = [];

  //turn the aggregates into a normal array of items that make sense... lol
  if (data.aggregations) {
    let aggregates = Object.keys(data.aggregations);

    aggregates
      .map(key => [data.aggregations[key], key])
      .forEach(([aggregate, key]) => {
        let items = aggregate.buckets.reduce(
          (acc, bucket) => [...acc, ...bucket.only_one_post.hits.hits],
          [],
        );
        aggregations.push(...items);
      });
  }
  return {
    raw: data,
    total: data.hits.total,
    result: mapItems(data.hits.hits),
    aggregations: aggregations.length ? mapItems(aggregations) : undefined,
  };
};
