import styled from "react-emotion";
import * as Colors from "base/colors";
import { EncodeSans } from "shared/layout/base/base";

export const TableHeadings = styled("div")`
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 20px;

  h4 {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;

    &:first-child {
      flex-grow: 1;
    }
  }
`;

export const TableRow = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &:first-child {
    background: white;
    margin-top: 0;

    h4 {
      @media (min-width: 1119px) {
        color: black;
      }
    }
  }

  h4 {
    @media (min-width: 1119px) {
      color: transparent;
    }
  }

  @media (min-width: 1119px) {
    flex-direction: row;
    margin-top: 0;
  }
`;

export const Quantity = styled("span")`
  display: block;

  div {
    padding: 0;
    width: 70px;
  }

  input {
    border-radius: 3px;
    background-color: #fafafa;
    border: solid 1px #e0dfdf;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RemoveQTY = styled("button")`
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  color: ${Colors.ctaGreen};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ItemWrapper = styled("div")`
  flex-grow: 1;
`;

export const ItemInfo = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled("div")`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
