import { graphql } from 'react-apollo';

import gql from 'graphql-tag.macro';
export const query = gql`
  query Product($input: ProductInput!) {
    product(input: $input) {
      id
      sku
      name

      details

      variations {
        type
        name
        display
        placeholder
        products {
          sku
          image {
            thumbnail
          }
          name
        }
      }

      collection {
        name
      }

      gallery {
        full
        thumbnail
      }

      samples {
        key
        label
      }

      dimensions {
        key
        value
      }
    }
  }
`;
export default graphql(query, {
  options: ({ data }) => {
    let input = { slug: data.sku };

    return {
      variables: { input },
    };
  },
});
