import React from 'react';
import { Container, Image, Content, Title } from './style';

//Used for add FAQ and add Review
export default ({ data, children, loading, Quickview }) => (
  <Container loading={loading}>
    <Image loading={!data.gallery}>
      {data.gallery ? (
        <img src={data.gallery[0].thumbnail} alt={data.sku} />
      ) : null}
    </Image>

    <Content>
      {Quickview ? null : <Title>{data.name}</Title>}
      {children}
    </Content>
  </Container>
);
