import React from 'react';
import {
  ListItem,
  SubNavWrapper,
  SubNavContainer,
  LinkArrowWrapper,
} from '../style';
import { Link } from 'react-router-dom';
import LinkArrow from 'shared/layout/arrow-link';
import { ProjectStudio, ProjectStudioSubNav, Image } from './style';

export default class Project extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { show } = this.state;
    let { hideDropdown } = this.props;
    let { data = {} } = this.props;
    let { projectStudio } = data.menus;
    return (
      <ListItem
        onMouseEnter={() => {
          if (hideDropdown) return;
          //show the nav after half second delay due to client request ....
          this.hide = false;

          setTimeout(
            () => (this.hide ? null : this.setState({ show: true })),
            250,
          );
        }}
        onMouseLeave={() => {
          if (hideDropdown) return;
          this.hide = true;
          this.setState({ show: false });
        }}
        onClick={() => this.setState({ show: false })}
      >
        <Link to="/projects">
          <ProjectStudio />
          PROJECT STUDIO
        </Link>
        <SubNavWrapper show={show}>
          <SubNavContainer>
            <ProjectStudioSubNav>
              {projectStudio.map(item => (
                <ul key={item.slug}>
                  <li>
                    <Link to={item.slug}>
                      <span>{item.name}</span>
                      {item.image ? (
                        <Image
                          style={{
                            backgroundImage: `url(${item.image.medium})`,
                          }}
                        />
                      ) : null}
                    </Link>
                  </li>
                </ul>
              ))}
            </ProjectStudioSubNav>
            <LinkArrowWrapper>
              <LinkArrow text="Explore All Projects" to="/projects" />
            </LinkArrowWrapper>
          </SubNavContainer>
        </SubNavWrapper>
      </ListItem>
    );
  }
}
