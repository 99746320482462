import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Siliguri, EncodeSans } from 'shared/layout/base/base';

export const Container = styled(({ noPadding, ...props }) => (
  <div {...props} />
))`
  padding: ${props => (props.noPadding ? '20px 0' : '10px 20px;')};

  .Select,
  .Select-control {
    height: 50px;

    input {
      height: 100% !important;
      padding: 0;
    }
  }

  .Select-input {
    height: 38px;
  }

  @media (min-width: 930px) {
    padding: ${props => (props.noPadding ? '20px 0' : '20px 40px;')};
  }
`;

export const Test = styled('div')`
  background: green;
`;

export const DropdownBreaker = styled('span')`
  display: block;
  font-family: ${Siliguri};
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  text-align: center;
  color: ${Colors.titleGrey};
`;

export const DropdownTitle = styled('span')`
  font-family: ${Siliguri};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  color: ${Colors.titleGrey};
`;

export const Middle = styled('div')`
  font-family: ${Siliguri};
  font-size: 14px;
  font-weight: bold;
  display: block;
  color: ${Colors.titleGrey};
`;

export const MiddleContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: ${props => (props.noPadding ? '0' : '20px 45px;')};
`;

export const Option = styled('div')`
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  padding: 5px 25px;
  display: flex;
  align-items: center;

  img {
    width: 43px;
    height: 37px;
    display: block;
    margin-right: 15px;
  }

  span {
    &:first-of-type {
      margin-right: 20px;
    }
  }
`;
