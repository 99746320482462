import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans, Tablet } from 'shared/layout/base/base';

export const Wrapper = styled(({ loading, ...props }) => <div {...props} />)`
  background-color: ${Colors.white};
  transform: opacity 0.25s;
  opacity: ${props => (props.loading ? 0.5 : 1)};
  width: 100%;
`;

export const Container = styled('div')`
  padding: 20px;

  @media (${Tablet}) {
    padding: 40px;
  }

  textarea {
    min-height: 185px;
    height: 100%;
    padding: 15px 20px;
  }
`;

export const ButtonContainer = styled('div')`
  width: 100%;
  text-align: right;
`;

export const CreateButton = styled('button')`
  display: inline-block;
  min-width: 110px;
  border-radius: 3px;
  background-color: ${Colors.ctaGreen};
  border: 2px solid ${Colors.ctaGreen};
  padding: 16px 20px;
  color: ${Colors.white};
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.25s, color 0.25s;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${Colors.white};
    color: ${Colors.ctaGreen};
  }
`;

export const MainContainer = styled('div')`
  width: 100%;
  margin: auto;
}`;

export const DropdownContainer = styled('div')`
  width: 70%;
  float: left;
  padding-right: 20px;
  
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
  }
  
  .Select-placeholder, .Select-value {
    line-height: 30px!important;
  }

  .Select-option.is-focused {
    color: #61a60d;
  }

  .Select-option {
    color: #61a60d;
  }
}`;

export const ButtonWrapper = styled('div')`
  margin-left: 15%;
  padding-top: 14px;
  
  button {
    padding: 14px 20px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
}`;

export const Clear = styled('div')`
  clear:both;
}`;