import { graphql } from 'react-apollo';

import gql from 'graphql-tag.macro';
export const query = gql`
  query Boards($input: GetBoardsInput) {
    boards(input: $input) {
      id
      name
      description
      vision_board_project_id
    }
  }
`;
export default graphql(query, {
  options: props => {
    let input = { status: 'active' };

    return {
      variables: { input },
      fetchPolicy: "cache-and-network"
    };
  }
});
