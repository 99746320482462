import React from "react";
import Fields from "./fields";
import { compose } from "react-apollo";
import boardQuery from "../queries/boards";
import addItemsMutation from "../mutations/add-items";
import createBoardMutation from "../mutations/create-board";
import Modal from "shared/modals/section/save-cancel";
import { ModalBody } from "views/board/sections/filter/style";
import ModalWrapper from "shared/modals/desktop";
import { Button } from "shared/layout/base/base";
import { Link } from "react-router-dom";
import 'shared/bootbox/style.css';

class Form extends React.Component {
  constructor() {
    super();
    this.state = { 
        values: {},
        showPopup: false,
        showErrorPopup: false,
        boardId: ''
    };
  }
  
    getProjectData(vision_board_project_id)
    {
        let { values } = this.state;
        this.setState({
            values: {
                vision_board_project_id: vision_board_project_id
            }
        });
    }

    getVisionBoardData(board)
    {
        this.setState({
            values: {
                id: board.id,
                description: board.description,
                vision_board_project_id: board.vision_board_project_id
            }
        });
    }
    
  render() {
    let { product, data, close, addItems, createBoard, location } = this.props;
    let { values, errors, loading, showPopup, showErrorPopup, boardId } = this.state;
    if (!data.boards) return null;
    
    let path = '/products';
    
    if(product.__typename === 'Project') {
        path = '/projects';
    }
    
    return (
    <>
      <Modal loading={loading} data={product} noTitle close={close}>
        {({ render, buttons }) => (
          <Fields
            close={async values => {
              this.setState({ loading: true });

              //If board is new, create it otherwise update the board selected
              if (typeof values.id === "string") {
                
                let response = await createBoard({
                  name: values.id,
                  description: values.description,
                  vision_board_project_id: values.vision_board_project_id,
                  items: [product.id]
                });
                
                const id = response.data && response.data.createBoard ? response.data.createBoard.id : '';
                
                this.setState({ showPopup: true, boardId: id });
              } else {
                let response = await addItems({
                  board_id: Number(values.id),
                  description: values.description,
                  item_id: product.id
                });
                
                this.setState({ boardId: values.id });   
                
                if(response.errors) {
                    if(response.errors.duplicate_item) {
                        this.setState({ showErrorPopup: true });
                    }else {
                        close();
                    }
                }else {
                    this.setState({ showPopup: true });   
                }
              }
              
              this.setState({ loading: false });              
            }}
            values={values}
            errors={errors}
            render={render}
            boards={data.boards}
            buttons={buttons}
            onValues={values => this.setState({ values })}
            onErrors={errors => this.setState({ errors })}
            setProjectData={this.getProjectData.bind(this)}
	    setVisionBoardData={this.getVisionBoardData.bind(this)}
          />
        )}
      </Modal>
     <ModalWrapper
        show={showPopup}
        title={" "}
        onClose={() => {
                this.setState({ showPopup: false });
                close();
            }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <ModalBody>{`Successfully added to your Vision Board!`}</ModalBody>
          </div>
          <div className="modal-footer">
            <Button onClick={() => {
                this.setState({ showPopup: false });
                close();
            }}>
              CONTINUE BROWSING
            </Button>
            <Link
              to={{
                pathname: boardId ? `/board/${boardId}` : '/account/boards',          
              }}
            >
              <Button>
                View Your Board
              </Button>
            </Link>
          </div>
        </div>        
      </ModalWrapper>
      <ModalWrapper
        show={showErrorPopup}
        title={" "}
        onClose={() => {
                this.setState({ showErrorPopup: false });
                close();
            }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <ModalBody>{`This product already exists in selected Vision Board`}</ModalBody>
          </div>
          <div className="modal-footer">
            <Link
              to={{
                pathname: boardId ? `/board/${boardId}` : '/account/boards',          
              }}
            >
              <Button>
                View Your Board
              </Button>
            </Link>
            <Link
              to={{
                pathname: path,          
              }}
            >
                <Button>
                  New Selection
                </Button>
            </Link>
          </div>
        </div>        
      </ModalWrapper>
     </>
    );
  }
}

export default compose(
  boardQuery,
  addItemsMutation,
  createBoardMutation
)(Form);
