import React from 'react';
import { Container } from './style';
import { Button } from 'shared/layout/base/base';

export default ({ values, loading, submit }) => (
  <React.Fragment>
    <Container full>
      <p style={{ textAlign: 'center' }}>Submit to finish signup</p>
    </Container>

    <Container full>
      <Button submit loading={loading} onClick={() => submit(values)}>
        Submit
      </Button>
    </Container>
  </React.Fragment>
);
