import React from "react";
import {
  InfoWrapper,
  ProfileSubtext,
  ProfileWrapper,
  ProfileInfo,
  ProfileText,
  InfoLinks,
  SignOut
} from "./style";
import { User } from "../style";
import storage from "shared/storage";
import { Link } from "react-router-dom";

export default ({ show, onClick }) => {
        
  let archsystems_users = false;
  
  if(storage.get("user"))
  {
    const email = storage.get("user").user_email;
    const email_split = email.split('@');
    archsystems_users = (email_split[1] && email_split[1] == 'archsystems.com')?true:false;
  }
   
return(
  <InfoWrapper show={show} onClick={onClick}>
    {storage.get("user") ? (
      <ProfileWrapper>
        <User />
        <ProfileInfo>
          <ProfileText>My ASI</ProfileText>
          <ProfileSubtext>{storage.get("user").user_nicename}</ProfileSubtext>
        </ProfileInfo>
      </ProfileWrapper>
    ) : null}
    {storage.get("user") ? (
      <InfoLinks>
        {storage.get("user").user_role == 'employee' ||
        storage.get("user").special_users ||
        storage.get("user").sales_access ||
        storage.get("user").order_access ||
        storage.get("user").quote_access ||
        storage.get("user").archive_access || 
        storage.get("user").data_entry_access ||
        archsystems_users
        ? (
            <li>
              <Link to="/digital-library">Digital Library</Link>
            </li>
        ): null}
        {storage.get("user").data_entry_access ? null : (
            <li>
              <Link to="/specs-catalog">Collection Details</Link>
            </li>
        )}

        {storage.get("user").special_users ||
        storage.get("user").archive_access ? (
          <li>
            <Link to="/sample-request">Request Samples</Link>
          </li>
        ) : null}
        {storage.get("user").quote_access ||
        storage.get("user").order_access ||
        storage.get("user").archive_access ? (
          <li>
            <Link to="/request-quote">Request Quote</Link>
          </li>
        ) : null}
        {storage.get("user").order_access ||
        storage.get("user").archive_access ? (
          <li>
            <Link to="/purchase-order">Place Order</Link>
          </li>
        ) : null}
        {storage.get("user").special_users ||
        storage.get("user").archive_access ||
        storage.get("user").user_role === "contractor" ||
        storage.get("user").user_role === "designer" ? (
          <li>
            <Link to="/account/orders/samples">Sample Requests</Link>
          </li>
        ) : null}
        {storage.get("user").data_entry_access ||
        storage.get("user").archive_access ||
        storage.get("user").order_access ? (
          <li>
            <Link to="/account/orders">Activity History</Link>
          </li>
        ) : null}
        {storage.get("user").data_entry_access ||
        storage.get("user").quote_access ||
        storage.get("user").archive_access ? (
          <li>
            <Link to="/account/quotes/submitted">Quote History</Link>
          </li>
        ) : null}
        <li>
          <Link to="/account">Account</Link>
        </li>

        {storage.get("user").data_entry_access ||
        storage.get("user").user_role === "spartan_sales" ||
        storage.get("user").user_role === "customer_service" ||
        storage.get("user").user_role === "contractor" ||
        storage.get("user").user_role === "designer" ? null : (
          <li>
            <Link to="/account/boards">Vision Boards</Link>
          </li>
        )}
      </InfoLinks>
    ) : null}

    {storage.get("user") ? (
      <SignOut
        onClick={() => {
          storage.clearUser();
          window.location = "/";
        }}
      >
        Sign out
      </SignOut>
    ) : (
      <Link to={storage.get("auth-link", "/auth/signup")}>
        <SignOut>Sign in</SignOut>
      </Link>
    )}
  </InfoWrapper>
)};
