import { graphql } from 'react-apollo';
import qs from "qs";
import gql from 'graphql-tag.macro';
export const query = gql`
  query VbProjects($input: GetVbProjectsInput) {
    vbProjects(input: $input) {
      vision_board_project_id
      name
      description
      status
      opportunity_id
    }
  }
`;
export default graphql(query, {
  options: props => {

    let { status } = qs.parse(window.location.search.substring(1));
    let input = { status: status || 'active'};

    return {
      variables: { input },
      fetchPolicy: "cache-and-network"
    };
  }
});