import React from "react";
import social from "./social";

export default component => {
  class SocialLoader extends React.Component {
    constructor() {
      super();
      this.state = { active: "insta" };
    }

    render() {
      let { active } = this.state;
      let { data, ...props } = this.props;
      if (!data.socialPosts) return null;

      return React.createElement(component, {
        ...props,
        active,
        data: data.socialPosts[active],
        activateInsta: e => {
          e.stopPropagation();
          this.setState({ active: "insta" });
        },
        activatePinterest: e => {
          e.stopPropagation();
          this.setState({ active: "pinterest" });
        }
      });
    }
  }

  return social(SocialLoader);
};
