import { graphql } from 'react-apollo';
import gql from 'graphql-tag.macro';
export const query = gql`
  query User {
    user {
      id
      role
      first_name
      last_name

      email
      phone
      company
      AccountId
      primary_contact__c
      professions {
        active
        options {
          name
          icon
        }
      }
    }

    validation(rules: "users/update") {
      name
      value
    }
  }
`;
export default graphql(query, {
  options: { fetchPolicy: 'cache-and-network' },
});
