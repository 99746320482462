import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Tablet, Siliguri } from 'shared/layout/base/base';

export const GridWrapper = styled('div')`
  display: block;
  text-align: center;
`;

export const Continue = styled('div')`
  button {
    width: calc(100% - 40px);

    @media (${Tablet}) {
      width: auto;
    }
  }
`;

export const FourColumnGridWrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  align-items: center;
  margin: 0 20px;

  @media (min-width: 500px) {
    margin: 0;
  }

  @media (${Tablet}) {
    margin: 0 -20px 40px;
  }

  li {
    width: calc(50% - 20px);
    margin: 0 10px;

    @media (min-width: 500px) {
      width: calc(33.33% - 40px);
      margin: 0 20px;
    }

    @media (${Tablet}) {
      width: calc(25% - 40px);
    }
  }
`;

export const ButtonWrapper = styled('button')`
  border: none;
  cursor: pointer;
  width: ${props => props.fifty ? "50%" : "100%"};
  margin-bottom: 30px;

  &:hover {
    span {
      background-color: ${Colors.ctaGreen};
      border-color: ${Colors.ctaGreen};

      svg {
        fill: ${Colors.white};
      }
    }
  }
`;

export const IconWrapper = styled(({ selected, ...props }) => (
  <span {...props} />
))`
  border: solid 1px #e0dfdf;
  background-color: ${props =>
    props.selected ? `${Colors.ctaGreen}` : 'rgba(216, 216, 216, 0.13)'};

  position: relative;
  margin-bottom: 20px;
  transition: background-color 0.25s, border-color 0.25s;
  display: inline-block;
  width: 100%;
  height: 0;
  padding: 50% 0;
  border-radius: 50%;

  svg {
    width: 40%;
    height: 40%;
    left: 50%;
    transition: fill 0.25s;
    fill: ${props => (props.selected ? Colors.white : Colors.ctaGreen)};

    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ProjessionTitle = styled('p')`
  font-family: ${Siliguri};
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.titleGrey};
  width: 100%;
  margin: 0;
`;
