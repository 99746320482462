import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
export const query = gql`
  query Blocks($slug: String, $id: Int) {
    blocks(slug: $slug, id: $id) {
      title
      description

      items {
        name
        attrs
        innerHtml
        innerBlocks {
          name
          attrs
          innerHtml
          innerBlocks {
            name
            attrs
            innerHtml
          }
        }
      }
    }
  }
`;
export default graphql(query, {
  options: ({ slug, id }) => {
    return {
      variables: { slug, id },
      fetchPolicy: "cache-and-network"
    };
  },
  props: ({ data, title, description, ownProps }) => ({
    ...ownProps,
    ...data,
    title: data.blocks && data.blocks.title ? data.blocks.title : title,
    description:
      data.blocks && data.blocks.description
        ? data.blocks.description
        : description,
    blocks:
      data.blocks && data.blocks.items
        ? data.blocks.items.map(block => ({
            ...block,
            attrs: JSON.parse(block.attrs)
          }))
        : null
  })
});
