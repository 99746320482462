import React from "react";
import ReactDOM from "react-dom";
import styled from "react-emotion";
import { Tablet, Montserrat } from "shared/layout/base/base";
import Icon from "icons";
import * as Colors from "base/colors";

const Wrapper = styled("div")`
  background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(38, 38, 38, 0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;

  iframe {
    display: block;
  }
`;

const Button = styled("button")`
  display: block;
  width: 15px;
  cursor: pointer;
  padding: 0;

  @media (${Tablet}) {
    width: 24px;
    height: 28px;
  }

  svg {
    width: 100%;
    fill: ${Colors.ctaGreen};
  }

  &:focus {
    outline: none;
  }
`;

export const Heading = styled("div")`
  background-color: ${Colors.backgroundGrey};
  padding: 20px;
  border: 1px solid ${Colors.borderColor};
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;

  @media (${Tablet}) {
    font-size: 30px;
    padding: 20px 40px;
  }
`;

const Container = styled(({ overflow, ...props }) => <div {...props} />)`
  max-height: calc(100% - 100px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  max-height: 90%;
  background-color: ${Colors.white};
  width: 90%;
  overflow-y: auto;
  ${props => (props.overflow ? "overflow: visible;" : "")};
  -webkit-overflow-scrolling: touch;

  @media (${Tablet}) {
    height: auto;
    max-height: 80vh;
    max-width: 926px;
  }
`;

export default class DesktopModal extends React.Component {
  constructor() {
    super();
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    let { show } = this.props;

    if (show) window.addEventListener("click", this.close);
  }

  componentDidUpdate(prevProps) {
    let { show } = this.props;

    if (!show && prevProps.show)
      window.removeEventListener("click", this.close);

    if (show && !prevProps.show)
      setTimeout(() => window.addEventListener("click", this.close), 100);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  close(e) {
    e.stopPropagation();
    let { onClose } = this.props;
    window.removeEventListener("click", this.close);

    onClose();
  }

  render() {
    let { show, title, children, overflow } = this.props;
    if (!show) return null;
    let wpEditor = document.querySelectorAll(".block-editor");

    if (wpEditor.length > 0) {
      return false;
    }

    return ReactDOM.createPortal(
      <Wrapper>
        <Container onClick={e => e.stopPropagation()} overflow={overflow}>
          {title ? (
            <Heading>
              {title}
              <Button onClick={this.close}>
                <Icon name="close" />
              </Button>
            </Heading>
          ) : (
            false
          )}

          {children}
        </Container>
      </Wrapper>,
      document.getElementById("desktop-modal")
    );
  }
}
