import React from "react";
import { css } from "emotion";
import { white } from "base/colors";
import { Montserrat, Tablet } from "shared/layout/base/base";
import { Heading } from "./style";
import Icon from "icons";

const PageHero = css`
  height: 320px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.71);
    z-index: 1;
  }

  h1 {
    color: ${white};
    font-family: ${Montserrat};
    @media (${Tablet}) {
      font-size: 45px;
    }
    font-weight: 200;
    position: relative;
    z-index: 2;
  }
`;

export default ({ text, image }) => (
  <div
    className={PageHero}
    style={{
      backgroundImage: `url(${image || "http://via.placeholder.com/350x150"})`
    }}
  >
    {text === "Project Spotlight" ? (
      <Heading>
        Project Sp
        <Icon name={"spotlight"} />
        tlight
      </Heading>
    ) : (
      <h1>{text}</h1>
    )}
  </div>
);
