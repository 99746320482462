import React from "react";
import styled from "react-emotion";
import { brandPink, ctaGreen, white } from "base/colors";
import { Tablet, EncodeSans, Siliguri } from "shared/layout/base/base";
import * as Colors from "base/colors";

export const Image = styled(({ singleProduct, ...props }) => (
  <div {...props} />
))`
  height: 130px;
  background-image: url("${props => props.url}");
  background-size: cover;
  margin: 10px;
  cursor: pointer;

  @media (${Tablet}) {
    height: 234px;
`;

export const ImageWrapper = styled(({ column, loading, ...props }) => (
  <div {...props} />
))`
  position: relative;
  max-width: ${props => (props.column ? "50%" : `unset`)};
  width: ${props => (props.column ? "100%" : `auto`)};

  ${props => (props.loading ? `height: 254px` : "")};

  @media (min-width: 1000px) {
    ${props => (props.hover ? "min-width: 168px" : `min-width: unset`)};
    ${props => (props.hover ? "cursor: pointer" : ` `)};
    background-color: white;

    &:hover {
      background-color: white;

      button {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  img {
    width: 100%;
    display: block;
    padding: 10px;
    position: absolute;
    top: 0;
    max-height: 100%;
  }

  &:after {
    content: "";
    padding-top: 100%;
    display: block;
  }
`;

export const ProjectImageWrapper = styled("div")`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
`;

export const Button = styled("button")`
  padding: 13px 6px;
  width: 93px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${ctaGreen};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-family: ${EncodeSans};
  font-size: 10px;
  font-weight: 500;
  border: 2px solid ${Colors.ctaGreen};
  color: ${Colors.white};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0.25s, color 0.25s,
    background-color 0.25s;
  text-transform: uppercase;

  & + button {
    margin-left: 10px;
  }

  &:hover {
    background-color: ${Colors.white};
    color: ${Colors.ctaGreen};
  }
`;

export const Text = styled("p")`
  font-family: "Encode Sans" !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin: 0;
  color: ${white};
`;

export const Buttons = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: color 0.25s, background-color 0.25s;
`;

export const Wrapper = styled("div")`
  display: none;
  flex-direction: row;
  max-width: 196px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;

  @media (${Tablet}) {
    display: flex;
    justify-content: center;

    button {
      margin-bottom: 0;
    }
  }
`;

export const NewContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${brandPink};
  width: 46px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NewBanner = styled("div")`
  font-family: ${Siliguri};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${Colors.white};
`;
