import React from "react";
import Image from "./image";
import Samples from "./samples";
import Colors from "./more-colors";
import { Link } from "react-router-dom";
import { lightTextGrey } from "base/colors";
import {
  Container,
  Content,
  Title,
  SpecId,
  ContentWrapper,
  CollectionLink
} from "./style";

const Card = ({ product, column, collection, noSave, loading }) => {
  return (
    <Container column={column}>
      <Image
        product={product}
        column={column}
        noSave={noSave}
        loading={loading}
      />
      <Content column={column}>
        <ContentWrapper>
          <Link to={product ? `/product/${product.sku.toLowerCase()}` : "/"}>
            <Title bold> Spec ID# {product ? product.sku : ""}</Title>
          </Link>

          <Link to={product ? `/product/${product.sku.toLowerCase()}` : "/"}>
            <SpecId color={lightTextGrey}>
              {product ? product.woo_product_display_name : " "}
            </SpecId>
          </Link>
          {product ? (
            <Samples hasSamples={product.hasSamples} sku={product.sku} />
          ) : null}
          {product ? <Colors product={product} column={column} /> : null}
        </ContentWrapper>
      </Content>
      {collection ? (
        <CollectionLink>
          <Link
            to={
              product.collection.slug
                ? `/collections/${product.collection.slug}`
                : "/"
            }
          >
            <span>From:</span>
            {product.collection.name}
          </Link>
        </CollectionLink>
      ) : null}
    </Container>
  );
};
export default Card;
