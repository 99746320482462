export default (post_title, results) => {
  return [
    { post_title },
    { name: 'in Projects', filter: `${post_title}&filter=projects` },
    { name: 'in Collections', filter: `${post_title}&filter=collections` },
    { name: 'in Materials', filter: `${post_title}&filter=materials` },
    { name: 'in Community', filter: `${post_title}&filter=community` },
    ...results,
  ];
};
