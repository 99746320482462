import * as Colors from 'base/colors';
import styled from 'react-emotion';
import { Button } from 'shared/layout/base/base';

export const ItemWrapper = styled('div')`
  position: relative;
  margin-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  button {
    min-width: unset;
    width: 100%;
  }
`;

export const Label = styled('span')`
  color: ${Colors.black};
  font-family: 'Hind Siliguri', sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  color: ${Colors.titleGrey};
`;

export const Item = styled('a')`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ItemImage = styled('img')`
  width: 54px;
  height: 54px;
  display: block;
`;

export const Info = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemTitle = styled('span')`
  font-family: 'Encode Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.titleGrey};
  padding-left: 10px;
  margin-bottom: 10px;
`;

export const ItemMeta = styled('div')`
  position: relative;
  display: flex;
`;

export const ItemMetaText = styled('span')`
  font-family: 'Encode Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.lightTextGrey};
  padding: 0 10px;
`;

export const ItemMeasurement = styled(ItemMetaText)`
  border-left: 1px solid #dad9d9;
`;

export const ShoppingButton = styled(Button)`
  width: 100%;
`;
