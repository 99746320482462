import { graphql } from 'react-apollo';
import gql from 'graphql-tag.macro';
export const query = gql`
  mutation UpdateUserRole($input: UpdateUserRole!) {
    updateRole(input: $input) {
      role
    }
  }
`;
export default graphql(query, {
  props: ({ mutate }) => ({
    updateUserRole: async input => {
      try {
        return await mutate({
          variables: { input },
        });
      } catch (e) {
        return null;
      }
    },
  }),
});
