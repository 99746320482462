import React from 'react';
import {
  NextButton,
  PrevButton,
  Pagination,
  PaginationWrapper,
  PaginationButton
} from 'shared/layout/base/base';
import Caret from 'icons/caret';

export default ({ maxPages, currentPage, changePage }) => {
  if (maxPages === 1) return null;
  let pages = [];
  for (let page = 1; maxPages >= page; page++) {
    let distance = page - parseInt(currentPage, 10);
    if (distance > 5 || distance < -5) continue;
    pages.push(
      <li
        onClick={() =>
          parseInt(currentPage, 10) !== page ? changePage(page) : null
        }
        key={page}
      >
        <PaginationButton active={page === parseInt(currentPage, 10)}>
          {page}
        </PaginationButton>
      </li>
    );
  }
  return (
    <PaginationWrapper>
      {currentPage !== 1 ? (<PrevButton
        onClick={() =>
          parseInt(currentPage, 10) > 1
            ? changePage(parseInt(currentPage, 10) - 1)
            : null
        }
      >
        <Caret />
      </PrevButton>): null}
      <Pagination>{pages}</Pagination>
      {currentPage !== maxPages ? (<NextButton
        onClick={() =>
          parseInt(currentPage, 10) < maxPages
            ? changePage(parseInt(currentPage, 10) + 1)
            : null
        }
      >
        <Caret />
      </NextButton>): null}
    </PaginationWrapper>
  );
};
