import React from 'react';
import Product from './product';
import Sample from './sample';

export default ({ sample, product, showQuantity, qty }) =>
  product ? (
    <Product product={product} showBackorder={false} qty={qty} />
  ) : (
    <Sample sample={sample} showBackorder={true} showQuantity={showQuantity} />
  );
