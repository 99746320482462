import styled from 'react-emotion';
import { Tablet } from 'shared/layout/base/base';
import { SiteContainer } from 'shared/layout/base/base';

export const Wrapper = styled('div')`
  margin: 40px 0;

  @media (max-width: 1119px) {
    padding: 0 20px;
  }

  @media (${Tablet}) {
    display: flex;
    margin: 80px 0;
  }
`;

/* 
  Define components here to wrap columns, if you put a classname 
  in gutenberg it will wrap the component with the matching name here
*/

export const sidebar = styled('div')`
  @media (${Tablet}) {
    width: 258px;
    min-width: 258px;
  }
`;

export const sitecontainer = SiteContainer;

export const container = styled('div')`
  width: 100%;
  margin-right: 30px;
  margin-bottom: 0;
`;

export const collectionsHero = styled(SiteContainer)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (${Tablet}) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;
