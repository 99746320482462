import React from 'react';
import { withRouter } from 'react-router-dom';
/* globals ga, analytics, piTracker */

class ScrollToTop extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (
        !this.props.location.pathname.match(/collections/) &&
        !this.props.location.pathname.match(/quotes\/submitted/)
      )
        window.scrollTo(0, 0);

      //Set to Google analytics
      if (typeof ga !== 'undefined') {
        ga('set', 'title', document.title);
        ga('set', 'page', window.location.pathname);
        ga('send', 'pageview');
      }

      if (typeof piTracker !== 'undefined') {
        piTracker(window.location.href);
      }

      if (typeof analytics !== 'undefined') {
        let { location } = this.props;
        analytics.page({
          path: location.pathname,
          referrer: document.referrer,
          search: location.search,
          title: document.title,
          url: location.href
        });
      }
    }
  }

  render() {
    return this.props.children(window.location.search);
  }
}

export default withRouter(ScrollToTop);
