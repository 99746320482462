import React from "react";
import styled from "react-emotion";
import { EncodeSans, Tablet } from "shared/layout/base/base";
import * as Colors from "base/colors";

export const Container = styled(({ column, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: ${props => (props.column ? "row" : "column")};
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: rgba(50, 50, 50, 0.2) 0px 0px 2px 0px;
  background-color: white;
  width: 100%;
  height: ${props => (props.column ? "auto" : "100%")};
`;

export const ContentWrapper = styled("div")`
  display: block;
  margin-bottom: 50px;

  @media (min-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const Content = styled(({ column, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 10px;
  text-align: left;
  border-top: ${props =>
    props.column ? "none" : `1px solid ${Colors.borderColor}`};
  border-left: ${props =>
    props.column ? `1px solid ${Colors.borderColor}` : "none"};
  min-width: ${props => (props.column ? "50%" : `unset`)};
  width: ${props => (props.column ? "100%" : `auto`)};
  height: ${props => (props.column ? "auto" : "100%")};

    @media (${Tablet}) {
      padding: 20px;
    }
  }
`;

export const Title = styled("p")`
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  line-height: 1.77;
  margin: 0;
  color: ${Colors.black};
`;

export const SpecId = styled(({ color, ...props }) => <p {...props} />)`
  font-family: ${EncodeSans};
  font-size: 12px;
  margin: 0 0 10px 0;
  ${props =>
    props.color ? `color: ${props.color}` : `${Colors.lightTextGrey}`};
`;

export const ColorText = styled(({ column, ...props }) => <div {...props} />)`
  display: flex;
  color: ${Colors.ctaGreen};
  font-family: ${EncodeSans};
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 20px);
  left: 10px;
  line-height: 1.92;
  align-items: center;

  @media (${Tablet}) {
    width: calc(100% - 40px);
    bottom: 15px;
    left: ${props => (props.column ? "10px" : `20px`)};
  }

  &:hover {
    cursor: pointer;

    a {
      color: ${Colors.ctaGreen};
    }

    p {
      color: ${Colors.ctaGreen};
    }
  }
`;

export const Request = styled("div")`
  display: flex;
  color: ${Colors.ctaGreen};
  font-family: ${EncodeSans};
  font-size: 12px;
  width: calc(100% - 20px);
  line-height: 1.92;
  align-items: center;

  @media (${Tablet}) {
    width: calc(100% - 40px);
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const CollectionLink = styled("div")`
  a {
    color: ${Colors.ctaGreen};
    padding: 10px 20px;
    display: block;
    font-size: 13px;
    font-weight: 500;
    line-height: 2.46;
    background-color: #fbfafa;
    border-top: 1px solid #e0dfdf;

    span {
      color: ${Colors.black};
      margin-right: 5px;
      display: inline-block;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;
