import React from 'react';

const Facebook = props => (
  <svg {...props} viewBox="0 0 10 20" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g transform="translate(-603.000000, -437.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(334.000000, 169.000000)">
            <path d="M40.044834,3.87340266 C40.044834,4.37741092 40.044834,6.62701102 40.044834,6.62701102 L38.0274524,6.62701102 L38.0274524,9.994134 L40.044834,9.994134 L40.044834,20.0000394 L44.1889639,20.0000394 L44.1889639,9.994413 L46.9698674,9.994413 C46.9698674,9.994413 47.2303112,8.37990329 47.3565574,6.61459565 C46.9946052,6.61459565 44.2046342,6.61459565 44.2046342,6.61459565 C44.2046342,6.61459565 44.2046342,4.65571052 44.2046342,4.3123581 C44.2046342,3.96826167 44.65647,3.50540549 45.1030513,3.50540549 C45.5487957,3.50540549 46.4895739,3.50540549 47.3609284,3.50540549 C47.3609284,3.04696676 47.3609284,1.46296073 47.3609284,3.94431554e-05 C46.1976965,3.94431554e-05 44.8743202,3.94431554e-05 44.2909838,3.94431554e-05 C39.9423955,-0.000193054427 40.044834,3.37027789 40.044834,3.87340266 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Facebook;
