import React from 'react';

const Pinterest = props => (
  <svg
    {...props}
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(-509.000000, -436.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(243.000000, 168.000000)">
            <g transform="translate(35.000000, 0.000000)">
              <path d="M15.4668678,5.56126341 C14.920643,1.60878184 10.9818952,-0.403705343 6.77941009,0.0675183893 C3.45691271,0.440015404 0.144407245,3.12627851 0.00690531146,6.96749207 C-0.0768540901,9.31246748 0.588163486,11.0712093 2.81940375,11.566241 C3.78565911,9.85626625 2.50817404,9.48249454 2.30817123,8.24374357 C1.48940971,3.18125461 8.14813822,-0.276236445 11.6356461,3.26127218 C14.0506472,5.71126552 12.4593831,13.2437316 8.56940237,12.4600035 C4.84315753,11.7125012 10.3943869,5.71628204 7.42066089,4.54003194 C5.00315156,3.58253493 3.7194164,7.46503196 4.86564963,9.39503443 C4.19314839,12.71379 2.74567738,15.8387517 3.33191095,19.9999944 C5.23189655,18.6199997 5.87314735,15.9812701 6.39815473,13.2275308 C7.35314349,13.8087889 7.86441713,14.4100309 9.08190958,14.5037823 C13.5718987,14.8524714 16.0831182,10.0212357 15.4668678,5.56126341 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Pinterest;
