import React from 'react';

const Search = props => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <g transform="translate(-270.000000, -350.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(6.000000, 82.000000)">
            <path d="M52.4993089,18.5795918 L47.7534027,13.7714286 C48.9298073,12.3183673 49.6388731,10.4571429 49.6388731,8.42857143 C49.6388731,3.7755102 45.912249,0 41.3194365,0 C36.7225953,0 33,3.7755102 33,8.42857143 C33,13.0816327 36.7225953,16.8571429 41.3194365,16.8571429 C43.3217416,16.8571429 45.1548378,16.1428571 46.5890845,14.9510204 L51.3349906,19.755102 C51.6572933,20.0816327 52.1770063,20.0816327 52.4993089,19.755102 C52.8216115,19.4326531 52.8216115,18.9020408 52.4993089,18.5795918 Z M41.3194365,15.1795918 C37.6411578,15.1795918 34.651801,12.1510204 34.651801,8.42857143 C34.651801,4.70612245 37.6411578,1.67346939 41.3194365,1.67346939 C44.9936865,1.67346939 47.9870721,4.70612245 47.9870721,8.42857143 C47.9870721,12.1510204 44.9936865,15.1795918 41.3194365,15.1795918 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Search;
