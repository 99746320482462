import React from 'react';

const Cart = props => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(-1203.000000, -346.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(942.000000, 78.000000)">
            <g transform="translate(30.000000, 0.000000)">
              <g>
                <g>
                  <path d="M8.32530488,20.8128049 C7.17676829,20.8128049 6.24390244,21.7437805 6.24390244,22.8942073 C6.24390244,24.0427439 7.17676829,24.9756098 8.32530488,24.9756098 C9.47481707,24.9756098 10.4064024,24.0427439 10.4064024,22.8942073 C10.4064024,21.7437805 9.47481707,20.8128049 8.32530488,20.8128049 Z" />
                  <path d="M17.6911585,20.8128049 C16.5416463,20.8128049 15.6097561,21.7437805 15.6097561,22.8942073 C15.6097561,24.0427439 16.5416463,24.9756098 17.6911585,24.9756098 C18.8406707,24.9756098 19.7722561,24.0427439 19.7722561,22.8942073 C19.7722561,21.7437805 18.8406707,20.8128049 17.6911585,20.8128049 Z" />
                  <path d="M24.9756098,4.1625 L4.82,4.1625 L4.48780488,2.05487805 C4.30890244,0.924695122 3.22652439,0 2.08140244,0 L0,0 L0,2.08109756 L2.38420732,2.08109756 L5.01542683,18.7439024 C5.10469512,19.3089634 5.64640244,19.7722561 6.21859756,19.7722561 L22.8942073,19.7722561 L22.8942073,17.6908537 L6.95634146,17.6908537 L6.6272561,15.6097561 L22.8942073,15.6097561 L24.9756098,4.1625 Z M21.1577439,13.5283537 L6.29884146,13.5283537 L5.14841463,6.24390244 L22.4818293,6.24390244 L21.1577439,13.5283537 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Cart;
