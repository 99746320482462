import React from "react";
import qs from "qs";
import Company from "./company";
import { compose } from "react-apollo";
import NewContact from "./new-contact";
import NewCompany from "./new-company";
import Address from "shared/user/address";
import { Redirect } from "react-router-dom";
import { FormContainer, Container } from "./style";
import userQuery from "views/account/my-account/queries/user";
import createSalesforceMutation from "./gql/create-salesforce";
import HeadingUnderlined from "shared/layout/heading-underlined";
import storage from "shared/storage";

class PersonalInfo extends React.Component {
  constructor() {
    super();
    this.state = { values: {}, company: {}, role: "" };
  }

  async submit(values, pending) {
    let { company } = this.state;
    let { createSalesforce } = this.props;

    this.setState({ loading: true });
    let newValues = { ...values };
    delete newValues.address_id;
    let res = await createSalesforce({
      ...newValues,
      AccountId: company ? company.id : null
    });

    if (res && res.data && res.data.createSalesForceAccount) {
      storage.set("user", {
        ...storage.get("user"),
        ...res.data.createSalesForceAccount
      });

      console.log("res", res);
      return this.setState({
        done: true,
        pending,
        role: res.data.createSalesForceAccount.user_profession
      });
    }

    this.setState({ loading: false });
  }

  render() {
    let { data, close,pad_0_25, submitBtn, userModal, reload } = this.props;
    if (!data.user) return null;

    let { values, errors, loading, user, done, company, pending } = this.state;
    let { redirect } = qs.parse(window.location.search.substring(1));
    if (!userModal){
      if (done && !pending) return <Redirect to={redirect || "/"} />;
      if (pending)
        return (
          <Redirect
            to={{
              pathname: "/auth/signup/complete",
              state: { role: this.state.role }
            }}
          />
        );
    }
    if(done && userModal) {close(false); if(reload) window.location.reload(); }
    return (
      <React.Fragment>
        <HeadingUnderlined
          text={
            values.address_id
              ? "Tell Us About Your Company"
              : "Tell Us About Yourself"
          }
        />

        <FormContainer pad_0_25={pad_0_25}>
          {values.address_id ? null : (
            <Container full>
              <Address
                type="shipping"
                buttonText={submitBtn ? "Submit" : "NEXT" }
                collapsed={false}
                extraData={{ isDefault: true }}
                onSelect={address_id =>
                  this.setState({ values: { ...values, address_id } })
                }
                value={values.address_id}
              />
            </Container>
          )}
          {values.address_id ? (
            <React.Fragment>
              <Company
                label="Search for your company"
                onSelect={company => this.setState({ company: company || {} })}
                company={company.id}
              />
              {company.id ? (
                <NewContact
                  values={values}
                  errors={errors}
                  loading={loading}
                  user={user}
                  update={value => this.setState(value)}
                  submit={values => this.submit(values)}
                />
              ) : (
                <NewCompany
                  values={values}
                  errors={errors}
                  loading={loading}
                  user={user}
                  update={value => this.setState(value)}
                  submit={values => this.submit(values, true)}
                />
              )}
            </React.Fragment>
          ) : null}
        </FormContainer>
      </React.Fragment>
    );
  }
}

export default compose(
  userQuery,
  createSalesforceMutation
)(PersonalInfo);
