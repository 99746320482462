import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans } from 'shared/layout/base/base';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  align-items: center;
  margin: 80px auto;

  a + a {
    margin-left: 10px;
  }

  img {
    display: block;
    max-width: 230px;
    height: auto;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const Title = styled('h1')`
  padding-bottom: 10px;
  margin-bottom: 40px;
  border-bottom: 2px solid ${Colors.brandHighLightPurple};
  font-family: Montserrat;
  font-size: 35px;
  font-weight: 200;
  color: ${Colors.black};
`;

export const Subtext = styled('p')`
  font-family: ${EncodeSans};
  font-size: 15px;
  line-height: 2.13;
  color: ${Colors.titleGrey};
  margin: 0 0 20px 0;
`;
