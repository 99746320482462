import React from 'react';
import Select from './gql/select';
import { Container } from './style';
import { query as findAccount } from './gql/find-account';

export default ({ company, label, placeholder, onSelect }) => (
  <Container full>
    <Select
      name="id"
      label={label}
      value={company}
      onSelect={onSelect}
      placeholder={placeholder}
      query={findAccount}
      items={data =>
        data && data.findAccount
          ? data.findAccount.map(item => ({
              label: item.name,
              value: item.id,
              ...item,
            }))
          : null
      }
      variables={name => ({
        name,
      })}
    />
  </Container>
);
