import React from 'react';
import { withRouter } from 'react-router-dom';
import Dropdown from 'shared/layout/dropdown';
import { Container, DropdownTitle, Option } from '../style';

export default withRouter(
  ({ data, placeholder, title, history, currentSku, noPadding }) => {
    return (
      <Container noPadding={noPadding}>
        {title ? <DropdownTitle>{title}</DropdownTitle> : null}
        <Dropdown
          searchable={false}
          items={data.map(item => ({
            label: item.name
              ? `${item.name} Spec ID: ${item.sku}`
              : `Spec ID: ${item.sku}`,
            value: item.sku.toLowerCase(),
            image: item.image
              ? item.image.thumbnail
                ? item.image.thumbnail
                : item.image.shop_thumbnail
              : '',
          }))}
          placeholder={placeholder}
          value={
            data.find(item => item.sku === currentSku)
              ? data.find(item => item.sku === currentSku).sku.toLowerCase()
              : null
          }
          optionRenderer={props => (
            <Option>
              <img src={props.image} alt={props.label} />
              <span>{props.label}</span>
            </Option>
          )}
          onChange={({ value }) => history.push(`/product/${value}`)}
        />
      </Container>
    );
  },
);
