import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans, Tablet, Siliguri } from 'shared/layout/base/base';

export const Container = styled('div')`
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e6e6e6;
  border-top: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  img {
    width: 100%;
  }
`;

export const ProjectDetails = styled('div')`
  width: 100%;
  padding: 10px;
  text-align: left;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (${Tablet}) {
    padding: 20px;
    min-height: 177px;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    fill: #333333;
  }
`;

export const Wrapper = styled('div')`
  margin-bottom: 60px;

  @media (${Tablet}) {
    margin-bottom: 80px;
  }
`;

export const Info = styled('div')`
  display: block;
`;

export const ProjectTitle = styled('p')`
  color: ${Colors.lightTextGrey};
  margin: 10px 0 0 0;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  line-height: 1.77;
`;

export const Designer = styled('p')`
  color: ${Colors.lightTextGrey};
  font-family: ${EncodeSans};
  margin: 0;
  font-size: 13px;
  line-height: 1.77;
`;

export const LinkWrapper = styled('div')`
  margin-top: auto;

  a {
    color: ${Colors.ctaGreen};
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 10px;
      height: 10px;
      fill: ${Colors.ctaGreen};
      margin-left: 5px;
    }
  }
`;

export const Label = styled('span')`
  font-family: ${EncodeSans};
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.lightTextGrey};
  margin-right: 5px;
  margin-bottom: 5px;
  display: none;

  @media (${Tablet}) {
    display: block;
  }
`;

export const Spotlight = styled('div')`
  width: 78px;
  height: 27px;
  background-color: ${Colors.brandPink};
  color: ${Colors.white};
  font-family: ${Siliguri};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: absolute;
  top: 20px;
  left: 0;
`;

export const Text = styled('div')`
  margin-bottom: 30px;

  @media (${Tablet}) {
    margin-bottom: 50px;
  }
`;

export const Image = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 134px;

  @media (${Tablet}) {
    height: 290px;
  }
`;

export const MarketSegment = styled('div')`
  a {
    color: ${Colors.ctaGreen};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      fill: #333333;
    }
  }
`;

export const IconWrapper = styled('div')`
  background-color: ${Colors.ctaGreen};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
`;

export const ImageWrapper = styled('div')`
  position: relative;
`;
