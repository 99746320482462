import { getQuery } from 'shared/query-string';

export default ({ collectionsPage, category, type, must = [], allRecords = false }) => {
  let query = getQuery();

  let matchers = Object.keys(query)
    .filter(
      key =>
        key !== 'from' &&
        key !== 'size' &&
        key !== 'query' &&
        key !== 'sort' &&
        key !== 'slug' &&
        key !== 'first' &&
        !allRecords,
    )
    .map(key => {
      let [section, ...rest] = key.split('.');
      let realKey = rest.join('.');

      if (query[key].length)
        return query[key].map(value => ({
          section,
          key: realKey,
          value: value,
        }));
      return [
        {
          section,
          key: realKey,
          value: query[key],
        },
      ];
    })
    .map(value => {
      return {
        bool: {
          should: value.map(value => ({ match: { [value.key]: value.value } })),
        },
      };
    }, []);

  let matchCategory;

  if (category)
    matchCategory = {
      match: {
        [`terms.${
          type === 'projects' ? 'arch_project_cat' : 'product_cat'
        }.slug`]: {
          query: category,
          operator: 'and',
        },
      },
    };

  let more = [];

  if (query.query)
    more = [
      {
        match: {
          post_title: { query: query.query, operator: 'or' },
        },
      },
      {
        match: {
          post_content: { query: query.query, operator: 'or' },
        },
      },
    ];

  let sorts = {
    low: {
      'meta.woo_product_tier_1_price.value': {
        order: 'asc',
      },
    },
    high: {
      'meta.woo_product_tier_1_price.value': {
        order: 'desc',
      },
    },
    new: {
      ID: {
        order: 'desc',
      },
    },
    collections: {
      menu_order: {
        order: 'asc',
      },
    },
  };
  let sort = [];
  if (query.sort) sort.push(sorts[query.sort]);
  if (collectionsPage || type === 'projects') sort.push(sorts.collections);
  let multiMatch;

  if (query.query)
    multiMatch = {
      multi_match: {
        fields: [
          'post_title^3',
          'terms.category.name',
          'terms.post_tag.name',
          'terms.archsystems_product.name',
          'terms.product_cat.name',
          'terms.collection_tax.name',
          'meta.woo_product_display_name.value',
          'terms.archsystems_specs.name',
          'terms.archsystems_filter.name',
        ],
        query: query.query,
      },
    };

  return {
    from: (!allRecords)?query.from || 0 : 0,
    size: (!allRecords)?query.size || 15 : 100000,
    sort,
    query: {
      bool: {
        minimum_should_match: more.length ? 0 : 0,
        must: [
          {
            bool: {
              should: [...more, multiMatch].filter(i => i),
            },
          },
          query.slug
            ? {
                match: {
                  'terms.collection_tax.slug': {
                    query: query.slug,
                    operator: 'and',
                  },
                },
              }
            : null,
          ...matchers,
          {
            match: {
              post_status: {
                query: 'publish',
                operator: 'and',
              },
            },
          },
          ...must.map(must => ({
            match: {
              [must.name]: {
                query: must.value,
                operator: 'and',
              },
            },
          })),
          matchCategory,
          {
            match: {
              post_type: {
                query: type === 'projects' ? 'archsystems_gallery' : 'product',
                operator: 'and',
              },
            },
          },
        ].filter(i => i),
      },
    },
  };
};
