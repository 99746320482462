import React from 'react';
import styled from 'react-emotion';
import * as Colors from 'base/colors';

export const Container = styled(({ show, ...props }) => <div {...props} />)`
  display: ${props => (props.show ? 'block' : 'none')};

  a {
    height: 54px;
    font-size: 13px;
    font-weight: 500;
    color: ${Colors.lightTextGrey};
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
  }
`;

export const SecondaryNavLink = styled(({ show, ...props }) => (
  <span {...props} />
))`
  display: block;

  > span {
    height: 54px;
    font-size: 13px;
    font-weight: 500;
    color: ${Colors.lightTextGrey};
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    text-decoration: none;

    svg {
      fill: ${Colors.ctaGreen};
      transform: ${props => (props.show ? 'rotate(90deg)' : '0')};
      transition: transform 0.25s;
    }
  }

  > a {
    font-size: 13px;
    font-weight: 500;
    color: ${Colors.lightTextGrey};
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
  }
`;
