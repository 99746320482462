import React from "react";
import UserIcon from "icons/user";
import * as Colors from "base/colors";
import styled, { css } from "react-emotion";
import { Tablet, Phone } from "shared/layout/base/base";

export const User = () => (
  <UserIcon
    className={css`
      width: 25px;
      height: 25px;
      fill: ${Colors.black};
      margin-right: 10px;
    `}
  />
);

export const SiteHeader = styled("header")`
  width: 100%;
  border-bottom: solid 1px #cccccc;
  position: fixed;
  z-index: 100;

  @media (${Phone}) {
    border-bottom: solid 1px #979797 !important;
  }

  @media (${Tablet}) {
    height: auto;
  }

  @media print {
    border-bottom: none;
    height: 0;
  }

  &:after {
    content: "";
    width: 100%;
    height: ${props => (props.show ? "100vh" : "0")};
    position: absolute;
    background-color: #262626;
    opacity: ${props => (props.show ? "0.75" : "0")};
    transition: opacity 0.25s;
    z-index: ${props => (props.show ? "0" : "-1")};
    top: 0;
    z-index: -1;
  }
`;

export const SiteHeaderTop = styled("div")`
  display: flex;
  align-items: center;
  background-color: ${Colors.white};
  min-height: 60px;

  @media (${Tablet}) {
    min-height: unset;
    padding: 20px 0 20px 0;
  }

  @media print {
    display: flex;
    padding: 0;
    min-height: unset;
    justify-content: space-between;
  }
`;

export const Logo = styled("h1")`
  color: ${Colors.black};
  max-width: 185px;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media (${Tablet}) {
    max-width: 230px;
    order: 1;
    padding: 0 20px;
  }

  @media print {
    padding: 0;
    padding-bottom: 5px;
    max-width: 150px;
  }
`;

export const MenuList = styled("div")`
  padding: 0;
  list-style: none;

  @print {
    display: none;
  }

  @media print {
    display: none;
  }

  li {
    a {
      padding: 20px;
      display: block;
      text-decoration: none;
      color: ${Colors.lightTextGrey};
      transition: color 0.25s;
      font-size: 13px;
      font-weight: 500;
      transition: font-size 0.25s;

      @media (${Tablet}) {
        font-size: 16px;
        border-bottom: 1px solid #e6e6e6;
      }

      &:hover {
        color: ${Colors.ctaGreen};
      }
    }
  }
`;

export const MenuButton = styled("button")`
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
  padding: 0 20px;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (${Tablet}) {
    border-left: 1px solid #cdcccc;
    padding: 0 20px;
    order: 5;
  }

  @media print {
    display: none;
  }
`;

export const MenuButtonWrapper = styled("span")`
  height: 20px;
  width: 26px;
  display: block;
  position: relative;

  @media (min-width: 769px) {
    &:hover {
      span {
        background-color: ${Colors.ctaGreen};
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${Colors.black};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out;
    top: 9px;

    ${props =>
      props.close ? "transform: rotate(45deg);" : " transform: translateX(0)"};

    @media (${Phone}) {
      ${props =>
        props.close
          ? props.backArrow
            ? "transform: translateX(4px);"
            : "transform: rotate(45deg);"
          : "transform: translateX(0)"};
    }

    &:first-child {
      top: 0;
      ${props =>
        props.close
          ? `width: 0%;
              left: 50%;
              top: 18px;
              transform: none;`
          : `top: 0;
              width: 100%;`};

      @media (${Phone}) {
        ${props =>
          props.close
            ? props.backArrow
              ? `transform: rotateZ(-45deg) translateY(0);
              top: 5px;
              width: 13px;
              left: 0;
              `
              : `width: 0%;
              left: 50%;
              top: 18px;
              transform: none;`
            : `transform: rotate(0deg);
                top: 0;
                width: 100%;`};
      }
    }

    &:last-child {
      top: 18px;

      ${props =>
        props.close
          ? `
          top: 9px;
            left: 50%;
            transform: rotate(-45deg);
            left: 0;`
          : `width: 100%;'
              top: 18px;
              transform: rotate(0deg);`};

      @media (${Phone}) {
        ${props =>
          props.close
            ? props.backArrow
              ? `width: 13px;
              top: 13px;
              transform: rotateZ(45deg) translateY(0);`
              : `top: 9px; left: 50%; transform: rotate(-45deg); left: 0;`
            : `width: 100%;'
              top: 18px;
              transform: rotate(0deg);`};
      }
    }
  }

  @media (${Tablet}) {
    width: 30px;
  }
`;

export const URLWrapper = styled("div")`
  order: 5;
  padding: 0 20px;
  display: none;

  @media print {
    display: block;
    padding: 0;
  }
  p {
    font-family: "Hind Siliguri", sans-serif;
    font-weight: 500;
    color: #7d7c7c;
  }
`;
