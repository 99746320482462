import React from 'react';
import BlockRenderer from 'shared/blocks/render';
import storage from "shared/storage";
import { Redirect } from 'react-router-dom';

export default ({ match }) => {
  let archsystems_users = false;
  
  if(storage.get("user"))
  {
    const email = storage.get("user").user_email;
    const email_split = email.split('@');
    archsystems_users = (email_split[1] && email_split[1] == 'archsystems.com')?true:false;
  }

  if((match.url === '/digital-catalog' && !storage.get("user")) || (match.url === '/digital-library' &&
    (!storage.get("user") ||
    (storage.get("user").user_role !== 'employee' &&
    !storage.get("user").special_users &&
    !storage.get("user").sales_access &&
    !storage.get("user").order_access &&
    !storage.get("user").quote_access &&
    !storage.get("user").archive_access &&
    !storage.get("user").data_entry_access &&
    !archsystems_users))))
  {
    return <Redirect to={`/auth/login?redirect=${match.url}`} />;
  }

return(
  <div className="page">
    <BlockRenderer slug={match.params.slug || 'home'} setTitle />
  </div>
)};
