import gql from 'graphql-tag.macro';
export const query = gql`
  query findAccount($name: String) {
    findAccount(name: $name) {
      id
      name
    }
  }
`;

export default query;
