import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { ctaGreen } from 'base/colors';

export const ItemContainer = styled('div')`
  margin-left: 10px;

  a {
    display: block;
    margin-bottom: 10px;
    cursor: pointer;

    p {
      transition: color 0.25s;
    }

    &:hover {
      p {
        color: ${Colors.ctaGreen};
      }
    }
  }
`;

export const Square = styled('div')`
  height: 14px;
  min-width: 14px;
  max-width: 14px;
  width: 100%;
  margin-right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  ${props => (props.checked ? `background-color: ${ctaGreen};` : null)};
`;
