import React from "react";
import Filter from "./filter";
import { Container } from "./style";
import { isEmpty } from "shared/helper.js";
import _ from "lodash";

const Standard = ({ products, data, type, slug, collections, page }) => {
    
  if (!data.searchSidebar && isEmpty(products)) return <Container loading />;

  if (!isEmpty(products) && !products.length)
    return (
      <Container>
        <div>No filtering available</div>
      </Container>
    );

    const findTerm = (term_id) =>
        data && data.getTerms && data.getTerms.length
          ? data.getTerms.find(term => parseInt(term.term_id) === term_id)
          : null;
  
    let map = new Map();
    
    products && products.forEach(product => {
        product && product.terms && product.terms.archsystems_filter && product.terms.archsystems_filter.forEach(filter => { 
            if(map.has(filter["name"])) {
                map.get(filter["name"]).count++;
            }else { 
                const term = findTerm(filter.parent);
                const parent_name = term ? term.name : null; 
                map.set(filter["name"], Object.assign(filter, {count:1, taxonomy: 'archsystems_filter', parent_name: parent_name}));
            }
        });
    });

    let filters = [...map.values()].filter(filter => filter.count < products.length && filter.parent_name !== null);
    
    const productFilters = _.chain(filters)
                        .groupBy("parent_name")
                            .map((value, key) => ({ name: key, children: value })).value(); 

  return (
    <Container>
      {data.searchSidebar && data.searchSidebar.categories ? (
        <Filter
          type={type}
          section={data.searchSidebar.categories}
          slug={slug}
          collections={collections}
        />
      ) : null}
      {page !== "main"
        ? productFilters && productFilters
            .filter(section => section.name)
            .map(section => (
              <Filter
                type={type}
                key={section.name}
                section={section}
                checkbox
                slug={slug}
                collections={collections}
              />
            ))
        : null}
    </Container>
  );
};

export default Standard;
