import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Tablet } from 'shared/layout/base/base';

export const Wrapper = styled('button')`
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px;
  width: 100%;
  text-align: left;
  color: ${Colors.lightTextGrey};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (${Tablet}) {
    display: none;
  }
`;
