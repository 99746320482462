import React from "react";
import Field from "./field";
import { css } from "emotion";
import { ctaGreen } from "base/colors";

const Checkbox = css`
    position: relative;
    margin-right: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    align-items: center;

    input {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        -webkit-appearance: none;
        border: solid 1px #e0dfdf;
        cursor: pointer;

        &:after {
            content: '';
            display: none;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            top: 2px;
            left: 6px;
            width: 5px;
            height: 10px;
        }

        &:focus {
            outline: none;
        }

        &:checked {
            background-color: ${ctaGreen};
            border-color: ${ctaGreen};

            &:after {
                display: block;
            }
        }
    }

}

`;
export default props => {
  let {
    label,
    type,
    error,
    success,
    value,
    onChange,
    name,
    isDisabled,
    center,
    ...rest
  } = props;
  return (
    <Field {...props} reversed center={center}>
      <div className={Checkbox}>
        <input
          {...rest}
          name={name}
          checked={value}
          id={props.name}
          type="checkbox"
          readOnly={isDisabled}
          onChange={e =>
            onChange({ target: { name, value: !value, ...e.target } })
          }
        />
      </div>
    </Field>
  );
};
