import styled from "react-emotion";
import * as Colors from "base/colors";
import { EncodeSans, Tablet, Montserrat } from "shared/layout/base/base";

export const SocialSidebar = styled("div")`
  margin-bottom: 20px;
  display: block;
  background-color: #dd4e75;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
  color: ${Colors.white};

  h3 {
    font-family: ${Montserrat};
    font-size: 50px;
    font-weight: 200;
    margin: 0 0 10px 0;
    color: ${Colors.white};
  }

  span {
    font-family: ${EncodeSans};
    font-size: 16px;
    padding: 10px 20px;
    border-left: 1px solid ${Colors.white};
    display: block;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    list-style: none !important;
    display: flex;

    li {
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }

    a {
      display: block;

      &:hover {
        svg {
          fill: #b03d5c;
        }
      }
    }

    svg {
      width: 20px;
      height: 20px;
      fill: ${Colors.white};
      transition: fill 0.25s;
    }
  }
`;
