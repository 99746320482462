import qs from "qs";
import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";

export const query = gql`
  query SearchOrders($input: SearchOrdersInput!) {
    searchOrders(input: $input) {
      count
      results {
        id
        date
        tracking
        total
        status
        salesforceOrderNumber
        spartan_order_number
        notes
        job_name
        sales_rep

        products {
          name
          sku
          quantity
          image {
            thumbnail
          }
        }
      }
    }
  }
`;

export default graphql(query, {
  options: props => {
    let { query, page = 1, year } = qs.parse(
      window.location.search.substring(1)
    );

    return {
      variables: {
        input: { queryString: query || "", limit: 5, page: parseInt(page) }
      },
      fetchPolicy: "cache-and-network"
    };
  }
});
