import React from 'react';
import Items from './items';
import storage from 'shared/storage';
import { GridWrapper } from './style';
import updateUserRole from './gql/update-role';
import { Redirect } from 'react-router-dom';
import HeadingUnderlined from 'shared/layout/heading-underlined';

class Profession extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  async submit(selectedItem,onUserClick) {
    let { updateUserRole } = this.props;

    if(onUserClick) onUserClick(true)
    await updateUserRole({ profession: selectedItem });

    this.setState({ done: true });
  }

  render() {
    let { selectedItem, done } = this.state;
    let { headingText, onUserClick, fifty } = this.props;

    if (!storage.get('user')) return <Redirect to="/auth/signup" />;
    if (done && !onUserClick)
      return (
        <Redirect to={`/auth/signup/personal-info${window.location.search}`} />
      );

    return (
      <React.Fragment>
        <HeadingUnderlined text={ headingText ? headingText : "Welcome To ASI"} />
        <GridWrapper>
          <Items
            fifty={fifty}
            onUserClick={onUserClick}
            selectedItem={selectedItem}
            onClick={selectedItem => this.submit(selectedItem, onUserClick)}
          />
        </GridWrapper>
      </React.Fragment>
    );
  }
}

export default updateUserRole(Profession);
