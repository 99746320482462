import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Tablet, EncodeSans, Montserrat } from 'shared/layout/base/base';

export const Container = styled('div')`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e6e6e6;
  margin-bottom: 20px;
  padding: 20px;
`;

export const Title = styled('p')`
  margin: 0;
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;

  @media (${Tablet}) {
    font-size: 26px;
  }
`;

export const Wrapper = styled('div')`
  margin: 20px 0;

  span {
    font-family: ${EncodeSans};
    font-size: 13px;
    line-height: 1.77;
    display: block;
  }

  a {
    color: ${Colors.ctaGreen};
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Heading = styled('span')`
  font-weight: 500;
`;
