export const ExcludedProducts = [
//............................Structura® Eco-Panels.............................
    'DSATM325',
    'DSATM324',
    'DSATM552',
    'DSATM347',
    'DSATM393',
    'DSATM439',
    'DSATM484',
    'DSATM576',
    'DSATM528',
    'DSATM369',
    'DSATM416',
    'DSATM462',
    'DSATM599',
    'DSATM461',
    'DSATM415',
    'DSATM368',
    'DSATM551',
    'DSATM346',
    'DSATM392',
    'DSATM438',
    'DSATM483',
    'DSATM575',
    'DSATM527',
    'DSATM326',
    'DSATM553',
    'DSATM348',
    'DSATM394',
    'DSATM440',
    'DSATM306',
    'DSATM577',
    'DSATM529',
    'DSATM370',
    'DSATM417',
    'DSATM463',
    'DSATM600',
    'DSATM328',
    'DSATM555',
    'DSATM350',
    'DSATM396',
    'DSATM442',
    'DSATM486',
    'DSATM578',
    'DSATM531',
    'DSATM372',
    'DSATM419',
    'DSATM465',
    'DSATM602',
    'DSATM554',
    'DSATM327',
    'DSATM349',
    'DSATM395',
    'DSATM441',
    'DSATM485',
    'DSATM303',
    'DSATM530',
    'DSATM371',
    'DSATM418',
    'DSATM464',
    'DSATM601',
    'DSATM331',
    'DSATM558',
    'DSATM352',
    'DSATM399',
    'DSATM445',
    'DSATM489',
    'DSATM581',
    'DSATM534',
    'DSATM375',
    'DSATM422',
    'DSATM468',
    'DSATM605',
    'DSATM333',
    'DSATM560',
    'DSATM354',
    'DSATM401',
    'DSATM447',
    'DSATM491',
    'DSATM583',
    'DSATM536',
    'DSATM377',
    'DSATM316',
    'DSATM470',
    'DSATM607',
    'DSATM598',
    'DSATM335',
    'DSATM562',
    'DSATM356',
    'DSATM403',
    'DSATM449',
    'DSATM493',
    'DSATM585',
    'DSATM538',
    'DSATM379',
    'DSATM425',
    'DSATM472',
    'DSATM608',
    'DSATM334',
    'DSATM561',
    'DSATM355',
    'DSATM402',
    'DSATM448',
    'DSATM492',
    'DSATM584',
    'DSATM537',
    'DSATM378',
    'DSATM424',
    'DSATM471',
    'DSATM259',
    'DSATM332',
    'DSATM559',
    'DSATM353',
    'DSATM400',
    'DSATM446',
    'DSATM490',
    'DSATM582',
    'DSATM535',
    'DSATM376',
    'DSATM423',
    'DSATM469',
    'DSATM606',
    'DSATM343',
    'DSATM435',
    'DSATM480',
    'DSATM258',
    'DSATM344',
    'DSATM572',
    'DSATM365',
    'DSATM412',
    'DSATM291',
    'DSATM313',
    'DSATM594',
    'DSATM548',
    'DSATM389',
    'DSATM390',
    'DSATM436',
    'DSATM481',
    'DSATM549',
    'DSATM615',
    'DSATM595',
    'DSATM501',
    'DSATM459',
    'DSATM413',
    'DSATM366',
    'DSATM573',
    'DSATM338',
    'DSATM567',
    'DSATM360',
    'DSATM408',
    'DSATM454',
    'DSATM497',
    'DSATM589',
    'DSATM543',
    'DSATM384',
    'DSATM430',
    'DSATM475',
    'DSATM612',
    'DSATM341',
    'DSATM570',
    'DSATM363',
    'DSATM410',
    'DSATM457',
    'DSATM499',
    'DSATM592',
    'DSATM546',
    'DSATM387',
    'DSATM433',
    'DSATM478',
    'DSATM614',
    'DSATM342',
    'DSATM434',
    'DSATM479',
    'DSATM292',
    'DSATM571',
    'DSATM364',
    'DSATM411',
    'DSATM458',
    'DSATM500',
    'DSATM593',
    'DSATM547',
    'DSATM388',
    'DSATM340',
    'DSATM569',
    'DSATM362',
    'DSATM221',
    'DSATM456',
    'DSATM264',
    'DSATM591',
    'DSATM545',
    'DSATM386',
    'DSATM432',
    'DSATM477',
    'DSATM613',
    'DSATM329',
    'DSATM556',
    'DSATM217',
    'DSATM397',
    'DSATM443',
    'DSATM487',
    'DSATM579',
    'DSATM532',
    'DSATM373',
    'DSATM420',
    'DSATM466',
    'DSATM603',
    'DSATM330',
    'DSATM557',
    'DSATM351',
    'DSATM398',
    'DSATM604',
    'DSATM444',
    'DSATM488',
    'DSATM580',
    'DSATM533',
    'DSATM374',
    'DSATM421',
    'DSATM467',
    'DSATM337',
    'DSATM542',
    'DSATM383',
    'DSATM429',
    'DSATM314',
    'DSATM611',
    'DSATM588',
    'DSATM566',
    'DSATM359',
    'DSATM407',
    'DSATM453',
    'DSATM260',
    
//...........................Structura® Ceiling Tiles...........................    
    
    'SPATM006',
    'SPATM234',
    'SPATM488',
    'SPATM715',
    'SPATM007',
    'SPATM008',
    'SPATM009',
    'SPATM010',
    'SPATM011',
    'SPATM012',
    'SPATM013',
    'SPATM014',
    'SPATM015',
    'SPATM016',
    'SPATM017',
    'SPATM018',
    'SPATM019',
    'SPATM020',
    'SPATM021',
    'SPATM022',
    'SPATM023',
    'SPATM489',
    'SPATM490',
    'SPATM491',
    'SPATM492',
    'SPATM493',
    'SPATM494',
    'SPATM495',
    'SPATM496',
    'SPATM497',
    'SPATM498',
    'SPATM499',
    'SPATM500',
    'SPATM501',
    'SPATM502',
    'SPATM503',
    'SPATM504',
    'SPATM505',
    'SPATM235',
    'SPATM238',
    'SPATM239',
    'SPATM240',
    'SPATM241',
    'SPATM242',
    'SPATM243',
    'SPATM244',
    'SPATM245',
    'SPATM246',
    'SPATM247',
    'SPATM248',
    'SPATM249',
    'SPATM250',
    'SPATM251',
    'SPATM252',
    'SPATM253',
    'SPATM254',
    'SPATM716',
    'SPATM719',
    'SPATM720',
    'SPATM721',
    'SPATM722',
    'SPATM723',
    'SPATM724',
    'SPATM725',
    'SPATM726',
    'SPATM727',
    'SPATM728',
    'SPATM729',
    'SPATM730',
    'SPATM731',
    'SPATM732',
    'SPATM733',
    'SPATM734',
    'SPATM735',
    'SPATM717',
    'SPATM236',
    'SPATM237',
    'SPATM718',
    'SPATM025',
    'SPATM026',
    'SPATM027',
    'SPATM028',
    'SPATM029',
    'SPATM030',
    'SPATM031',
    'SPATM032',
    'SPATM033',
    'SPATM034',
    'SPATM035',
    'SPATM036',
    'SPATM037',
    'SPATM038',
    'SPATM039',
    'SPATM040',
    'SPATM041',
    'SPATM042',
    'SPATM507',
    'SPATM508',
    'SPATM509',
    'SPATM510',
    'SPATM511',
    'SPATM512',
    'SPATM513',
    'SPATM255',
    'SPATM514',
    'SPATM516',
    'SPATM517',
    'SPATM520',
    'SPATM518',
    'SPATM515',
    'SPATM519',
    'SPATM521',
    'SPATM522',
    'SPATM524',
    'SPATM523',
    'SPATM256',
    'SPATM257',
    'SPATM258',
    'SPATM259',
    'SPATM260',
    'SPATM261',
    'SPATM262',
    'SPATM263',
    'SPATM264',
    'SPATM265',
    'SPATM266',
    'SPATM267',
    'SPATM268',
    'SPATM269',
    'SPATM270',
    'SPATM272',
    'SPATM271',
    'SPATM273',
    'SPATM275',
    'SPATM274',
    'SPATM736',
    'SPATM737',
    'SPATM738',
    'SPATM739',
    'SPATM740',
    'SPATM741',
    'SPATM742',
    'SPATM743',
    'SPATM744',
    'SPATM745',
    'SPATM746',
    'SPATM748',
    'SPATM747',
    'SPATM749',
    'SPATM750',
    'SPATM751',
    'SPATM753',
    'SPATM752',
    'SPATM754',
    'SPATM756',
    'SPATM755',
    'SPATM044',
    'SPATM045',
    'SPATM046',
    'SPATM047',
    'SPATM048',
    'SPATM049',
    'SPATM050',
    'SPATM051',
    'SPATM052',
    'SPATM053',
    'SPATM054',
    'SPATM055',
    'SPATM056',
    'SPATM057',
    'SPATM059',
    'SPATM058',
    'SPATM060',
    'SPATM061',
    'SPATM526',
    'SPATM527',
    'SPATM528',
    'SPATM532',
    'SPATM529',
    'SPATM533',
    'SPATM530',
    'SPATM534',
    'SPATM531',
    'SPATM535',
    'SPATM289',
    'SPATM290',
    'SPATM291',
    'SPATM292',
    'SPATM295',
    'SPATM293',
    'SPATM296',
    'SPATM294',
    'SPATM536',
    'SPATM537',
    'SPATM538',
    'SPATM539',
    'SPATM540',
    'SPATM542',
    'SPATM541',
    'SPATM543',
    'SPATM276',
    'SPATM278',
    'SPATM277',
    'SPATM279',
    'SPATM280',
    'SPATM281',
    'SPATM282',
    'SPATM285',
    'SPATM283',
    'SPATM286',
    'SPATM284',
    'SPATM287',
    'SPATM288',
    'SPATM757',
    'SPATM758',
    'SPATM759',
    'SPATM760',
    'SPATM761',
    'SPATM762',
    'SPATM764',
    'SPATM765',
    'SPATM766',
    'SPATM768',
    'SPATM767',
    'SPATM769',
    'SPATM763',
    'SPATM770',
    'SPATM771',
    'SPATM772',
    'SPATM773',
    'SPATM774',
    'SPATM775',
    'SPATM776',
    'SPATM777',
    'SPATM063',
    'SPATM064',
    'SPATM066',
    'SPATM067',
    'SPATM069',
    'SPATM065',
    'SPATM068',
    'SPATM070',
    'SPATM071',
    'SPATM072',
    'SPATM073',
    'SPATM077',
    'SPATM074',
    'SPATM075',
    'SPATM076',
    'SPATM080',
    'SPATM078',
    'SPATM079',
    'SPATM546',
    'SPATM547',
    'SPATM549',
    'SPATM548',
    'SPATM551',
    'SPATM545',
    'SPATM553',
    'SPATM550',
    'SPATM552',
    'SPATM554',
    'SPATM555',
    'SPATM556',
    'SPATM557',
    'SPATM558',
    'SPATM560',
    'SPATM561',
    'SPATM559',
    'SPATM562',
    'SPATM297',
    'SPATM298',
    'SPATM299',
    'SPATM300',
    'SPATM301',
    'SPATM302',
    'SPATM303',
    'SPATM304',
    'SPATM305',
    'SPATM306',
    'SPATM307',
    'SPATM309',
    'SPATM308',
    'SPATM310',
    'SPATM313',
    'SPATM312',
    'SPATM315',
    'SPATM311',
    'SPATM314',
    'SPATM317',
    'SPATM316',
    'SPATM778',
    'SPATM779',
    'SPATM783',
    'SPATM782',
    'SPATM780',
    'SPATM781',
    'SPATM784',
    'SPATM785',
    'SPATM786',
    'SPATM788',
    'SPATM789',
    'SPATM787',
    'SPATM790',
    'SPATM791',
    'SPATM792',
    'SPATM793',
    'SPATM796',
    'SPATM794',
    'SPATM798',
    'SPATM795',
    'SPATM797',
    'SPATM083',
    'SPATM082',
    'SPATM084',
    'SPATM085',
    'SPATM086',
    'SPATM088',
    'SPATM090',
    'SPATM087',
    'SPATM093',
    'SPATM089',
    'SPATM091',
    'SPATM094',
    'SPATM092',
    'SPATM095',
    'SPATM096',
    'SPATM097',
    'SPATM099',
    'SPATM098',
    'SPATM318',
    'SPATM322',
    'SPATM323',
    'SPATM319',
    'SPATM324',
    'SPATM325',
    'SPATM326',
    'SPATM327',
    'SPATM328',
    'SPATM329',
    'SPATM330',
    'SPATM331',
    'SPATM335',
    'SPATM334',
    'SPATM332',
    'SPATM333',
    'SPATM336',
    'SPATM338',
    'SPATM337',
    'SPATM320',
    'SPATM321',
    'SPATM566',
    'SPATM568',
    'SPATM564',
    'SPATM570',
    'SPATM571',
    'SPATM565',
    'SPATM567',
    'SPATM569',
    'SPATM572',
    'SPATM573',
    'SPATM574',
    'SPATM575',
    'SPATM577',
    'SPATM576',
    'SPATM578',
    'SPATM579',
    'SPATM580',
    'SPATM581',
    'SPATM799',
    'SPATM800',
    'SPATM802',
    'SPATM801',
    'SPATM803',
    'SPATM806',
    'SPATM804',
    'SPATM809',
    'SPATM807',
    'SPATM805',
    'SPATM810',
    'SPATM808',
    'SPATM812',
    'SPATM811',
    'SPATM813',
    'SPATM814',
    'SPATM816',
    'SPATM815',
    'SPATM818',
    'SPATM819',
    'SPATM817',
    'SPATM101',
    'SPATM104',
    'SPATM102',
    'SPATM105',
    'SPATM103',
    'SPATM106',
    'SPATM109',
    'SPATM108',
    'SPATM107',
    'SPATM110',
    'SPATM111',
    'SPATM112',
    'SPATM113',
    'SPATM115',
    'SPATM114',
    'SPATM116',
    'SPATM117',
    'SPATM118',
    'SPATM600',
    'SPATM583',
    'SPATM584',
    'SPATM585',
    'SPATM589',
    'SPATM586',
    'SPATM587',
    'SPATM588',
    'SPATM590',
    'SPATM591',
    'SPATM592',
    'SPATM595',
    'SPATM593',
    'SPATM596',
    'SPATM599',
    'SPATM597',
    'SPATM594',
    'SPATM598',
    'SPATM339',
    'SPATM340',
    'SPATM344',
    'SPATM343',
    'SPATM345',
    'SPATM341',
    'SPATM342',
    'SPATM346',
    'SPATM347',
    'SPATM348',
    'SPATM349',
    'SPATM351',
    'SPATM353',
    'SPATM350',
    'SPATM354',
    'SPATM352',
    'SPATM355',
    'SPATM356',
    'SPATM357',
    'SPATM358',
    'SPATM359',
    'SPATM822',
    'SPATM823',
    'SPATM820',
    'SPATM821',
    'SPATM824',
    'SPATM825',
    'SPATM827',
    'SPATM826',
    'SPATM828',
    'SPATM829',
    'SPATM830',
    'SPATM831',
    'SPATM833',
    'SPATM834',
    'SPATM835',
    'SPATM838',
    'SPATM836',
    'SPATM832',
    'SPATM837',
    'SPATM839',
    'SPATM840',
    'SPATM120',
    'SPATM122',
    'SPATM121',
    'SPATM123',
    'SPATM124',
    'SPATM125',
    'SPATM127',
    'SPATM126',
    'SPATM128',
    'SPATM130',
    'SPATM131',
    'SPATM132',
    'SPATM133',
    'SPATM129',
    'SPATM135',
    'SPATM136',
    'SPATM134',
    'SPATM137',
    'SPATM602',
    'SPATM603',
    'SPATM604',
    'SPATM605',
    'SPATM606',
    'SPATM608',
    'SPATM610',
    'SPATM609',
    'SPATM607',
    'SPATM611',
    'SPATM612',
    'SPATM615',
    'SPATM613',
    'SPATM614',
    'SPATM616',
    'SPATM617',
    'SPATM618',
    'SPATM619',
    'SPATM360',
    'SPATM361',
    'SPATM362',
    'SPATM363',
    'SPATM364',
    'SPATM366',
    'SPATM365',
    'SPATM370',
    'SPATM367',
    'SPATM372',
    'SPATM368',
    'SPATM369',
    'SPATM371',
    'SPATM373',
    'SPATM375',
    'SPATM374',
    'SPATM376',
    'SPATM378',
    'SPATM379',
    'SPATM380',
    'SPATM377',
    'SPATM841',
    'SPATM842',
    'SPATM843',
    'SPATM844',
    'SPATM845',
    'SPATM846',
    'SPATM847',
    'SPATM848',
    'SPATM849',
    'SPATM851',
    'SPATM850',
    'SPATM852',
    'SPATM853',
    'SPATM854',
    'SPATM855',
    'SPATM856',
    'SPATM859',
    'SPATM860',
    'SPATM857',
    'SPATM858',
    'SPATM861',
    'SPATM139',
    'SPATM140',
    'SPATM142',
    'SPATM141',
    'SPATM144',
    'SPATM145',
    'SPATM143',
    'SPATM146',
    'SPATM166',
    'SPATM147',
    'SPATM148',
    'SPATM149',
    'SPATM150',
    'SPATM151',
    'SPATM153',
    'SPATM152',
    'SPATM156',
    'SPATM154',
    'SPATM155',
    'SPATM621',
    'SPATM622',
    'SPATM623',
    'SPATM624',
    'SPATM625',
    'SPATM626',
    'SPATM627',
    'SPATM628',
    'SPATM631',
    'SPATM630',
    'SPATM629',
    'SPATM632',
    'SPATM633',
    'SPATM635',
    'SPATM634',
    'SPATM637',
    'SPATM638',
    'SPATM636',
    'SPATM381',
    'SPATM382',
    'SPATM383',
    'SPATM384',
    'SPATM385',
    'SPATM386',
    'SPATM387',
    'SPATM390',
    'SPATM393',
    'SPATM391',
    'SPATM394',
    'SPATM388',
    'SPATM395',
    'SPATM389',
    'SPATM392',
    'SPATM396',
    'SPATM397',
    'SPATM398',
    'SPATM399',
    'SPATM400',
    'SPATM401',
    'SPATM863',
    'SPATM862',
    'SPATM864',
    'SPATM865',
    'SPATM866',
    'SPATM869',
    'SPATM867',
    'SPATM868',
    'SPATM872',
    'SPATM871',
    'SPATM870',
    'SPATM874',
    'SPATM873',
    'SPATM875',
    'SPATM876',
    'SPATM877',
    'SPATM878',
    'SPATM880',
    'SPATM881',
    'SPATM879',
    'SPATM882',
    'SPATM158',
    'SPATM159',
    'SPATM162',
    'SPATM163',
    'SPATM165',
    'SPATM164',
    'SPATM160',
    'SPATM161',
    'SPATM167',
    'SPATM170',
    'SPATM168',
    'SPATM173',
    'SPATM174',
    'SPATM171',
    'SPATM169',
    'SPATM175',
    'SPATM172',
    'SPATM640',
    'SPATM641',
    'SPATM643',
    'SPATM644',
    'SPATM642',
    'SPATM645',
    'SPATM647',
    'SPATM646',
    'SPATM652',
    'SPATM648',
    'SPATM649',
    'SPATM650',
    'SPATM651',
    'SPATM653',
    'SPATM654',
    'SPATM656',
    'SPATM655',
    'SPATM657',
    'SPATM402',
    'SPATM406',
    'SPATM408',
    'SPATM409',
    'SPATM403',
    'SPATM411',
    'SPATM412',
    'SPATM407',
    'SPATM410',
    'SPATM413',
    'SPATM414',
    'SPATM415',
    'SPATM417',
    'SPATM416',
    'SPATM418',
    'SPATM419',
    'SPATM422',
    'SPATM420',
    'SPATM421',
    'SPATM404',
    'SPATM405',
    'SPATM885',
    'SPATM886',
    'SPATM883',
    'SPATM884',
    'SPATM888',
    'SPATM887',
    'SPATM890',
    'SPATM889',
    'SPATM892',
    'SPATM891',
    'SPATM895',
    'SPATM896',
    'SPATM897',
    'SPATM893',
    'SPATM894',
    'SPATM901',
    'SPATM902',
    'SPATM898',
    'SPATM903',
    'SPATM899',
    'SPATM900',
    'SPATM177',
    'SPATM178',
    'SPATM179',
    'SPATM183',
    'SPATM180',
    'SPATM182',
    'SPATM181',
    'SPATM184',
    'SPATM185',
    'SPATM187',
    'SPATM191',
    'SPATM186',
    'SPATM189',
    'SPATM190',
    'SPATM188',
    'SPATM192',
    'SPATM194',
    'SPATM193',
    'SPATM659',
    'SPATM660',
    'SPATM662',
    'SPATM664',
    'SPATM661',
    'SPATM663',
    'SPATM665',
    'SPATM666',
    'SPATM667',
    'SPATM668',
    'SPATM671',
    'SPATM673',
    'SPATM669',
    'SPATM674',
    'SPATM670',
    'SPATM675',
    'SPATM676',
    'SPATM672',
    'SPATM423',
    'SPATM427',
    'SPATM424',
    'SPATM429',
    'SPATM428',
    'SPATM430',
    'SPATM431',
    'SPATM434',
    'SPATM435',
    'SPATM432',
    'SPATM437',
    'SPATM439',
    'SPATM433',
    'SPATM438',
    'SPATM441',
    'SPATM442',
    'SPATM436',
    'SPATM443',
    'SPATM440',
    'SPATM425',
    'SPATM426',
    'SPATM906',
    'SPATM907',
    'SPATM904',
    'SPATM905',
    'SPATM909',
    'SPATM908',
    'SPATM910',
    'SPATM911',
    'SPATM912',
    'SPATM915',
    'SPATM916',
    'SPATM918',
    'SPATM913',
    'SPATM919',
    'SPATM921',
    'SPATM920',
    'SPATM914',
    'SPATM924',
    'SPATM917',
    'SPATM922',
    'SPATM923',
    'SPATM196',
    'SPATM197',
    'SPATM200',
    'SPATM198',
    'SPATM201',
    'SPATM202',
    'SPATM199',
    'SPATM203',
    'SPATM204',
    'SPATM205',
    'SPATM206',
    'SPATM209',
    'SPATM211',
    'SPATM207',
    'SPATM213',
    'SPATM212',
    'SPATM208',
    'SPATM210',
    'SPATM678',
    'SPATM680',
    'SPATM679',
    'SPATM681',
    'SPATM682',
    'SPATM683',
    'SPATM684',
    'SPATM688',
    'SPATM686',
    'SPATM685',
    'SPATM689',
    'SPATM691',
    'SPATM687',
    'SPATM694',
    'SPATM695',
    'SPATM690',
    'SPATM692',
    'SPATM693',
    'SPATM444',
    'SPATM445',
    'SPATM446',
    'SPATM447',
    'SPATM448',
    'SPATM449',
    'SPATM450',
    'SPATM454',
    'SPATM451',
    'SPATM455',
    'SPATM453',
    'SPATM452',
    'SPATM224',
    'SPATM456',
    'SPATM457',
    'SPATM460',
    'SPATM458',
    'SPATM459',
    'SPATM464',
    'SPATM462',
    'SPATM461',
    'SPATM463',
    'SPATM925',
    'SPATM926',
    'SPATM928',
    'SPATM927',
    'SPATM931',
    'SPATM929',
    'SPATM934',
    'SPATM930',
    'SPATM936',
    'SPATM932',
    'SPATM933',
    'SPATM935',
    'SPATM937',
    'SPATM939',
    'SPATM940',
    'SPATM941',
    'SPATM942',
    'SPATM943',
    'SPATM944',
    'SPATM938',
    'SPATM945',
    'SPATM215',
    'SPATM216',
    'SPATM217',
    'SPATM220',
    'SPATM221',
    'SPATM218',
    'SPATM223',
    'SPATM222',
    'SPATM219',
    'SPATM227',
    'SPATM225',
    'SPATM230',
    'SPATM226',
    'SPATM228',
    'SPATM229',
    'SPATM231',
    'SPATM232',
    'SPATM697',
    'SPATM698',
    'SPATM699',
    'SPATM700',
    'SPATM701',
    'SPATM704',
    'SPATM702',
    'SPATM703',
    'SPATM705',
    'SPATM706',
    'SPATM707',
    'SPATM708',
    'SPATM710',
    'SPATM709',
    'SPATM712',
    'SPATM711',
    'SPATM713',
    'SPATM714',
    'SPATM465',
    'SPATM466',
    'SPATM467',
    'SPATM468',
    'SPATM469',
    'SPATM472',
    'SPATM473',
    'SPATM470',
    'SPATM474',
    'SPATM471',
    'SPATM475',
    'SPATM478',
    'SPATM477',
    'SPATM479',
    'SPATM480',
    'SPATM482',
    'SPATM476',
    'SPATM484',
    'SPATM481',
    'SPATM483',
    'SPATM485',
    'SPATM948',
    'SPATM949',
    'SPATM950',
    'SPATM951',
    'SPATM952',
    'SPATM953',
    'SPATM946',
    'SPATM954',
    'SPATM947',
    'SPATM955',
    'SPATM956',
    'SPATM957',
    'SPATM958',
    'SPATM959',
    'SPATM960',
    'SPATM961',
    'SPATM963',
    'SPATM962',
    'SPATM964',
    'SPATM965',
    'SPATM966',
    'SPATP001',
    'SPATP002',
    'SPATP003',
    'SPATP004',
    'SPATP005',
    'SPATP006',
    'SPATP007',
    'SPATP008',
    'SPATP009',
    'SPATP010',
    'SPATP011',
    'SPATP012',
    'SPATP013',
    'SPATP014',
    'SPATP015',
    'SPATP016',
    'SPATP017',
    'SPATP019',
    'SPATP018',
    'SPATP020',
    'SPATP021',
    'SPATP022',
    'SPATP024',
    'SPATP023',
    'SPATP025',
    'SPATP027',
    'SPATP026',
    'SPATP028',
    'SPATP029',
    'SPATP030',
    'SPATP031',
    'SPATP032',
    'SPATP033',
    'SPATP035',
    'SPATP034',
    'SPATP036',
    'SPATP037',
    'SPATP038',
    'SPATP039',
    'SPATP040',
    'SPATP041',
    'SPATP042',
    'SPATP043',
    'SPATP044',
    'SPATP045',
    'SPATP046',
    'SPATP047',
    'SPATP048'
];

