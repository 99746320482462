import { graphql } from "react-apollo";
import { query as boardItemsQuery } from "views/board/gql/queries/get";
import mutator from 'shared/gql/mutate';

import gql from "graphql-tag.macro";
export const query = gql`
  mutation AddItem($input: AddBoardItemInput!) {
    addBoardItem(input: $input) {
      id
      name
      description
      user_id
      template
      textarea
      collaborators {
        id
        name
        phone
        email
        board_id
      }
      images {
        id
        url
      }
      colors {
        id
        hex_code
      }
      items {
        item_id
        id
        name
        slug
        image {
          thumbnail
        }
      }
    }
  }
`;
export default graphql(query, {
  props: ({ mutate }) => ({
    addItems: async input => {
      return mutator(() =>
        mutate({
          variables: { input },
          refetchQueries: [
            {
              query: boardItemsQuery,
              variables: { input: { id: input.board_id } }
            }
          ]
        }),
      );
    }
  })
});