import qs from 'qs';
import React from 'react';
import {
  CartIcon,
  CartWrapper,
  CartButton,
  CartItems,
  ShoppingCartWrapper,
  ShoppingCartBackButton,
} from './style';
import Items from './items';
import Arrow from 'icons/arrow';
import isAtLeast from 'shared/size';
import storage from 'shared/storage';
import cartQuery from 'shared/cart/gql/cart';
import { cartId } from "shared/helper";

class Cart extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { data } = this.props;
    let { show } = this.state;
    if (!data.cart)
      return (
        <CartWrapper>
          <CartButton>
            <CartIcon />
            <CartItems>0</CartItems>
          </CartButton>
        </CartWrapper>
      );
    let { products, samples } = data.cart;

    //dont set cart id in localstorage if its in the url
    let query = qs.parse(window.location.search.substring(1));
    if (!query.cart_id && !cartId() && storage.get('user'))
      storage.set('cartId-'+storage.get('user').id, data.cart.id);

    return (
      <CartWrapper
        onMouseEnter={isAtLeast('tablet', () => this.setState({ show: true }))}
        onMouseLeave={isAtLeast('tablet', () => this.setState({ show: false }))}
        onClick={() => this.setState({ show: !show })}
      >
        <CartButton>
          <CartIcon />
          <CartItems>{products.length + samples.length}</CartItems>
        </CartButton>
        <ShoppingCartWrapper show={show && (products.length || samples.length)}>
          <ShoppingCartBackButton
            onClick={() => this.setState({ show: !show })}
          >
            <Arrow />
            BACK
          </ShoppingCartBackButton>
          <Items products={products} samples={samples} />
        </ShoppingCartWrapper>
      </CartWrapper>
    );
  }
}

export default cartQuery(Cart);
