import React from 'react';
import Address from 'shared/user/address';
import Input from 'shared/layout/form/input';
import { Section, FieldWrapper } from 'views/checkout/shared/style';
import Line from 'shared/layout/line';

export default ({ errors, values, update, noLine, isDisabled }) => (
  <React.Fragment>
    <Address
      type="shipping"
      label="Shipping"
      error={errors.shipping_id}
      onSelect={id => update({ shipping_id: id }, true)}
      value={values.shipping_id}
      isDisabled={isDisabled}
    />
    {noLine ? null : (
      <Line style={{ marginTop: '20px', marginBottom: '20px' }} />
    )}
  </React.Fragment>
);
