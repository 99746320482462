import React from 'react';
import ContactSidebar from 'shared/blocks/contact/contact-us';
import SocialSidebar from 'shared/blocks/about/social-sidebar';
import SidebarContainer from 'views/checkout/shared/sidebar-container';

export default () => (
  <SidebarContainer>
    <ContactSidebar />
    <SocialSidebar
      youtube="https://www.youtube.com/user/Archsystems"
      pinterest="https://www.pinterest.com/archsystems/"
      instagram="https://www.instagram.com/archsystems/"
      facebook="https://www.facebook.com/architecturalsystems/"
    />
  </SidebarContainer>
);
