// when this page is built move this to the page, since it is not shared
import React from 'react';
import styled from 'react-emotion';
import Highlighter from 'react-highlight-words';
import { black, white } from 'base/colors';

export const Content = styled('div')`
  margin-top: 18px;
  font-family: Encode Sans;
  color:  ${ black };
  mark {
    font-weight: bold;
    color:  ${ black };
    background-color: ${ white };
  }
`;

export const Container = styled('div')`
  display: flex;
  cursor: pointer;
`;

export const Image = styled('img')`
  height: 50px;
  width: 50px;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export default ({ item, term, displayName }) => {
  if (item.name || !item.meta) return null;

  return (
    <Container>
      {item.meta.featured_images && item.meta.featured_images[0] ? (
        <Image src={item.meta.featured_images[0].value} />
      ) : null}
      <Content>
        <Highlighter
          searchWords={term.split('')}
          autoEscape={true}
          textToHighlight={item.post_title}
        />
        <br />
        { displayName ? (<Highlighter
          searchWords={term.split('')}
          autoEscape={true}
          textToHighlight={item.meta.woo_product_display_name && item.meta.woo_product_display_name.length
                ? item.meta.woo_product_display_name[0].value
                : ""}
        />) : '' }
      </Content>
    </Container>
  );
};
