import React from 'react';
const Projectstudio = props => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(-701.000000, -348.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(438.500000, 80.000000)">
            <g transform="translate(31.500000, 0.000000)">
              <g>
                <path d="M1.97917826,4.16666667 L0,4.16666667 L0,18.0208217 C0,19.1142937 0.885659891,20 1.97917826,20 L15.8333333,20 L15.8333333,18.0208217 L1.97917826,18.0208217 L1.97917826,4.16666667 Z" />
                <path d="M18.0208217,0 L6.14584493,0 C5.05237294,0 4.16666667,0.885659891 4.16666667,1.97917826 L4.16666667,13.8541551 C4.16666667,14.9476271 5.05232656,15.8333333 6.14584493,15.8333333 L18.0208217,15.8333333 C19.1142937,15.8333333 20,14.9476734 20,13.8541551 L20,1.97917826 C20,0.885659891 19.1142937,0 18.0208217,0 Z M6.14584493,13.8541551 L9.11458913,9.89584493 L11.1234548,12.5775951 L14.0625116,8.90623261 L18.0208217,13.8541551 L6.14584493,13.8541551 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Projectstudio;
