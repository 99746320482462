import React from 'react';
import Select from 'react-select';
import places from './google-places';
import Field from 'shared/layout/form/field';

let timer;
export default class SelectWrapper extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { data, loading } = this.state;
    let { onChange, value, name, selectedAddress, error, placeid } = this.props;

    return (
      <Field label="Address*" name={name} error={error}>
        <Select
          value={value || 1}
          style={{ width: '100%' }}
          name={name}
          placeholder="Search for address"
          options={
            selectedAddress && !data
              ? [{ value: placeid, label: selectedAddress }]
              : data
          }
          onKeyDown
          filterOption={data => true}
          onInputChange={async value => {
            if (!value) return null;

            clearTimeout(timer);

            timer = setTimeout(
              async () => this.setState({ data: await places(value) }),
              350,
            );
          }}
          onChange={async value =>
            onChange({ target: { name, value: value ? value.value : null } })
          }
          noResultsText={
            loading ? 'Loading results...' : 'Start typing your address...'
          }
        />
      </Field>
    );
  }
}
