import React from "react";
import Save from "./save";
import QuickView from "./quick-view";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { ImageWrapper, Wrapper, ProjectImageWrapper } from "./style";

const Image = ({ product, project, column, hover, noSave, loading }) => {
  return (
    <ImageWrapper column={column} hover={hover} loading={loading}>
      {loading ? (
        <ContentLoader
          height="254"
          width="254"
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      ) : product ? (
        <Link to={product.sku ? `/product/${product.sku.toLowerCase()}` : null}>
          <img
            src={
              product.meta
                ? product.meta.featured_images
                  ? product.meta.featured_images[0].value.replace('dte1ze7ytxnas.cloudfront.net', 'daxcoxi2v09bn.cloudfront.net')
                  : null
                : product.featuredImage
                ? product.featuredImage.full
                : null
            }
            alt={project ? project.name : product.title}
          />
        </Link>
      ) : project ? (
        <Link to={`/project/${project.slug}`}>
          <ProjectImageWrapper
            style={{
              backgroundImage: `url(${
                project.meta
                  ? project.meta.featured_images
                    ? project.meta.featured_images[0].value
                    : null
                  : project.featuredImage
                  ? project.featuredImage.full
                  : null
              })`
            }}
          />
        </Link>
      ) : null}
      <Wrapper column={column}>
        {noSave ? null : product ? <Save product={product} /> : null}
        {product ? <QuickView data={product} /> : null}
      </Wrapper>
    </ImageWrapper>
  );
};

export default Image;
