import { cartId } from "shared/helper";
import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
import cart from "shared/gql/fragments/cart";

export const product = gql`
  mutation AddProduct($input: AddCartProductInput!) {
    addProduct(input: $input) {
      ...cart
    }
  }
  ${cart}
`;

export const sample = gql`
  mutation AddSample($input: AddSamplesInput!) {
    addSample(input: $input) {
      ...cart
    }
  }
  ${cart}
`;
const types = {
  product,
  sample
};

export default type =>
  graphql(types[type], {
    props: ({ mutate }) => ({
      [type]: async input => {
        input.samples.map(
          item => (item.product_id = parseInt(item.product_id, 10))
        );

        try {
          await mutate({
            variables: { input: { ...input, id: cartId() } }
          });
        } catch (e) {
          return null;
        }
      },
      add: async input => {
        try {
          await mutate({
            variables: { input: { ...input, id: cartId() } }
          });
        } catch (e) {
          return null;
        }
      }
    })
  });
