import styled from "react-emotion";

export default styled("div")`
  @media (min-width: 768px) {
    padding-top: 123px;
  }
  min-height: 500px;

  @media (max-width: 767px) {
    padding-top: 60px;
  }

  @print {
    padding-top: 0;
  }
`;
