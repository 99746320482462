import React from "react";
import {
  TableRow,
  ItemInfo,
  TableHeadings,
  Item,
  ItemWrapper,
  Quantity,
  RemoveQTY
} from "./style";
import Input from "shared/layout/form/input";
import ProductDetails from "views/checkout/shared/product-details";

export default class ProductItem extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { warning } = this.state;
    let {
      item,
      qty,
      onChange,
      onDelete,
      isDisabled,
      isValid,
      noMinimum
    } = this.props;

    return (
      <TableRow>
        <ItemInfo>
          <TableHeadings>
            <h4>Item</h4>
            <h4>Quantity</h4>
          </TableHeadings>
          <Item>
            <ItemWrapper>
              <ProductDetails product={item} qty={qty} />
            </ItemWrapper>

            {noMinimum ? null : warning ? (
              <p style={{ color: "red", width: "150px", marginRight: "10px" }}>
                You must order the minimum quantity or more.
              </p>
            ) : null}
            <Quantity>
              {isDisabled ? (
                <Input
                  readOnly
                  value={qty}
                  type="number"
                  min={item.minimumQty}
                  onChange={e =>
                    !item.minimumQty ||
                    parseFloat(e.target.value) >= item.minimumQty
                      ? onChange(e.target.value)
                      : null
                  }
                />
              ) : (
                <React.Fragment>
                  <Input
                    value={qty}
                    type="number"
                    min={item.minimumQty}
                    onChange={e => {
                      onChange(e.target.value);

                      if (parseFloat(e.target.value) < (item.minimumQty || 1)) {
                        this.setState({ warning: true });
                        isValid(false);
                      } else {
                        this.setState({ warning: false });
                        isValid(true);
                      }
                    }}
                  />
                  <RemoveQTY onClick={onDelete}>Remove</RemoveQTY>
                </React.Fragment>
              )}
            </Quantity>
          </Item>
        </ItemInfo>
      </TableRow>
    );
  }
}
