import React from 'react';
import {
  MegaNav,
  MegaNavButton,
  SecondaryNav,
  SecondaryNavList,
} from './style';
import Caret from 'icons/caret';
import Item from './item';
import { Link } from 'react-router-dom';

export default class Materials extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { open } = this.state;
    let { onClick, show, data, toggleShow } = this.props;

    return (
      <MegaNav>
        <li>
          <MegaNavButton onClick={onClick}>
            BROWSE ASI MATERIALS
            <Caret />
          </MegaNavButton>
          <SecondaryNav show={show}>
            <Link
              to="/products"
              onClick={() => {
                onClick(true);
                toggleShow(true);
              }}
            >
              <span>VIEW ALL MATERIALS</span>
            </Link>
            <SecondaryNavList>
              {data.menus.browseMaterials.map(material => (
                <Item
                  open={open === material.name}
                  title={material.name}
                  items={material}
                  onClick={() =>
                    open === material.name
                      ? this.setState({ open: false })
                      : this.setState({ open: material.name })
                  }
                  key={material.name}
                  toggleShow={toggleShow}
                />
              ))}
            </SecondaryNavList>
          </SecondaryNav>
        </li>
      </MegaNav>
    );
  }
}
