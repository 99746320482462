import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
import mutator from "shared/gql/mutate";

export const query = gql`
  mutation CreateSalesForceAccount($input: CreateSalesForceAccountInput!) {
    createSalesForceAccount(input: $input) {
      id
      user_login
      user_email
      user_nicename
      user_role
      user_profession
      primary_contact__c
    }
  }
`;
export default graphql(query, {
  props: ({ mutate }) => ({
    createSalesforce: async input => {
      return mutator(() =>
        mutate({
          variables: { input }
        })
      );
    }
  })
});
