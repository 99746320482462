import { graphql } from 'react-apollo';
import { query } from 'shared/blocks/about/recent-materials/gql/products';

export default graphql(query, {
  options: props => {
    let id = props.items.map(item => item.id);

    return { variables: { input: { id } } };
  },
});
