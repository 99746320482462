import React from 'react';

const Instagram = props => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(-703.000000, -439.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(439.000000, 171.000000)">
            <path d="M35.5512245,0 L50.3253061,0 C51.7283673,0 52.8763265,1.03877551 52.8763265,2.55102041 L52.8763265,17.3255102 C52.8763265,18.8377551 51.7283673,19.8765306 50.3253061,19.8765306 L35.5512245,19.8765306 C34.1477551,19.8765306 33,18.8377551 33,17.3255102 L33,2.55102041 C33,1.03877551 34.1477551,0 35.5512245,0 Z M47.4791837,2.20836735 C46.9869388,2.20836735 46.5846939,2.61081633 46.5846939,3.10326531 L46.5846939,5.24510204 C46.5846939,5.73734694 46.9869388,6.14 47.4791837,6.14 L49.7257143,6.14 C50.2179592,6.14 50.6206122,5.73734694 50.6206122,5.24510204 L50.6206122,3.10326531 C50.6206122,2.61081633 50.2179592,2.20836735 49.7257143,2.20836735 L47.4791837,2.20836735 Z M50.63,8.40571429 L48.8806122,8.40571429 C49.0461224,8.94612245 49.1357143,9.51857143 49.1357143,10.1108163 C49.1357143,13.4169388 46.3691837,16.0969388 42.9571429,16.0969388 C39.5453061,16.0969388 36.7791837,13.4169388 36.7791837,10.1108163 C36.7791837,9.51816327 36.8685714,8.94591837 37.0342857,8.40571429 L35.2087755,8.40571429 L35.2087755,16.8018367 C35.2087755,17.2363265 35.5642857,17.5920408 35.9989796,17.5920408 L49.8402041,17.5920408 C50.274898,17.5920408 50.6304082,17.2365306 50.6304082,16.8018367 L50.6304082,8.40571429 L50.63,8.40571429 Z M42.9569388,6.02714286 C40.752449,6.02714286 38.965102,7.75877551 38.965102,9.89510204 C38.965102,12.0314286 40.752449,13.7630612 42.9569388,13.7630612 C45.1616327,13.7630612 46.9491837,12.0314286 46.9491837,9.89510204 C46.9491837,7.75877551 45.1618367,6.02714286 42.9569388,6.02714286 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Instagram;
