import React from 'react';
import color from './color';
import dropdownTitle from './dropdown-title';
import dropdownImage from './dropdown-image';

let types = {
  'color-swatch': color,
  'dropdown-title': dropdownTitle,
  'dropdown-title-image': dropdownImage,
};

export default ({ variations, currentSku, noPadding }) => {
  if (!variations.length) return null;

  return variations.map((variation, index) =>
    React.createElement(types[variation.display], {
      key: index,
      currentSku,
      title: variation.name,
      data: variation.products,
      placeholder: variation.products.length > 1 ? variation.placeholder : null,
      noPadding,
    }),
  );
};
