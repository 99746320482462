import React, { useState } from 'react';
import { BaseForm } from 'react-validify';
import Dropdown from 'shared/layout/form/dropdown';
import { RichTextWrapper, DropdownWrapper, MainContainer, DropdownContainer, ButtonContainer, Clear, Label, ProjectText } from './style';
import ProjectModal from 'views/account/boards/projects/modal';
import { Button } from 'shared/layout/base/base';
import Projects from '../queries/projects';
import { isEmpty, removeHtmlTags } from 'shared/helper';
import PersonalInfo from 'views/auth/signup/steps/personal-info';
import Profession from 'views/auth/signup/steps/profession';
import UserModal from "shared/modals/desktop";
import storage from 'shared/storage';
import Modal from 'views/account/boards/create/modal';

class Fields extends React.Component {
  constructor(props) {
    super();
    this.state = { values: {}, boardList: props.boards, personalInfo: false, showModal: false, show: false };
  }
  
    getProjectData(data)
    {
        const id = data.data.createProject.vision_board_project_id;
        this.props.setProjectData(id);
        this.setState({ boardList: [{label: "Create new vision board", value: 'add'}], vision_board_project_id: id });
    }

    getVisionBoardData(data)
    {
        this.props.setVisionBoardData(data);
        const description = removeHtmlTags(data.description);
        if(!isEmpty(description)) {
            this.setState({ showDescription: true });
        }else {
            this.setState({ showDescription: false });
        }  
                
        const vision_board_project_id = this.state.vision_board_project_id;
        let items = this.props.boards.filter(function(board) {
                        if(board.vision_board_project_id === vision_board_project_id) {
                            return board;
                        }
                    });
        items.unshift({label: "Create new vision board", value:'add'});
        this.setState({ boardList: items });
    }
    
  render() {
    let { close,
            values,
            errors,
            onValues,
            onErrors,
            render,
            buttons,
            boards,
            data } = this.props;
    let { boardList, showProject, showDescription, personalInfo, showModal, show, vision_board_project_id } = this.state;
    
    let user = storage.get('user');
    let project_id_validation =  (process.env.REACT_APP_SHOW_VISION_BOARD === "true") ? 'required':'';
    
    if (!data.vbProjects) return null;
    
    let projects = data.vbProjects.map(project => ({
        label: project.name,
        value: project.vision_board_project_id
      })); 
return(
  <BaseForm
    rules={{
      vision_board_project_id: project_id_validation,
      id: 'required'
    }}
    values={values}
    errors={errors}
    onValues={onValues}
    onErrors={onErrors}
  >
    {render(
      <React.Fragment>
          {(process.env.REACT_APP_SHOW_VISION_BOARD === "true") ?
          <MainContainer>
            <DropdownContainer>
              <Dropdown
                name="vision_board_project_id"
                label="Select Project"
                placeholder="All Projects"
                items={projects}
                beforeChange={(id, change) => {
                  if (typeof id === 'number') {
                      let items = boards.filter(function(board) {
                                    if(board.vision_board_project_id === id) {
                                        return board;
                                    }
                                  });
                      items.unshift({label: "Create new vision board", value:'add'});
                      this.setState({ boardList: items, vision_board_project_id: id });
                  }
                }}
              />
            </DropdownContainer>
            <ButtonContainer>
                <Button onClick={() => user && user.primary_contact__c ? this.setState({ showProject: !showProject }) : this.setState({ ...this.state, showModal: !showModal})}>Create Project</Button>
                <ProjectModal
                  go={false}
                  show={showProject}
                  reload={false}
                  onClose={() => this.setState({ showProject: !showProject })}
                  setProjectData={this.getProjectData.bind(this)}
                />
                <Modal
                    vision_board_project_id={vision_board_project_id}
                    vision_board_project_name=""
                    setVisionBoardData={this.getVisionBoardData.bind(this)}
                    show={show}
                    onClose={() => this.setState({ show: !show })}
                  />
                <UserModal
                    show={showModal}
                    title={"Complete your Profile"}
                    onClose={() => this.setState({ ...this.state, showModal: false })}
                >
                    {
                      personalInfo ?
                        <PersonalInfo reload={false} submitBtn={true} close={() => this.setState({ ...this.state, showModal: false, showProject: true })} userModal={true} pad_0_25={true} />
                        :
                        <Profession fifty={true} onUserClick={() => this.setState({ ...this.state, personalInfo: true})} headingText="Please Fill out the details" />
                    }

                </UserModal>
            </ButtonContainer>
        </MainContainer> : ''}
        <Clear/>
        <DropdownWrapper selectProject={vision_board_project_id ? true: false}>
          <Dropdown
            create
            name="id"
            label="Type a vision board name and hit enter or choose a saved board"
            promptTextCreator={label => `Create Board "${label}"`}
            placeholder={
              typeof values.id === 'string' ? values.id : 'Vision Board'
            }
            items={boardList.map(board => ({
              label: board.name,
              value: board.id,
              ...board,
            }))}
            beforeChange={(id, change) => { 
              if (typeof id === 'number') {
                let board = boards.find(board => board.id === id) || {};
                change({ 
                  target: { name: 'description', value: board.description },
                });
                const description = removeHtmlTags(board.description);
                if(!isEmpty(description)) {
                    this.setState({ showDescription: true });
                }else {
                    this.setState({ showDescription: false });
                }               
              }else if(id === 'add')
              {
                this.setState({ show: !show });
              }
            }}
          />
        </DropdownWrapper>  
        <RichTextWrapper showDescription={showDescription}>
          <Label>Vision Board Description</Label>
          <ProjectText
            dangerouslySetInnerHTML={{ __html: values.description }}
          />
        </RichTextWrapper>
      </React.Fragment>,
    )}
    {buttons(close)}
  </BaseForm>
)};
}
export default Projects(Fields);
