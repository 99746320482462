import styled, { css } from "react-emotion";
import * as Colors from "base/colors";
import {
  Desktop,
  Montserrat,
  EncodeSans,
  Siliguri,
  Tablet
} from "shared/layout/base/base";

export const CollectionWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 0 20px;

  @media (${Tablet}) {
    width: 100%;
    max-width: 865px;
    margin: 0px -20px;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

export const Container = css`
  width: 100%;
  height: 195px;
  position: relative;
  color: #fff;
  margin: 0 0 20px;

  @media (${Tablet}) {
    height: 245px;
    margin: 0 10px 20px;
    width: calc(100% - 20px);
  }

  @media (min-width: 820px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 950px) {
    width: calc(33.33% - 20px);
  }

  &:before {
    background-color: rgba(30, 30, 30, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
`;

export const Box = styled("div")`
  width: 100%;
  background-size: cover;
  padding: 10px;
  height: 100%;
  z-index: 2;
  position: relative;

  @media (${Tablet}) {
    padding: 20px;
    &:hover {
      svg {
        transform: translateX(30px);
      }
    }
  }

  h3 {
    text-align: left;
    margin: 0;
    font-size: 20px;
    font-weight: 300;
    font-family: ${Montserrat};
    color: ${Colors.white};

    @media (${Desktop}) {
      font-size: 26px;
    }
  }

  h6 {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    font-family: ${Siliguri};
    margin-top: 5px;

    @media (${Desktop}) {
      font-size: 18px;
    }
  }

  span {
    color: #fff;
    position: absolute;
    left: 10px;
    bottom: 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    font-family: ${EncodeSans};

    @media (${Desktop}) {
      font-size: 13px;
      bottom: 20px;
      left: 20px;
    }
  }

  svg {
    width: 25px;
    height: 12px;
    fill: ${Colors.white};
    margin-left: 5px;

    @media (${Desktop}) {
      width: 30px;
      height: 15px;
      transition: transform 0.25s;
    }
  }
`;
