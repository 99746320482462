import styled from 'react-emotion';
import { Tablet } from 'shared/layout/base/base';

export const Container = styled('div')`
  width: 100%;
  margin-top: 40px;

  @media (${Tablet}) {
    max-width: 255px;
    margin-top: 0;
  }
`;
