import React from 'react';
import styled, { css } from 'react-emotion';
import * as Colors from 'base/colors';
import SearchIcon from 'icons/search';
import { Tablet } from 'shared/layout/base/base';

export const Search = () => (
  <SearchIcon
    className={css`
      width: 20px;
      height: 20px;
      fill: ${Colors.black};

      @media (${Tablet}) {
        order: 1;
        fill: ${Colors.ctaGreen};
      }
    `}
  />
);

export const Wrapper = styled('div')`
  position: relative;
  width: 50px;

  @media (${Tablet}) {
    order: 2;
    min-width: 250px;
    flex: 1;
    width: 100%;

    svg {
      position: absolute;
      top: 50% !important;
      transform: translateY(-50%);
      left: 20px;
    }
  }

  svg {
    position: relative;
  }

  @media print {
    display: none;
  }
`;

export const Bar = styled('div')`
  align-items: center;
  display: flex;
  padding: 10px 20px 10px 10px;
  height: 100%;

  @media (${Tablet}) {
    border-radius: 5px;
    border: solid 1px #d4d2d2;
    padding: 10px 20px;
  }
`;

export const Input = styled('input')`
  border: none;
  margin-left: 10px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: ${Colors.lightTextGrey};
  display: none;

  &:focus {
    outline: none;
  }

  @media (${Tablet}) {
    display: block;
    order: 2;
  }
`;
