export default async mutate => {
  try {
    return await mutate();
  } catch (e) {
    console.error(e);
    let errors = e.graphQLErrors;
    if (!errors) return null;

    return {
      global: errors.filter(error => error.field).length === 0,
      errors: errors
        .filter(error => error.field)
        .reduce((acc, error) => ({ [error.field]: error.message, ...acc }), {}),
    };
  }
};
