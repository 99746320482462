import React from "react";
import { Container, Wrapper } from "./style";
import { Button } from "shared/layout/base/base";
import Modal from "shared/modals/desktop";
import AddSample from "../add-sample";
import { Element } from "react-scroll";

class Samples extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { showModal } = this.state;
    let { samples, title, collectionsPage, collection, sampleRequest } = this.props;
    if (!samples.length) return null;
    
    return (
      <Element
        name="samples"
        className="samples"
        id="samples"
        style={{
          position: sampleRequest ? "absolute" : "relative",
          paddingTop: sampleRequest ? "" : "40px",
          marginTop: sampleRequest ? "" : "-20px",
          float: 'left',
          left: '0px',
          bottom: sampleRequest ? "-101px" : "",
        }}
      >
        <Wrapper collectionsPage={collectionsPage}>
          <Button
            onClick={async () => {
              this.setState({ showModal: true });
            }}
            style={{
                padding: sampleRequest ? "16px 65px" :"16px 20px",
            }}
          >
            REQUEST SAMPLES
          </Button>
          <Modal
            show={showModal}
            title="Request Samples"
            onClose={() => this.setState({ showModal: false })}
            overflow
          >
            <Container>
              <AddSample
                collection={collection}
                samples={samples}
                title={title}
                close={() => this.setState({ showModal: false })}
              />
            </Container>
          </Modal>
        </Wrapper>
      </Element>
    );
  }
}
export default Samples;
