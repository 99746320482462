import React from 'react';
import {
  FourColumnGridWrapper,
  ButtonWrapper,
  ProjessionTitle,
  IconWrapper,
} from './style';
import Icon from 'icons';

const items = [
  { name: 'Architect', icon: 'architect' },
  { name: 'Architectural Mill Worker', icon: 'carpenter' },
  { name: 'Builder', icon: 'builder' },
  { name: 'Construction/Project Manager', icon: 'project-manager' },
  { name: 'Designer', icon: 'designer' },
  { name: 'Design/Build', icon: 'design-vision' },
  { name: 'Developer', icon: 'developer' },
  { name: 'Educator or Student', icon: 'educator' },
  { name: 'Flooring Contractor', icon: 'walls' },
  { name: 'General Contractor', icon: 'general-contractor' },
  { name: 'Landscape Architect', icon: 'landscaper' },
  { name: 'Lighting Designer', icon: 'lighting-designer' },
  { name: 'Metal Glass Installer', icon: 'metal-glass-installer' },
  { name: 'Painting Contractor', icon: 'paint' },
  { name: 'Purchasing Agent/FF&E', icon: 'purchasing-agent' },
  { name: 'Owners Rep', icon: 'purchasing-agent' },
  { name: 'Homeowner', icon: 'retail-consumer' },
  { name: 'Stone Contractor', icon: 'wall' },
  { name: 'ASI Employee', icon: 'employee' },
  { name: 'ASI Representative', icon: 'representative' },
  { name: 'Franchise', icon: 'franchise' },
  { name: 'Healthcare', icon: 'healthcare-designer' },
  { name: 'Hospitality', icon: 'hospitality-designer' },
  { name: 'Retail Store', icon: 'retail-store' },
];

export default ({ selectedItem, onClick, fifty }) => (
  <FourColumnGridWrapper>
    {items.map(item => (
      <li key={item.name}>
        <ButtonWrapper fifty={fifty} onClick={() => onClick(item.icon)}>
          <IconWrapper selected={selectedItem === item.icon}>
            <Icon name={item.icon} green />
          </IconWrapper>
          <ProjessionTitle>{item.name}</ProjessionTitle>
        </ButtonWrapper>
      </li>
    ))}
  </FourColumnGridWrapper>
);
