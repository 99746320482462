import React from "react";
import { Link } from "react-router-dom";
import { ButtonWrapper } from "shared/blocks/general/request-quote/style";
import Container from "shared/blocks/general/request-quote/sections/material-container";
import storage from "shared/storage";
import { SalesKitWrapper } from "./style";

export default ({ title, introText, link }) => (
  <Container title={title} introText={introText}>
    {link ? (
      <ButtonWrapper>
        <Link to={link}>View Orders</Link>
      </ButtonWrapper>
    ) : null}

    {storage.get("user").user_role === "employee" ? (
      <SalesKitWrapper>
        <p>Please fill out a sales kit form for this Sample Request.</p>
        <ButtonWrapper>
          <Link to="/sales-portal">Sales Portal</Link>
        </ButtonWrapper>
      </SalesKitWrapper>
    ) : null}
  </Container>
);
