import React from "react";
import qs from "qs";
import { Link } from "react-router-dom";
import { ItemContainer } from "./style";
import { Text } from "shared/layout/text";
import { ctaGreen, black } from "base/colors";
import { Link as LinkScroll } from "react-scroll";

const TextFilter = ({ category, type, slug }) => {
  let query = qs.parse(window.location.search.substring(1));
  let splits = window.location.pathname.split("/");
  let slugSplits = category.slug.split("/");
  let mainCategory = slugSplits[slugSplits.length - 1];
  let active =
    splits[splits.length - 1].split("?")[0] ===
    slugSplits[slugSplits.length - 1];

  let isChildCategory = category.children.length && category.children.filter(function(child) {
                            let slugSplits = child.slug.split("/"); 
                            return splits[splits.length - 1].split("?")[0] ===  slugSplits[slugSplits.length - 1];   
                          });
  return (
    <ItemContainer style={{ marginLeft: "20px" }}>
      {type === "products" ? (
        <Link to={`/${category.slug}`}>
          <Text color={active ? ctaGreen : black} underline={active}>
            {category.name}
          </Text>
        </Link>
      ) : (
        <Link to={`/${category.slug}?slug=${slug || query.slug || ""}`}>
          <Text color={active ? ctaGreen : black} underline={active}>
            {category.name}
          </Text>
        </Link>
      )}

      {(category.children.length && (type === 'projects' || (mainCategory === splits[splits.length - 1].split("?")[0] || isChildCategory.length > 0))) ? (
        <div style={{ marginLeft: 10 }}>
          {category.children.map(child => { 
            let query = qs.parse(window.location.search.substring(1));
            let splits = window.location.pathname.split("/");
            let slugSplits = child.slug.split("/");
            let active =
              splits[splits.length - 1].split("?")[0] ===
              slugSplits[slugSplits.length - 1];

            return (
              <Link to={`/${child.slug}?slug=${slug || query.slug || ""}`}>
                <Text color={active ? ctaGreen : black} underline={active}>
                  {child.name}
                </Text>
              </Link>
            );
          })}
        </div>
      ) : null}
    </ItemContainer>
  );
};

export default TextFilter;
