import React from 'react';
import { BaseForm } from 'react-validify';
import Modal from 'shared/modals/desktop';
import { withRouter } from 'react-router-dom';
import { compose } from "react-apollo";
import Input from 'shared/layout/form/input';
import Dropdown from 'shared/layout/form/dropdown';
import createMutations from './mutations/create';
import Textarea from 'shared/layout/form/text-area';
import { Wrapper, Container, ButtonContainer, CreateButton, MainContainer, DropdownContainer, ButtonWrapper, Clear } from './style';
import ProjectList from 'shared/blocks/products/tabs/card/image/save/queries/projects';
import ProjectModal from 'views/account/boards/projects/modal';
import { Button } from 'shared/layout/base/base';

class CreateModal extends React.Component {
  constructor(data) {
    super();
    this.state = { 
          values: {
            name: "",
            description: "",
            vision_board_project_id: isNaN(data.vision_board_project_id)?'':data.vision_board_project_id
          } 
        };
  }
  
    getProjectData(data)
    {
        let { values } = this.state;
        this.setState({
            values: {
                vision_board_project_id: data.data.createProject.vision_board_project_id
            } 
        });
    }
    
  async create() {
    let { values } = this.state;

    let { onClose, create, go, history, newBoard, setVisionBoardData } = this.props;

    this.setState({ loading: true });
    let response = await create(values);

    if (response && response.errors)
      return this.setState({
        loading: false,
        errors: response.errors,
      });
  
    if(typeof setVisionBoardData === 'function') {
        setVisionBoardData(response.data.createBoard);
    }
    
    onClose();
    if (go) {
      history.push({
        pathname:`/board/${response.data.createBoard.id}`,
        newBoard
      });
    }
    else
      this.setState({
        loading: false,
        values: {},
      });
  }
  render() {
    let { show, onClose, data, vision_board_project_id, vision_board_project_name, reload} = this.props;
    let { values, errors, loading, showProject } = this.state;

    if (!data.vbProjects) return null;
    
    if(!isNaN(vision_board_project_id) && (values.vision_board_project_id === '' || (values.vision_board_project_id !== '' && vision_board_project_id !== values.vision_board_project_id))) {
        this.setState({
            values: {
                vision_board_project_id: vision_board_project_id
            } 
        });
    }
    let items = data.vbProjects.map(project => ({
                    label: project.name,
                    value: project.vision_board_project_id
                  }));
    return (
      <Modal show={show} onClose={onClose} title={'New vision board '+ vision_board_project_name}>
        <Wrapper loading={loading}>
          <Container>
            <BaseForm
              values={values}
              errors={errors}
              rules={{
                name: 'required|min:5|max:45',
                vision_board_project_id:'required' 
              }}
              onValues={values => this.setState({ values })}
              onErrors={errors => this.setState({ errors })}
            > 
              {isNaN(vision_board_project_id)?
              <MainContainer>
                <DropdownContainer>
                  <Dropdown
                    label=""
                    placeholder="All Projects"
                    name="vision_board_project_id"
                    items={items}
                    onChange={(e) => {}}
                    autosize={false}
                  />
                </DropdownContainer>
                <ButtonWrapper>
                  <Button onClick={() => this.setState({ showProject: !showProject })}>Create Project</Button>
                  <ProjectModal
                    go={false}
                    show={showProject}
                    reload={reload}
                    onClose={() => this.setState({ showProject: !showProject })}
                    setProjectData={this.getProjectData.bind(this)}
                  />
                </ButtonWrapper>
              </MainContainer>
              :<Input type="hidden" name="vision_board_project_id" />}
              {isNaN(vision_board_project_id)?<Clear/>:''}
              <Input name="name" placeholder="Vision Board Name" />
              <Textarea
                name="description"
                placeholder="Add project notes or product specification"
              />
              <ButtonContainer submit onClick={values => this.create(values)}>
                <CreateButton>Create</CreateButton>
              </ButtonContainer>
            </BaseForm>
          </Container>
        </Wrapper>
      </Modal>
    );
  }
}

export default compose(ProjectList, createMutations)(withRouter(CreateModal));
