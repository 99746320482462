export default query => ({
  bool: {
    must: [
      {
        multi_match: {
          fields: [
            'post_title',
            'terms.category.name',
            'terms.post_tag.name',
            'terms.archsystems_product.name',
            'terms.product_cat.name',
            'terms.collection_tax.name',
            'meta.woo_product_display_name.value',
          ],
          query,
          fuzziness: '2',
        },
      },
    ],
    filter: [
      { term: { post_status: 'publish' } },
      {
        terms: {
          'post_type.raw': [
            'archsystems_gallery',
            'product',
            'post',
            'collection_cpt',
          ],
        },
      },
    ],
  },
});
