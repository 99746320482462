export const salesReps = [
  { name: "Leslie Anderson", value: "Leslie Anderson" },
  { name: "Amber Bartlett", value: "Amber Bartlett" },
  { name: "Scott Bell", value: "Scott Bell" },
  { name: "Michael Blasek", value: "Michael Blasek" },
  { name: "Joe Blodgett", value: "Joe Blodgett" },
  { name: "Mike Boyko", value: "Mike Boyko" },
  { name: "Danny Boyle", value: "Danny Boyle" },
  { name: "Michael Brannan", value: "Michael Brannan" },
  { name: "Craig Bruehl", value: "Craig Bruehl" },
  { name: "Julie Bruehl", value: "Julie Bruehl" },
  { name: "Shawn Campbell", value: "Shawn Campbell" },
  { name: "Christopher Capobianco", value: "Christopher Capobianco" },
  { name: "Evan Cardwell", value: "Evan Cardwell" },
  { name: "Jake Clary", value: "Jake Clary" },
  { name: "Phil Carey", value: "Phil Carey" },
  { name: "Scott Cucci", value: "Scott Cucci" },
  { name: "Katie Deelo", value: "Katie Deelo" },
  { name: "Danny Delozier", value: "Danny Delozier" },
  { name: "Tim Dormer", value: "Tim Dormer" },
  { name: "Kevin Dunn", value: "Kevin Dunn" },
  { name: "Anthony Ferrante", value: "Anthony Ferrante" },
  { name: "Doug Fulmer", value: "Doug Fulmer" },
  { name: "Justin Gayo", value: "Justin Gayo" },
  { name: "Boyle Group", value: "Boyle Group" },
  { name: "Josh Judy", value: "Josh Judy" },
  { name: "Collin Keating", value: "Collin Keating" },
  { name: "Keith Knecht", value: "Keith Knecht" },
  { name: "Keith Lacognata", value: "Keith Lacognata" },
  { name: "Connor LaPlante", value: "Connor LaPlante" },
  { name: "Megan Lynam", value: "Megan Lynam" },
  { name: "Suzy McGarity", value: "Suzy McGarity" },
  { name: "Katie McGowan", value: "Katie McGowan" },
  { name: "Kelli Mildren", value: "Kelli Mildren" },
  { name: "Ken Munson", value: "Ken Munson" },
  { name: "Neil O'Brien", value: "Neil O'Brien" },
  { name: "Colin O'Donnell", value: "Colin O'Donnell" },
  { name: "Kairos Premier", value: "Kairos Premier" },
  { name: "Nicole Quinn", value: "Nicole Quinn" },
  { name: "Gail Rosenberg", value: "Gail Rosenberg" },
  { name: "Chuck Schillaci", value: "Chuck Schillaci" },
  { name: "Nick Shields", value: "Nick Shields" },
  { name: "Spartan Surfaces", value: "Spartan Surfaces" },
  { name: "Ray Szech", value: "Ray Szech" },
  { name: "Rocky Tramonte", value: "Rocky Tramonte" },
  { name: "Bill Trimble", value: "Bill Trimble" },
  { name: "Hannah Verschoore", value: "Hannah Verschoore" }
];
