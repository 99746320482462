import React from 'react';

const Arrow = props => (
  <svg {...props} viewBox="0 0 32 17" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g transform="translate(-394.000000, -351.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(142.625000, 84.000000)">
            <path d="M50.7802113,7.05035919 L43.9957053,0.265794908 C43.7519918,-0.0187901717 43.3236857,-0.0519655273 43.0391006,0.191806286 C42.7545156,0.435519794 42.7213402,0.863825879 42.965112,1.14841096 C42.9878508,1.17493958 43.012572,1.19971907 43.0391006,1.22239958 L48.6634604,6.85358094 L21.8034331,6.85358094 C21.4287673,6.85358094 21.125,7.15734825 21.125,7.53207235 C21.125,7.90679644 21.4287673,8.21050545 21.8034331,8.21050545 L48.6634604,8.21050545 L43.0391006,13.8348652 C42.7545156,14.0785787 42.7213402,14.5068848 42.965112,14.7914698 C43.2088838,15.0760549 43.6371316,15.1092303 43.9217167,14.8654585 C43.9482453,14.8427196 43.9730248,14.8179985 43.9957053,14.7914698 L50.7802696,8.00690556 C51.043282,7.74237728 51.043282,7.31500407 50.7802113,7.05035919 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Arrow;
