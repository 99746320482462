import styled from 'react-emotion';
import { Siliguri } from 'shared/layout/base/base';

export const OrderTable = styled('div')`
  display: block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #e6e6e6;
  margin-top: 40px;
`;

export const Table = styled('div')`
  display: block;
  padding: 20px;
`;

export const TableTitle = styled('p')`
  font-family: ${Siliguri};
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
`;
