import React from 'react';
import Caret from 'icons/caret';
import Profile from '../profile';
import { Wrapper } from './style';
import { User } from '../../style';

export default class Account extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { onClick, show, toggleShow } = this.props;

    return (
      <Wrapper onClick={onClick}>
        <React.Fragment>
          <User />
          My ASI
          <Caret style={{ marginLeft: 'auto' }} />
          <Profile show={show} onClick={toggleShow} />
        </React.Fragment>
      </Wrapper>
    );
  }
}
