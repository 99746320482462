import React from "react";
import Newsletter from "./newsletter";
import BlockRenderer from "shared/blocks/render";
import { FooterWrapper, Address, ScrollToTopButton } from "./style";
import { SiteContainer } from "shared/layout/base/base";
import Icon from "icons";
import smoothscroll from "smoothscroll-polyfill";
import FooterAddress from "shared/blocks/footer/footer-address";

const Footer = ({ address }) => {
  const ScrollToTop = () => {
    let elmnt = document.getElementById("root");
    elmnt.scrollIntoView({ behavior: "smooth" });

    // kick off the polyfill!
    smoothscroll.polyfill();
  };

  return (
    <FooterWrapper id="footer">
      <ScrollToTopButton onClick={() => ScrollToTop()}>
        <Icon name="caret" />
      </ScrollToTopButton>
      <Newsletter />
      <BlockRenderer slug="footer" />
      <FooterAddress />
    </FooterWrapper>
  );
};

export default Footer;
