import React from "react";
import CustomPages from "views/custom-pages";
import { Route, Redirect, Switch } from "react-router-dom";

export default () => (
  <Switch>
    <Route
      path="/*.aspx"
      render={({ match }) => <Redirect to={`/product/${match.params[0]}`} />}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Infused-Glass-Mosaics-Fabrique"
      render={({ match }) => (
        <Redirect
          to={`/products/search?patterns.terms.archsystems_filter.term_id%5B0%5D=2128&slug=infused-glass-mosaics&first=patterns`}
        />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Infused-Glass-Mosaics-Iridescent"
      render={({ match }) => (
        <Redirect
          to={`/products/search?slug=infused-glass-mosaics&first=patterns&patterns.terms.archsystems_filter.term_id%5B0%5D=2157`}
        />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Infused-Glass-Mosaics-Marble"
      render={({ match }) => (
        <Redirect
          to={`/products/search?slug=infused-glass-mosaics&first=patterns&patterns.terms.archsystems_filter.term_id%5B0%5D=2195`}
        />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Infused-Glass-Mosaics-Nature"
      render={({ match }) => (
        <Redirect
          to={`/products/search?slug=infused-glass-mosaics&first=patterns&patterns.terms.archsystems_filter.term_id%5B0%5D=2166`}
        />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Infused-Glass-Mosaics-Pebbles"
      render={({ match }) => (
        <Redirect
          to={`/products/search?slug=infused-glass-mosaics&first=patterns&patterns.terms.archsystems_filter.term_id%5B0%5D=2178`}
        />
      )}
    />
    <Route
      path="/en-US/Brands--Collections/Specialty/Infused-Glass-Mosaics-Shimmer"
      render={({ match }) => (
        <Redirect
          to={`/products/search?slug=infused-glass-mosaics&first=patterns&patterns.terms.archsystems_filter.term_id%5B0%5D=2141`}
        />
      )}
    />
    <Route
      path="/en-US/Brands--Collections/Display--Furniture/Embossed-Wood---Artisan-Tabletops1/"
      render={({ match }) => (
        <Redirect to={`/collections/embossed-wood-artisan-tabletops`} />
      )}
    />
    <Route
      path="/en-US/Brands--Collections/Display--Furniture/Taboon-Vinyl-Rugs1"
      render={({ match }) => <Redirect to={`/collections/taboon-vinyl-rugs`} />}
    />

    <Route
      path="/en-US/Request-Samples/Samples-Landing-Page/"
      render={({ match }) => <Redirect to={`/products`} />}
    />

    <Route
      path="/en-US/Brands--Collections/Wood-Panels"
      exact
      render={({ match }) => <Redirect to={`/wood-panels`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Flooring"
      exact
      render={({ match }) => <Redirect to={`/flooring`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Flooring/Hardwood"
      exact
      render={({ match }) => <Redirect to={`/hardwood`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Flooring/ASI-Resilient"
      exact
      render={({ match }) => <Redirect to={`/luxury-vinyl`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Flooring/ASI-Stone"
      exact
      render={({ match }) => <Redirect to={`/porcelain`} />}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Cast-Stone-Dimensional-Panels"
      render={({ match }) => (
        <Redirect to={`/collections/cast-stone-dimensional-panels`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Specialty/Agglomerate-Stone-Collection---Slabs"
      render={({ match }) => (
        <Redirect to={`/collections/agglomerate-stone-collection`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Decorative/SWICK-Natural-Wall-Coverings1"
      render={({ match }) => (
        <Redirect to={`/collections/swick-natural-wall-coverings`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Decorative/Bakerloo"
      render={({ match }) => (
        <Redirect to={`/collections/metro-tile-collection-bakerloo`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Decorative/Jubilee"
      render={({ match }) => (
        <Redirect to={`/collections/metro-tile-collection-jubilee`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Decorative/Ornamental_Surfaces"
      render={({ match }) => (
        <Redirect to={`/collections/ornamental-surfaces`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Decorative/Glam"
      render={({ match }) => (
        <Redirect to={`/collections/ornamental-surfaces`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Flooring/Hardwood/Chelsea-Hardwood"
      render={({ match }) => <Redirect to={`/collections/chelsea-hardwoods`} />}
    />

    <Route
      path="/en-US/Brands--Collections/Flooring/ASI-Resilient/Eco-Acoustico-Flooring/"
      render={({ match }) => (
        <Redirect to={`/collections/eco-acoustico-floating-floor`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Flooring/ASI-Resilient/Eco-Tech-Resilient-Flooring"
      render={({ match }) => (
        <Redirect to={`/collections/eco-tech-resilient-floating-floor`} />
      )}
    />

    <Route
      path="/en-US/Brands--Collections/Wood-Panels/Vy-CLAD-Sculptured-Panels/"
      render={({ match }) => (
        <Redirect to={`/collections/sculptured-collection`} />
      )}
    />

    <Route
      path="/en-US/Projects/ShowroomTradeshow/Project-Details/Marriott-Concept-Booth/"
      render={({ match }) => (
        <Redirect
          to={`/project/marriott-m-club-lounge-studio-hi-connect-2015`}
        />
      )}
    />

    <Route
      path="/en-US/Projects/Hospitality/Project-Details/Gravity-Taphouse"
      render={({ match }) => (
        <Redirect to={`/project/gravity-taphouse-grille`} />
      )}
    />
    <Route
      path="/en-US/Projects/Retail/Project-Details/Macys"
      render={({ match }) => <Redirect to={`/project/macys-warwick`} />}
    />
    <Route
      path="/en-US/Projects/Retail/Project-Details/Macys-Herald-Square1"
      render={({ match }) => <Redirect to={`/project/macys-herald-square`} />}
    />

    <Route
      path="/en-US/Projects/Hospitality/Project-Details/Mr-Crabbys"
      render={({ match }) => (
        <Redirect to={`/project/mr-crabbys-seafood-house`} />
      )}
    />

    <Route
      path="/en-US/Projects/Retail/Project-Details/Givenchy"
      render={({ match }) => (
        <Redirect to={`/project/givenchy-jewelry-macys`} />
      )}
    />

    <Route
      path="/en-US/Projects/Retail/Project-Details/Thistle-and-Bee-Too"
      render={({ match }) => <Redirect to={`/project/thistle-bee-too`} />}
    />

    <Route
      path="/en-US/Projects/Retail/Project-Details/Bloomingdales1"
      render={({ match }) => (
        <Redirect to={`/project/bloomingdales-santa-monica-ca`} />
      )}
    />

    <Route
      path="/en-US/Projects/Retail/Project-Details/Bloomingdales1"
      render={({ match }) => (
        <Redirect to={`/project/bloomingdales-santa-monica-ca`} />
      )}
    />

    <Route
      path="/en-US/Projects/Retail/Project-Details/Godiva"
      render={({ match }) => <Redirect to={`/project/godiva-fifth-avenue`} />}
    />
    <Route
      path="/en-US/Projects/Retail/Project-Details/Godiva1"
      render={({ match }) => <Redirect to={`/project/godiva-istanbul`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Wood-Panels/Cubism/"
      render={({ match }) => (
        <Redirect to={`/collections/embossed-wood-geometrix-collection`} />
      )}
    />
    <Route
      path="/en-US/Projects/Hospitality/Project-Details/The-Times-Nyack"
      render={({ match }) => <Redirect to={`/project/the-time-nyack`} />}
    />
    <Route
      path="/en-US/Projects/Hospitality/Project-Details/Paul-Hotel-New-York-NY"
      render={({ match }) => <Redirect to={`/project/the-paul-hotel`} />}
    />

    <Route
      path="/en-US/Projects/Hospitality/Project-Details/Sophies-Restaurant-at-Saks-Chicago-IL"
      render={({ match }) => (
        <Redirect to={`/project/sophies-at-saks-fifth-avenue`} />
      )}
    />

    <Route
      path="/en-US/Projects/Retail/Project-Details/Aeropostale"
      render={({ match }) => (
        <Redirect to={`/project/aeropostale-times-square`} />
      )}
    />

    <Route
      path="/en-US/Projects/Workplace/Project-Details/Testimonial-11"
      render={({ match }) => <Redirect to={`/project/clearwater-systems`} />}
    />

    <Route
      path="/en-US/Projects/Hospitality/Project-Details/Firstmark-Capital11121"
      render={({ match }) => <Redirect to={`/project/firefly-kitchen-bar`} />}
    />

    <Route
      path="/en-US/Projects/Workplace/Project-Details/DAddario--Company-Inc"
      render={({ match }) => (
        <Redirect to={`/project/daddario-company-inc-brooklyn-studio`} />
      )}
    />

    <Route
      path="/en-US/Projects/Hospitality/Project-Details/nordstrom-century-city"
      render={({ match }) => (
        <Redirect to={`/project/nordstrom-century-city-bar-verde`} />
      )}
    />

    <Route
      path="/en-US/Projects/Workplace/Project-Details/Artist-Lounge-DAddario--Company-Inc"
      render={({ match }) => (
        <Redirect to={`/project/daddario-company-inc-artist-lounge`} />
      )}
    />

    <Route
      path="/en-US/Projects/Workplace/Project-Details/CDI"
      render={({ match }) => (
        <Redirect to={`/project/corporate-design-interiors`} />
      )}
    />

    <Route
      path="/en-US/Projects/Workplace/Project-Details/Motiva"
      render={({ match }) => <Redirect to={`/project/motiva-enterprises`} />}
    />

    <Route
      path="/en-US/Brands--Collections/Decorative"
      exact
      render={({ match }) => <Redirect to={`/decorative`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Specialty"
      exact
      render={({ match }) => <Redirect to={`/specialty`} />}
    />
    <Route
      path="/en-US/Brands--Collections/Display--Furniture"
      exact
      render={({ match }) => <Redirect to={`/display-furniture`} />}
    />

    <Route
      path="/en-US/Brands--Collections/*"
      render={({ match }) => {
        let splits = match.params[0].split("/").filter(Boolean);

        return (
          <Redirect
            to={`/collections/${splits[splits.length - 1]
              .toLowerCase()
              .replace("---", "-")
              .replace("--", "-")}`}
          />
        );
      }}
    />

    <Route
      path="/en-US/Projects/*"
      render={({ match }) => (
        <Redirect
          to={`/project/${match.params[0]
            .split("/")[2]
            .toLowerCase()
            .replace("---", "-")
            .replace("--", "-")}`}
        />
      )}
    />

    <Route
      path="/en-US/Projects/*"
      render={({ match }) => (
        <Redirect
          to={`/project/${match.params[0]
            .split("/")[2]
            .toLowerCase()
            .replace("---", "-")
            .replace("--", "-")}`}
        />
      )}
    />

    <Route
      path="/en-US/Blog/*"
      render={({ match }) => (
        <Redirect to={`/community/post/${match.params[0].split("/")[2]}`} />
      )}
    />
    <Route path="/:slug*" component={CustomPages} />
  </Switch>
);
