import React from 'react';
import { Wrapper } from './style';
import * as wrappers from './style';
import renderBlocks from '../index';
import { isEmpty } from 'shared/helper';

export default async (block, index, extraProps, blockWrappers) => {
  let all = block.innerBlocks.reduce(
    (acc, block) => [...acc, ...block.innerBlocks],
    [],
  );

  let components = await Promise.all(
    all.map(block => renderBlocks(block, null, {}, blockWrappers)),
  );

  components = components.map((component, index) => ({
    component,
    name: `${all[index].name}-${index}`
  }));

  let children = (
    <Wrapper key={block.name + index}>
      {block.innerBlocks.map((column, blockIndex) => {
        let wrapper = React.Fragment;
        //grab the wrapper name from gutenberg. first component in the column's classname value

        let attrs = column.attrs ? JSON.parse(column.attrs) : null;
        if (attrs && attrs.className) wrapper = wrappers[attrs.className];

        return React.createElement(wrapper, {
          key: column.name + blockIndex,
          children: column.innerBlocks.map((block, index) => {
            let cpt = components.find(cpt => cpt.name === `${block.name}-${blockIndex}`);
            if(isEmpty(cpt)) return null;
            if (cpt.component.default)
              return React.createElement(cpt.component.default, {
                key: index + blockIndex,
                ...extraProps,
                ...JSON.parse(block.attrs),
              });

            return React.cloneElement(cpt.component, {
              key: block.index,
              ...extraProps,
            });
          }),
        });
      })}
    </Wrapper>
  );

  if (block.attrs && block.attrs.className)
    return React.createElement(wrappers[block.attrs.className], {
      key: block.name + index,
      children,
    });
  return children;
};
