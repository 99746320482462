import React from 'react';
import Profile from '../profile';
import { User } from '../../style';
import { Wrapper } from './style';
import { Button } from '../style';
export default class Account extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { show } = this.state;

    return (
      <Wrapper
        onMouseEnter={() => this.setState({ show: true })}
        onMouseLeave={() => this.setState({ show: false })}
      >
        <React.Fragment>
          <Button>
            <User />
            My ASI
          </Button>
          <Profile show={show} onClick={() => this.setState({ show: false })} />
        </React.Fragment>
      </Wrapper>
    );
  }
}
