import React from "react";
import qs from "qs";
// import ConvertQuote from "./convert-quote";
// import getQuote from "./gql/get-quote";
import { compose } from "react-apollo";
import {
  Wrapper,
  Label,
  TermsAgreementWrapper
} from "views/checkout/shared/style";
import { SiteContainer } from "shared/layout/base/base";
import { Button, ButtonWhiteGreenBorder } from "shared/layout/base/base";
import Search from "shared/search/input";
import Sidebar from "shared/blocks/general/request-quote/sections/sidebar";
import CreatePurchaseOrder from "./gql/createPurchaseOrder";
// import saveQuote from "./gql/save-quote";
import Materials from "shared/blocks/general/request-quote/sections/materials";
import {
  ButtonContainer,
  UploadWrapper
} from "shared/blocks/general/request-quote/sections/form/style";
import Upload from "shared/layout/form/upload";
import Container from "shared/blocks/general/request-quote/sections/material-container";
import MaterialItem from "shared/search/input/material-item";
// import Accessories from "shared/blocks/general/request-quote/sections/accessories";
import {
  SearchWrapper,
  Heading
} from "shared/blocks/general/request-quote/style";
import DeliveryService from "shared/blocks/general/request-quote/sections/delivery-services";
import Address from "shared/blocks/general/request-quote/sections/address";
import Dropdown from "shared/layout/dropdown";
import Input from "shared/layout/form/input";
import { regions } from "shared/blocks/general/request-quote/regions";
import { salesReps } from "shared/blocks/general/request-quote/sales-reps";
import PageHero from "shared/blocks/about/header";
import storage from "shared/storage";
import { Redirect } from "react-router-dom";
import Success from "views/checkout/shared/success";
import getQuote from "shared/blocks/general/request-quote/gql/get-quote";
import Textarea from "shared/layout/form/text-area";
import Checkbox from "shared/layout/form/checkbox";
import { cartId } from "shared/helper";

class PurchasingMaterials extends React.Component {
  constructor({ location = window.location }) {
    super();
    let item = qs.parse(location.search.substring(1));

    let products = item.products || [];
    this.state = {
      valid: true,
      quote_id: item.quoteId,
      items: [item, ...products]
        .map(item => ({
          sku: item.sku,
          qty: item.qty,
          id: parseFloat(item.id)
        }))
        .filter(i => i.id),
      values: {
        Delivery_Requirements: null,
        shipping_id: null,
        region: null,
        spartan_order_number: null,
        job_name: null,
        sales_rep: null,
        project_location:null,
        termsAgreements: false
      },
      errors: {}
    };
  }

  componentDidMount(prevProps) {
    if (!this.props.data || !this.props.data.quote) return;
    this.setState({
      file: this.props.data.quote.file,
      Name: this.props.data.quote.Name,
      usedName: this.props.data.quote.Name,
      values: {
        Delivery_Requirements: this.props.data.quote.Delivery_Requirements,
        // OpportunityId: this.props.data.quote.OpportunityId,
        // file_url: this.props.data.quote.file_url,
        // billing_id: this.props.data.quote.billing_id,
        shipping_id: this.props.data.quote.shipping_id,
        shipping: this.props.data.quote.shipping.fullAddress,
        // BillingName: this.props.data.quote.BillingName,
        // ShippingName: this.props.data.quote.ShippingName,
        region: this.props.data.quote.region,
        spartan_order_number: this.props.data.quote.spartan_order_number
          ? this.props.data.quote.spartan_order_number
          : null,
        job_name: this.props.data.quote.job_name,
        sales_rep: this.props.data.quote.sales_rep,
        termsAgreements: false
      },
      items: this.props.data.quote.products.map(item => ({
        sku: item.sku,
        qty: item.qty,
        id: item.id
      }))
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.data || prevProps.data.quote === this.props.data.quote)
      return;
    this.setState({
      file: this.props.data.quote.file,
      Name: this.props.data.quote.Name,
      usedName: this.props.data.quote.Name,
      values: {
        Delivery_Requirements: this.props.data.quote.Delivery_Requirements,
        // OpportunityId: this.props.data.quote.OpportunityId,
        // file_url: this.props.data.quote.file_url,
        // billing_id: this.props.data.quote.billing_id,
        shipping_id: this.props.data.quote.shipping_id,
        shipping: this.props.data.quote.shipping.fullAddress,
        // BillingName: this.props.data.quote.BillingName,
        // ShippingName: this.props.data.quote.ShippingName,
        region: this.props.data.quote.region,
        spartan_order_number: this.props.data.quote.spartan_order_number
          ? this.props.data.quote.spartan_order_number
          : null,
        job_name: this.props.data.quote.job_name,
        sales_rep: this.props.data.quote.sales_rep,
        termsAgreements: false
      },
      items: this.props.data.quote.products.map(item => ({
        sku: item.sku,
        qty: item.qty,
        id: item.id
      }))
    });
  }

  render() {
    let { createPurchaseOrder, saveQuote, data } = this.props;
    let {
      items,
      success,
      quoteApproval,
      values,
      newProject,
      loading,
      errors,
      usedAddress,
      quotePending,
      quote_id,
      valid,
      Name,
      file,
      hasSpecialPricing,
      salesforceQuoteNumber,
      ShippingPostalCode
    } = this.state;
    let test = regions.find(region => region.value === values.region);
    if (storage.get("user").user_role !== "customer_service")
      return <Redirect to="/" />;

    return (
      <React.Fragment>
        <PageHero
          text="Place Order"
          image="https://dte1ze7ytxnas.cloudfront.net/uploads/2018/11/about-bg-min.jpg"
        />
        <SiteContainer>
          <Wrapper>
            {success ? (
              <Success
                title="Thank you for making a purchase"
                link="/account/orders"
                introText="You can view the order status from your account"
              />
            ) : (
              <Container
                title="Place Order"
                introText="Thank you for your interest. Please fill out the following form to purchase materials."
              >
                {errors.global ? (
                  <p style={{ color: "red", margin: "20px 0" }}>
                    {errors.global}
                  </p>
                ) : null}
                <Input
                  error={errors.job_name}
                  type="text"
                  name="job_name"
                  value={values.job_name}
                  placeholder="Job Name"
                  onChange={e => {
                    this.setState({
                      values: { ...values, job_name: e.target.value }
                    });
                  }}
                />
                <Input
                  error={errors.spartan_order_number}
                  type="text"
                  name="spartan_order_number"
                  value={values.spartan_order_number}
                  placeholder="Spartan Purchase Order Number"
                  onChange={e =>
                    this.setState({
                      values: {
                        ...values,
                        spartan_order_number: e.target.value
                      }
                    })
                  }
                />
                <Textarea
                  error={errors.notes}
                  type="text"
                  name="notes"
                  value={values.notes}
                  placeholder="Notes"
                  onBlur={() => true}
                  onChange={e =>
                    this.setState({
                      values: { ...values, notes: e.target.value }
                    })
                  }
                />

                {items.length > 0 ? (
                  <Materials
                    isDisabled={quote_id ? true : false}
                    items={items}
                    isValid={valid => {
                      this.setState({ valid });
                    }}
                    noMinimum={false}
                    onDelete={id =>
                      this.setState({
                        items: items.filter(item => item.id !== id)
                      })
                    }
                    onValue={newItem => {
                      this.setState({
                        items: items.map(item =>
                          item.id === newItem.id
                            ? {
                                ...item,
                                ...newItem,
                                qty: parseFloat(newItem.qty)
                              }
                            : item
                        )
                      });
                    }}
                  />
                ) : null}
                {quote_id ? null : (
                  <SearchWrapper>
                    <Heading>
                      Add {items.length ? "additional " : ""}
                      materials
                    </Heading>
                    {errors.items ? (
                      <p style={{ color: "red", margin: "20px 0" }}>
                        {errors.items}
                      </p>
                    ) : null}
                    <Search
                      matchers={query => ({
                        bool: {
                          must: [
                            {
                              multi_match: {
                                fields: [
                                  "post_title",
                                  "terms.category.name",
                                  "terms.post_tag.name",
                                  "terms.archsystems_product.name",
                                  "terms.product_cat.name",
                                  "terms.collection_tax.name",
                                  "meta.woo_product_display_name.value"
                                ],
                                query,
                                fuzziness: "2"
                              }
                            }
                          ],
                          must_not: [
                            {
                              match: {
                                "meta.woo_product_budget-price-low.value": ""
                              }
                            },
                            {
                              terms: {
                                post_staus: ["private", "inherit"]
                              }
                            }
                          ],
                          filter: [
                            { term: { post_status: "publish" } },
                            {
                              terms: {
                                post_type: ["product"]
                              }
                            }
                          ]
                        }
                      })}
                      modifyResults={results =>
                        results.filter(result => {
                          if (!result.meta.woo_product_spartan_price) {
                            return false;
                          }

                          if (
                            result.meta.woo_product_spartan_price &&
                            (result.meta.woo_product_spartan_price[0].value ===
                              "" ||
                              result.meta.woo_product_spartan_price[0].value ===
                                "0")
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        })
                      }
                      placeholder="Start typing to find more products..."
                      onSelect={(id, { post_title, meta }) => {
                        this.setState({
                          items: [
                            ...items,
                            {
                              id: parseFloat(id),
                              qty:
                                meta &&
                                meta.woo_product_minimum_amount &&
                                meta.woo_product_minimum_amount[0]
                                  ? meta.woo_product_minimum_amount[0].value
                                    ? meta.woo_product_minimum_amount[0].value
                                    : 1
                                  : 1,
                              sku: post_title
                            }
                          ]
                        });
                      }}
                      renderItem={props => <MaterialItem {...props} />}
                    />
                  </SearchWrapper>
                )}

                <div
                  style={{
                    width: "100%",
                    marginBottom: 40,
                    paddingBottom: 40,
                    marginTop: 40,
                    borderBottom: "1px solid #e0dfdf"
                  }}
                >
                  <Heading>Regions</Heading>
                  {quote_id ? (
                    <Input type="text" readOnly value={values.region} />
                  ) : (
                    <div>
                      {errors.region ? (
                        <p style={{ color: "red", margin: "20px 0" }}>
                          {errors.region}
                        </p>
                      ) : null}
                      <Dropdown
                        style={{ width: "100%" }}
                        value={values.region}
                        items={regions.map((region, index) => ({
                          label: region.name,
                          value: region.value
                        }))}
                        onChange={e => {
                          this.setState({
                            values: {
                              ...values,
                              region: e.value
                            }
                          });
                        }}
                        isSearchable
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    width: "100%",
                    marginBottom: 40,
                    paddingBottom: 40,
                    marginTop: 40,
                    borderBottom: "1px solid #e0dfdf"
                  }}
                >
                  <Heading>Sales Rep</Heading>
                  {quote_id ? (
                    <Input type="text" readOnly value={values.sales_rep} />
                  ) : (
                    <div>
                      {errors.sales_rep ? (
                        <p style={{ color: "red", margin: "20px 0" }}>
                          {errors.sales_rep}
                        </p>
                      ) : null}
                      <Dropdown
                        style={{ width: "100%" }}
                        value={values.sales_rep}
                        items={salesReps.map(rep => ({
                          label: rep.name,
                          value: rep.value
                        }))}
                        onChange={e => {
                          this.setState({
                            values: {
                              ...values,
                              sales_rep: e.value
                            }
                          });
                        }}
                        isSearchable
                      />
                    </div>
                  )}
                </div>

                <React.Fragment>
                  <Heading
                    style={{
                      marginTop: "30px",
                      marginBottom: "0px"
                    }}
                  >
                    Address Information
                  </Heading>

                  {quote_id ? (
                    <Input type="text" readOnly value={values.shipping} />
                  ) : (
                    <Address
                      errors={errors}
                      update={data => {
                        this.setState({
                          values: {
                            ...values,
                            ...data
                          }
                        });
                      }}
                      values={values}
                    />
                  )}
                  <Label>Custom Freight Quote</Label>
                  <Input
                    error={errors.customer_freight}
                    type="number"
                    step="0.01"
                    name="customer_freight"
                    value={values.customer_freight}
                    placeholder="e.g. 200.50"
                    onChange={e =>
                      this.setState({
                        values: {
                          ...values,
                          customer_freight: parseFloat(e.target.value)
                        }
                      })
                    }
                  />
                </React.Fragment>

                <div style={{ marginBottom: 40 }}>
                  {errors.Delivery_Requirements ? (
                    <p style={{ color: "red", margin: "20px 0" }}>
                      {errors.Delivery_Requirements}
                    </p>
                  ) : null}
                  <DeliveryService
                    isDisabled={quote_id ? true : false}
                    Delivery_Requirements={values.Delivery_Requirements}
                    update={Delivery_Requirements => {
                      this.setState({
                        values: {
                          ...values,
                          Delivery_Requirements
                        }
                      });
                    }}
                  />
                </div>

                <UploadWrapper>
                  <Upload
                    onChange={e =>
                      this.setState({
                        values: { ...values, [e.target.name]: e.target.value }
                      })
                    }
                    value={values.file ? values.file : ""}
                    // error={errors.file}
                    name="file"
                    label="Upload Purchase Order"
                    accept="=.pdf"
                  />
                </UploadWrapper>
                <div style={{ marginBottom: 40 }}>
                  <Label>Terms & Agreements</Label>
                  {errors.termsAgreements ? (
                    <p style={{ color: "red", margin: "20px 0" }}>
                      {errors.termsAgreements}
                    </p>
                  ) : null}
                  <TermsAgreementWrapper>
                    <Checkbox
                      isDisabled
                      name="termsAgreements"
                      value={values.termsAgreements}
                      onChange={() =>
                        this.setState({
                          values: {
                            ...values,
                            termsAgreements: !values.termsAgreements
                          }
                        })
                      }
                    />
                    <p>
                      By placing this order, I agree to ASI's{" "}
                      <a href="/terms-of-use">terms and conditions.</a>
                    </p>
                  </TermsAgreementWrapper>
                </div>

                <ButtonContainer>
                  <Button
                    loading={loading === "submit"}
                    onClick={async () => {
                      if (!valid) return;
                      this.setState({ loading: "submit" });

                      let emptyFields = Object.keys(values).filter(
                        key =>
                          values[key] === null ||
                          values[key] === false ||
                          values[key] === ""
                      );

                      let requiredFields = emptyFields.reduce(
                        (acc, key) => ({ ...acc, [key]: "Required Field" }),

                        {}
                      );

                      if (emptyFields.length || items.length == 0) {
                        window.scrollTo(0, 0);
                        this.setState({
                          loading: false,
                          errors: {
                            ...errors,
                            ...requiredFields,
                            items:
                              items.length == 0 ? "You need to add items" : "",
                            global:
                              "Please make sure to fill out all of the fields!"
                          }
                        });

                        return;
                      } else {
                        this.setState({
                          errors: {}
                        });
                      }

                      let mutationValues = {};
                      mutationValues = { ...values };

                      delete mutationValues.shipping;
                      delete mutationValues.termsAgreements;

                      let response = await createPurchaseOrder({
                        input: {
                          ...mutationValues,
                          cart_id: cartId(),
                          quote_id: parseInt(quote_id, 10)
                        },
                        products: items.map(item => ({
                          ...item,
                          qty: parseFloat(item.qty)
                        }))
                      });

                      if (!response.global) window.scrollTo(0, 0);
                      if (response.errors)
                        return this.setState({
                          errors: response.errors,
                          loading: false
                        });

                      let success = response.data.createPurchaseOrder === true;

                      this.setState({
                        success: true,
                        loading: false
                      });
                    }}
                  >
                    Checkout
                  </Button>
                </ButtonContainer>
              </Container>
            )}

            <Sidebar />
          </Wrapper>
        </SiteContainer>
      </React.Fragment>
    );
  }
}

export default compose(getQuote, CreatePurchaseOrder)(PurchasingMaterials);
