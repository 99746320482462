import React from "react";
import Field from "./field";
import Input from "./input";
import styled from "react-emotion";
import * as colors from "base/colors";
import { UploadField } from "@navjobs/upload";

const Upload = styled("div")`
  display: flex;
  width: 100%;
  height: 50px;
  align-self: flex-end;
  border: solid 1px #e6e6e6;
`;

const Name = styled("div")`
  width: 75%;
  padding-left: 20px;
  background-color: ${colors.dropdownGrey};
`;

const Button = styled("div")`
  width: 25%;
  display: flex;
  align-items: center;
  color: ${colors.ctaGreen};
  justify-content: center;
  border-left: solid 1px #e6e6e6;
  background-color: ${colors.white};
`;

export default props => {
  let {
    label,
    type,
    error,
    success,
    onBlur,
    onEnter,
    hideLabel,
    onChange,
    value,
    fileUrl,
    onError,
    ...rest
  } = props;
  return (
    <React.Fragment>
      <Field {...props} onEnter={onEnter} error={error}>
        <UploadField
          onFiles={files => {
            if (files.length) {
              let size = files[0].size / 1024 / 1024;
              if (size > 10) {
                onError(
                  "This file is too big! The max size for file uploads is 10mb. Please use a file url."
                );
              } else {
                onChange({
                  target: { name: props.name, value: files[0], size }
                });
              }
            }
          }}
          uploadProps={rest}
        >
          <Upload>
            <Name>
              <p>
                {props.value && props.value.name
                  ? props.value.name
                  : "Select a file..."}
              </p>
            </Name>
            <Button>
              <p>Browse</p>
            </Button>
          </Upload>
        </UploadField>
      </Field>
      {fileUrl ? (
        <Input
          type="url"
          label="File URL"
          value={fileUrl.value}
          placeholder="https://..."
          name={`${props.name}_url`}
          onChange={e =>
            onChange({
              target: { name: `${props.name}_url`, value: e.target.value }
            })
          }
        />
      ) : null}
    </React.Fragment>
  );
};
