import React from "react";
import { SiteContainer } from "shared/layout/base/base";
import {
  Wrapper,
  InfoWrapper,
  Text,
  Title,
  Subtext,
  Form,
  Button
} from "./style";
import Input from "./input";
import segment from "shared/segment";
import { BaseForm } from "react-validify";
export default class Newsletter extends React.Component {
  constructor() {
    super();
    this.state = { values: {} };
  }

  async submit(values) {
    await segment("newsletter", values);
    this.setState({ submitted: true, values: {} });
  }

  render() {
    let { values, errors, submitted } = this.state;
    return (
      <Wrapper>
        <SiteContainer>
          <InfoWrapper>
            <Text>
              {/* Client will add strong tags in the customizer if they want bold text */}
              <Title>
                <strong>Sign-Up</strong> To Stay In The Know!
              </Title>
              <Subtext>
                Get the latest on materials, projects and the Next New Thing
                <span>®</span>
              </Subtext>
            </Text>
            <Form errors={errors}>
              <BaseForm
                errors={errors}
                values={values}
                onValues={values => this.setState({ values })}
                onErrors={errors => this.setState({ errors })}
                rules={{ email: "required|email", company: "required|min:2" }}
              >
                {submitted ? (
                  "Thank you for subscribing with us!"
                ) : (
                  <React.Fragment>
                    <Input
                      type="email"
                      name="email"
                      label="Enter your email address"
                    />
                    <Input type="text" name="company" label="Company Name" />

                    <Button submit onClick={values => this.submit(values)}>
                      Subscribe
                    </Button>
                  </React.Fragment>
                )}
              </BaseForm>
            </Form>
          </InfoWrapper>
        </SiteContainer>
      </Wrapper>
    );
  }
}
