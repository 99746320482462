/* globals google */

let autocompleteService;

export default async input => {
  if (!autocompleteService)
    autocompleteService = new google.maps.places.AutocompleteService();

  let results = await new Promise(resolve =>
    autocompleteService.getPlacePredictions(
      {
        input,
        fields: 'geometry',
        types: ['address'],
      },
      value => resolve(value),
    ),
  );

  if (results) {
    return results.map(result => ({
      value: result.place_id,
      label: result.description,
    }));
  }
  return [];
};
