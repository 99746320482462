import React from 'react';
import styled, { css } from 'react-emotion';
import { white, black, ctaGreen, lightTextGrey } from 'base/colors';
import { Tablet, Phone } from 'shared/layout/base/base';

export const Input = styled('input')`
  width: ${props =>
    props.header && props.mobileHeaderSearch ? '100vw' : '100%'};
  height: 100%;
  border: none;
  outline: none;
  ${props =>
    props.header && props.mobileHeaderSearch
      ? ' margin-left: 70px'
      : 'padding-left: 50px'};
  font-family: Encode Sans;
  font-size: 12px;
  font-weight: 500;
  color: ${lightTextGrey};
  ${props => (props.header ? null : 'background-color: rgb(230, 229, 229)')};
  opacity: ${props => (props.header && props.mobileHeaderSearch ? '1' : '0')};
  transition: opacity 0.5s;
`;

export const Search = css`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 50px;
  font-family: Encode Sans;
  font-size: 12px;
  font-weight: 500;
  color: ${lightTextGrey};
  background-color: rgb(230, 229, 229);
`;

export const HeaderSearch = css`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 50px;
  font-family: Encode Sans;
  font-size: 12px;
  font-weight: 500;
  color: ${lightTextGrey};
  opacity: 0;
  transition: opacity 0.5s;

  @media (${Tablet}) {
    border-radius: 5px;
    border: solid 1px #d4d2d2;
    opacity: 1;
  }
`;

export const HeaderSearchOpen = css`
  width: 100vw;
  height: 100%;
  border: none;
  outline: none;
  margin-left: 70px;
  font-family: Encode Sans;
  font-size: 12px;
  font-weight: 500;
  color: ${lightTextGrey};
  opacity: 1;
  transition: opacity 0.5s;

  @media (${Tablet}) {
    border-radius: 5px;
    border: solid 1px #d4d2d2;
    opacity: 1;
  }
`;

export const Item = styled('div')`
  background: ${white};
  margin-right: 20px;
  margin-left: 20px;

  &:nth-child(5) {
    border-bottom: solid 1px #e6e6e6;
  }

  &:last-child {
    div {
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const Content = styled('div')`
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Encode Sans;
  color: ${props => (props.isHighlighted ? ctaGreen : black)};
  border-bottom: solid 1px #e6e6e6;
  transition: color 0.25s;

  mark {
    font-weight: bold;
    color: ${props => (props.isHighlighted ? ctaGreen : black)};
    background-color: ${white};
    transition: color 0.25s;
  }

  span {
    line-height: 2;
  }
`;

export const Category = styled('div')`
  height: 20px;
  font-family: Encode Sans;
  cursor: pointer;
  margin: 10px 20px;
  color: ${props => (props.isHighlighted ? ctaGreen : black)};
`;

export const menuStyle = {
  zIndex: 999999,
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: white,
  padding: '2px 0',
  fontSize: '90%',
  position: 'absolute',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  overflowY: 'scroll',
};

export const searchStyle = {
  width: '100%',
  height: '50px',
  display: 'inline-block',
};

export const WrapperStyle = styled(
  ({ mobileHeaderSearch, header, ...props }) => <div {...props} />,
)`
  display: inline-block;
  width: ${props => (props.mobileHeaderSearch ? '100vw' : '100%')};
  position: ${props => (props.mobileHeaderSearch ? 'absolute' : 'relative')};
  transform: ${props =>
    props.mobileHeaderSearch ? 'translateY(-50%)' : 'none'};
  right: ${props => (props.mobileHeaderSearch ? '0' : 'auto')};
  transition: ${props =>
    props.mobileHeaderSearch ? 'width 0.25s, opacity 0.4s' : 'none'};

  ${props =>
    props.header
      ? `
      svg {
        position: absolute;
        right: 20px;
        top: 50%;
        z-index: 999;
        transform: translateY(-50%);
      }`
      : null};

  > div {
    width: 100%;
    height:  ${props =>
      props.header
        ? `
        40px`
        : `50px`};

    ${props =>
      props.header
        ? `
      background-color: #fff;`
        : null};
  }

  @media(${Tablet}) {
    display: inline-block;
    width: 100%;
    position: relative;
    transform: none;
    right: auto;
    transition: none;
}
  }
`;

export const Arrow = styled('div')`
  ${props =>
    props.header
      ? `
      display: block`
      : `display: none`};

  position: absolute;
  left: 20px;
  width: 30px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: ${props =>
    props.header && props.mobileHeaderSearch ? 'block' : 'none'};

  svg {
    width: 100%;
    height: 15px;
    transform: rotate(180deg);
    right: auto;
    position: relative;
    top: auto;
  }

  @media (${Tablet}) {
    display: none;
  }
`;

export const SearchIcon = styled('div')`
 ${props =>
   props.header
     ? `
     @media (${Phone}) {
        right: 0;
        left: auto;
        background-color: #fff;
        top: -3px;
        transform: none;
      }
    `
     : ' right: 0;'};

  position: absolute;
  right: auto;
  left: 0;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  width: 60px;
  height: 100%;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;
