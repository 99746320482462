import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'shared/layout/text';
import Check from 'icons/check';
import { Flex } from 'shared/layout';
import { ctaGreen, lightTextGrey } from 'base/colors';
import { ColorText } from './style';

const Colors = ({ product }) =>
  product.hasOptions ? (
    <ColorText>
      <Link to={`/product/${product.sku}`}>
        <Flex>
          <Check
            color={ctaGreen}
            width={15}
            height={15}
            style={{ marginRight: '8px', alignSelf: 'center' }}
          />
          <Text size={12} color={lightTextGrey}>
            More Options Available
          </Text>
        </Flex>
      </Link>
    </ColorText>
  ) : null;

export default Colors;
