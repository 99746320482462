import styled from 'react-emotion';
import { Tablet, EncodeSans } from "shared/layout/base/base";

export const RichTextWrapper = styled('div')`
  margin-top: 20px;
  ${props => (props.showDescription ? '' : 'display: none')};
`;

export const Label = styled('p')`
  font-family: 'Encode Sans',sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #434343;
`;

export const DropdownWrapper = styled('div')`
  .Select-placeholder, .Select-value {
    line-height: 30px!important;
  }

  .Select-option:first-of-type {
    ${props => (props.selectProject ? `color: #61a60d;` : '')};
  }
`;

export const MainContainer = styled('div')`
  width: 100%;
  margin: auto;
}`;

export const DropdownContainer = styled('div')`
  width: 70%;
  float: left;
  padding-right: 20px;
  
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
  }
  .Select-placeholder, .Select-value {
    line-height: 30px!important;
  }
}`;

export const ButtonContainer = styled('div')`
  margin-left: 15%;
  padding-top: 27px;
  
  button {
    padding: 14px 20px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
}`;

export const Clear = styled('div')`
  clear:both;
}`;

export const ProjectText = styled("div")`
  font-family: ${EncodeSans};
  font-size: 13px;
  line-height: 1.77;
  word-wrap: break-word;

  @media (${Tablet}) {
    font-size: 16px;
    line-height: 1.53;
  }
`;