import React from 'react';
import { MenuWrapper, Menu, MenuList } from './style';
import Materials from './materials';
import ProjectStudio from './project-studio';
import Community from './community';
import { Link } from 'react-router-dom';
import MobileAccount from '../account/mobile';

export default class SideMenu extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let {
      show,
      showSubmenu,
      offcanvas,
      toggleShow,
      data,
      toggleSubmenu,
    } = this.props;

    return (
      <MenuWrapper show={show}>
        <Menu show={show}>
          <Materials
            show={showSubmenu === 'materials'}
            onClick={hide => toggleSubmenu(hide === true ? false : 'materials')}
            data={data}
            toggleShow={toggleShow}
          />
          <ProjectStudio
            show={showSubmenu === 'projects'}
            onClick={hide => toggleSubmenu(hide === true ? false : 'projects')}
            data={data}
            toggleShow={toggleShow}
          />
          <Community
            show={showSubmenu === 'community'}
            onClick={hide => toggleSubmenu(hide === true ? false : 'community')}
            data={data}
            toggleShow={toggleShow}
          />
          <MobileAccount
            show={showSubmenu === 'account'}
            onClick={hide => toggleSubmenu(hide === true ? false : 'account')}
            toggleShow={toggleShow}
          />
          <MenuList>
            {offcanvas.links.map(item => (
              <li key={item.path}>
                <Link to={item.path} onClick={toggleShow}>
                  {item.name}
                </Link>
              </li>
            ))}
          </MenuList>
        </Menu>
      </MenuWrapper>
    );
  }
}
