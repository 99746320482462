import React from 'react';

const Play = props => (
  <svg {...props} viewBox="0 0 34 34">
    <g
      id="Style-Guide"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="style-guide-1" transform="translate(-822.000000, -432.000000)">
        <g id="icons" transform="translate(231.000000, 268.000000)">
          <g id="Group-3" transform="translate(565.000000, 165.000000)">
            <g id="icon/play-copy" transform="translate(27.000000, 0.000000)">
              <g id="icon/play">
                <polygon
                  id="Path"
                  fill={props.fill}
                  fillRule="evenodd"
                  points="13 23 23 15.5 13 8"
                />
                <circle
                  id="Oval"
                  stroke={props.fill}
                  strokeWidth="2"
                  cx="16"
                  cy="16"
                  r="16"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Play;
