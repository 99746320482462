import { graphql } from 'react-apollo';

import gql from 'graphql-tag.macro';

export const query = gql`
  fragment menu on Menu {
    name
    slug
    links {
      name
      path
    }
    image {
      id
      medium
      full
    }
  }

  query Menu {
    menus {
      featuredPress {
        ...menu
      }
      topMarketSegments {
        ...menu
      }
      offcanvas {
        ...menu
      }
      browseMaterials {
        ...menu
      }
      projectStudio {
        ...menu
      }
    }
  }
`;
export default graphql(query);
