import React from 'react';
import { css } from 'emotion';
import Arrow from 'icons/arrow';
import { Link } from 'react-router-dom';
import { ctaGreen, titleGrey } from 'base/colors';
import { EncodeSans } from 'shared/layout/base/base';

const LinkArrow = css`
  color: ${titleGrey};
  transition: color 0.25s;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;

  &:hover {
    color: ${ctaGreen};

    svg {
      transform: translateX(20px);
    }
  }

  svg {
    width: 30px;
    height: 15px;
    fill: ${ctaGreen};
    margin-left: 20px;
    transition: transform 0.25s;
  }
`;
export default ({ url, to, text }) =>
  to ? (
    <Link to={to} className={LinkArrow}>
      {text}
      <Arrow />
    </Link>
  ) : (
    <a href={url} className={LinkArrow}>
      {text}
      <Arrow />
    </a>
  );
