import React from 'react';
import { Link } from 'react-router-dom';
import Caret from 'icons/caret';
import { Container, SecondaryNavLink } from './style';

const NavSection = ({ open, items, onClick, toggleShow }) => (
  <li key={items.slug} onClick={onClick}>
    {items.slug === '/builders-group-2' ?
        (<SecondaryNavLink>
            <Link to={items.slug}>
                {items.name}
            </Link>
        </SecondaryNavLink>)
        : (<SecondaryNavLink show={open}>
          {items.links ? (
            <span>
              {items.name}
              <Caret />
            </span>
          ) : (
            <Link to={items.slug ? items.slug : items.path} onClick={toggleShow}>
              {items.name}
            </Link>
          )}
          {items.links ? (
            <Container show={open}>
              {items.links.map(link => (
                <Link key={link.path} to={link.path} onClick={toggleShow}>
                  {link.name}
                </Link>
              ))}
            </Container>
          ) : null}
        </SecondaryNavLink>)
    }
  </li>
);

export default NavSection;
