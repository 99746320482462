import qs from 'qs';
import React from 'react';
import filterNames from '../filter';
import { withRouter } from 'react-router';
import { mergeQuery } from 'shared/query-string';
import Dropdown from 'shared/layout/form/dropdown';
import { Container, Content, Text, DropdownContainer, Wrapper } from './style';
import { isEmpty } from "shared/helper.js";
import _ from "lodash";

export default withRouter(({ history, products, data, slug, type, collections }) => {
  if (!data.searchSidebar && isEmpty(products)) return null;

  let splits = window.location.pathname.split('/');
  let active = splits[splits.length - 1];

  slug = slug || qs.parse(window.location.search.substring(1)).slug;
  
  const findTerm = (term_id) =>
        data && data.getTerms && data.getTerms.length
          ? data.getTerms.find(term => parseInt(term.term_id) === term_id)
          : null;
  
    let map = new Map();
    
    products && products.forEach(product => {
        product && product.terms && product.terms.archsystems_filter && product.terms.archsystems_filter.forEach(filter => { 
            if(map.has(filter["name"])) {
                map.get(filter["name"]).count++;
            }else { 
                const term = findTerm(filter.parent);
                const parent_name = term ? term.name : null; 
                map.set(filter["name"], Object.assign(filter, {count:1, taxonomy: 'archsystems_filter', parent_name: parent_name}));
            }
        });
    });

    let filters = [...map.values()].filter(filter => filter.count < products.length);
    
    const productFilters = _.chain(filters)
                        .groupBy("parent_name")
                            .map((value, key) => ({ name: key, children: value })).value(); 
                    
  return (
    <Container>
      <Content>
        <Wrapper>
          {data.searchSidebar && data.searchSidebar.categories ? (
            <React.Fragment>
              <Text>Filter By</Text>
              <DropdownContainer width={150}>
                <Dropdown
                  searchable={false}
                  placeholder="Category"
                  value={`products/search/${active}`}
                  items={data.searchSidebar.categories.children.map(item => ({
                    label: item.name,
                    value: item.slug,
                  }))}
                  onChange={e =>
                    history.push(
                      `/${e.target.value}${slug ? `?slug=${slug}` : ''}`,
                    )
                  }
                  hideLabel
                />
              </DropdownContainer>
            </React.Fragment>
          ) : null}

          {productFilters && productFilters.map(filter => {
            return (
              <DropdownContainer width={150} key={filter.name}>
                <Dropdown
                  searchable={false}
                  placeholder={filter.name}
                  items={filter.children.map(item => {
                    let { name, value, isFirst, query } = filterNames(
                      filter,
                      item,
                    );

                    return {
                      label: item.name,
                      value: `${
                        collections
                          ? window.location.pathname
                          : `/${type}/search`
                      }${mergeQuery({
                        [name]: value,
                        slug,
                        first: isFirst
                          ? filter.name.toLowerCase()
                          : query.first,
                      })}`,
                    };
                  })}
                  hideLabel
                  onChange={e => history.push(e.target.value)}
                />
              </DropdownContainer>
            );
          })}
        </Wrapper>
      </Content>
    </Container>
  );
});
