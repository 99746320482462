import React from "react";
import styled, { css } from "react-emotion";
import * as Colors from "base/colors";
import BasketIcon from "icons/basket";
import BoardIcon from "icons/board";
import { Tablet } from "shared/layout/base/base";

export const Basket = () => (
  <BasketIcon
    className={css`
      fill: ${Colors.ctaGreen};
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 23px;
    `}
  />
);

export const Board = () => (
  <BoardIcon
    className={css`
      fill: ${Colors.ctaGreen};
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 23px;
    `}
  />
);

export const Wrapper = styled("div")`
  position: relative;
  transform: ${props => (props.show ? "translateX(0)" : "translateX(-100%)")};
  opacity: ${props => (props.show ? "1" : "0")};
  height: ${props => (props.show ? "100%" : "0")};
  display: block;

  @media (${Tablet}) {
    height: auto;
    display: block;
    transform-origin: top center;
    transform: scaleY(1);
    transition: transform 0.25s ease-in-out, height 0.25s ease-in-out,
      opacity 0.3s;
    height: 42px;
    opacity: 1;
    z-index: 1;
    /* display: ${props => (props.show ? "none" : "block")}; */

    .headroom--unpinned & {
      transform: scaleY(0);
      opacity: 0;
      transition: transform 0.25s ease-in-out, height 0.25s ease-in-out,
        opacity 0.3s;
      height: 0;
    }
  }

  @print {
    display: none;
  }
`;

export const List = styled("div")`
  list-style: none;
  justify-content: center;
  display: none;
  background-color: ${Colors.white};

  @media (${Tablet}) {
    display: flex;
  }
`;

export const ListItem = styled("div")`
  padding: 0 10px 20px;
  color: #333333;
  font-size: 12px;
  font-weight: 500;
  transition: color 0.25s, border-color 0.25s;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin: 0 20px;

  @media (min-width: 769px) {
    font-size: 13px;
  }

  &:hover {
    border-color: ${Colors.ctaGreen};
  }
  a {
    color: #333333;
  }
  a:hover {
    color: #333333;
  }
`;

export const SubNavWrapper = styled("div")`
  position: absolute;
  top: 42px;
  max-width: 1120px;
  width: calc(100% - 40px);
  left: 50%;
  box-sizing: border-box;
  max-height: 80vh;
  border: 1px solid #e6e6e6;
  transform: ${props =>
    props.show ? "scaleY(1) translateX(-50%)" : "scaleY(0) translateX(-50%)"};
  transition: transform 0.25s ease-in-out, height 0.25s ease-in-out,
    opacity 0.25s;
  opacity: ${props => (props.show ? "1" : "0")};
  transform-origin: top center;
  z-index: 2;
  background-color: ${Colors.white};
`;

export const SubNavContainer = styled("div")`
  max-height: 80vh;
  overflow-y: auto;
  height: 100%;
`;

export const SubNav = styled("div")`
  padding: 20px 0;
  background-color: ${Colors.white};
  column-count: 2;

  @media (min-width: 850px) {
    column-count: 3;
  }

  @media (min-width: 1020px) {
    column-count: 4;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: block;

    &:first-child {
      a {
        color: ${Colors.ctaGreen};
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 300;
        position: relative;
        vertical-align: middle;

        span:after {
          content: "";
          width: 0px;
          height: 0px;
          border: solid #61a60d;
          border-width: 0px 1px 1px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(-50deg);
          margin-left: 10px;
          vertical-align: middle;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-top: 15px;
        }
      }
    }
  }

  a {
    display: block;
    color: ${Colors.lightTextGrey};
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    padding: 10px 20px;
    transition: color 0.25s;

    &:hover {
      color: ${Colors.ctaGreen};
    }
  }
`;

export const LinkArrowWrapper = styled("div")`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.white};
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  text-decoration: none;
  width: 100%;

  a {
    width: 100%;
    height: 60px;
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
  }
`;
