import React from 'react';
import styled from 'react-emotion';
import { EncodeSans } from 'shared/layout/base/base';

export const ColorWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
`;

export const ColorButton = styled(({ first, ...props }) => (
  <button {...props} />
))`
  width: 86px;
  outline: none;
  cursor: pointer;
  margin: 7px;
  padding: 0;

  img {
    ${props => (props.first ? 'border-color: #ad619d !important' : null)};
  }

  &:hover {
    img {
      border-color: #ad619d;
    }
  }

  img {
    width: 100%;
    display: block;
    padding: 4px;
    border: solid 2px transparent;
    margin-bottom: 10px;
    transition: border-color 0.25s;
  }

  span {
    font-family: ${EncodeSans};
    font-size: 10px;
    display: block;
  }
`;
