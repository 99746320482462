import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";

export const query = gql`
  query getTerms {
    getTerms {
      term_id
      name

    }
  }
`;
export default graphql(query, {
  options: () => {
    return {
      fetchPolicy: "cache-and-network"
    };
  }
});
