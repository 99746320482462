import styled from "react-emotion";
import * as Colors from "base/colors";
import { Tablet, EncodeSans, Phone } from "shared/layout/base/base";

export const FilterWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items:center;
  flex-direction: column;
  padding: 20px 0;
  position:relative;

  @media print {
    display: none;
    margin: 0;
    padding:0;
  }

  @media (max-width: 1119px) {
    margin: 0 20px;
  }

  @media (${Tablet}) {
    flex-direction: row;
    padding: 40px 0;
  }

  .Select {
    width: 100%;
    height: 50px;

    .Select-control {
      border-radius: 3px;
      background-color: #fbfafa;
      border: solid 1px #e0dfdf;
    }

    @media (${Tablet}) {
      width: 257px;
    }

    > div {
      color: ${Colors.titleGrey};
      height: 100%;
    }

    .Select-input {
      height: 100%;
    }

    .Select-placeholder {
      line-height: 35px;
      font-family: ${EncodeSans};
      font-size: 13px;
      color: ${Colors.titleGrey};
      font-weight: 400;
    }

    input {
      height: 100%
      padding: 0;
    }
  }

  button {
    margin-top: 20px;

    @media (${Tablet}) {
      margin-top: 0;
    }
  }
`;

export const ModalBody = styled("div")`
  text-align:center;
`;

export const ButtonContainer = styled("div")`
  button:last-child {
    @media (min-width: 768px) {
      margin-left: 20px;
    }
  }
`;

export const ToolBar = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 0 20px;
  background-color: #f3f2f2;
  border-radius: 23px;
  margin-left: 15%;
  div.pen-tool {
    display:flex;
    height: 100%;
    margin: 0 10px;
  }
  div.active{
    border-top: 2px solid #a3c874
  }

  div.pen-tool button.active {
    background-color: #a3c874;
    cursor: pointer;
    border-radius: 7px;
    margin: 15px 0;
    padding: 7px 6px;
  }
  div.pen-tool button:hover {
    background-color: #a3c874;
    cursor: pointer;
    border-radius: 10px;
    margin: 15px 0;
    padding: 7px 6px;
  }

  div.pen-tool button.in-active:hover {
    background-color: #f3f2f2;
    cursor: default;
    border-radius: 10px;
    margin: 15px 0;
    padding: 7px 6px;
  }

  @media (${Phone}) {
     margin-left: unset;
  }

  .__react_component_tooltip {
    position: absolute;
    border-radius: 6px;
    padding: 15px;
}
`;


export const ChromePickerWrapper = styled("div")`
  position: absolute;
  bottom: 28%;
  right: 30%;
  transform: translateY(100%);
  z-index: 11;
`;

export const PenToolWrapper = styled("div")`
  display:flex;
  svg {
    margin: 0 2px;
  }
  button {
    width: auto;
  }
  button:hover {
    background-color: #8080807d;
    cursor: pointer;
  }
  a {
    width: 35px;
    border: none;
    cursor: pointer;
    background: black;
    height: 22px;
    margin-top: 3px;
  }
`;

export const TextToolWrapper = styled("div")`
  display:flex;
  select{
    background-color: #222;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    margin: 0 3px;
  }
  svg {
    margin: 0 2px;
  }
  button {
    width: 25px;
    padding: 0px;
  }
  button:hover, button.active {
    background-color: #8080807d;
    cursor: pointer;
  }
  a.colorPickerText{
    width: 40px;
    border: none;
    cursor: pointer;
    background: black;
    height: 30px;
  }
  div.ChromePickerWrapper {
    right: 4%;
  }

`;
