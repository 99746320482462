import React from "react";
import Icon from "icons";
import DefaultItem from "./default-item";
import defaultMatcher from "./default-matcher";
import { getQuery } from "shared/query-string";
import { withRouter } from "react-router-dom";
import search from "shared/elastic-search";
import addCategories from "./add-categories";
import Autocomplete from "react-autocomplete";
import { ctaGreen } from "base/colors";
import Media from "react-resized";
import {
  //Input,
  Item,
  WrapperStyle,
  menuStyle,
  searchStyle,
  Arrow,
  SearchIcon,
  HeaderSearchOpen,
  Search,
  //WrapperStyleSearch,
  //WrapperStyleSearchOpen,
  HeaderSearch
} from "./style";

/*
  would love to split this out better, but this autocomplete 
  component does not support reacts new ref api, so you can't 
  pull out the render prop components
*/
class SearchInput extends React.Component {
  constructor() {
    super();
    this.state = {
      results: [],
      value: getQuery().query,
      open: false,
      openMobile: false
    };
    this.click = this.click.bind(this);
  }

  componentDidMount() {
    if (this.state.value) this.search(this.state.value);

    window.addEventListener("click", this.click);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.click);
  }

  click() {
    let { open } = this.state;
    if (open) this.setState({ open: false });
  }

  async search(input) {
    let { modifyResults, matchers } = this.props;
    this.setState({
      value: input
    });
    if (!matchers) matchers = defaultMatcher;

    let { raw } = await search({
      query: matchers(input)
    });

    let hits = raw.hits.hits.map(hit => hit._source);

    this.setState({
      open: raw.hits.hits.length ? true : false,
      results: modifyResults ? modifyResults(hits) : addCategories(input, hits)
    });
  }

  render() {
    let { open, results, value, focused, openMobile } = this.state;
    let {
      header,
      renderItem,
      history,
      media,
      onClick,
      onSelect,
      placeholder
    } = this.props;
    if (!openMobile && window.innerWidth < 768 && header)
      return (
        <WrapperStyle
          onClick={e => {
            e.stopPropagation();
            if (results.length && !open) this.setState({ open: true });
          }}
          mobileHeaderSearch={header ? openMobile : null}
          header={header}
        >
          <div>
            <Arrow
              onClick={e => {
                if (window.innerWidth < 768 && header) {
                  this.setState({
                    openMobile: !openMobile
                  });
                }
              }}
              mobileHeaderSearch={header ? openMobile : null}
              header={header}
            >
              <Icon name="arrow" />
            </Arrow>

            <SearchIcon
              header={header}
              mobileHeaderSearch={header ? openMobile : null}
            >
              <Icon
                onClick={e => {
                  if (window.innerWidth < 768 && header) {
                    onClick();

                    this.setState({
                      openMobile: !openMobile
                    });
                  }
                }}
                name="search"
                style={{
                  height: 20,
                  fill: ctaGreen,
                  width: 20
                }}
              />
            </SearchIcon>
          </div>
        </WrapperStyle>
      );
    return (
      <WrapperStyle
        onClick={e => {
          e.stopPropagation();
          if (results.length && !open) this.setState({ open: true });
        }}
        mobileHeaderSearch={header ? openMobile : null}
        header={header}
      >
        <Autocomplete
          open={
            window.innerWidth < 768 && header
              ? openMobile && value
              : open && focused
          }
          items={results}
          autoHighlight
          value={value}
          onChange={e => this.search(e.target.value)}
          renderInput={props => (
            <React.Fragment>
              <Arrow
                onClick={e => {
                  if (window.innerWidth < 768 && header) {
                    this.setState({
                      openMobile: !openMobile
                    });
                  }
                }}
                mobileHeaderSearch={header ? openMobile : null}
                header={header}
              >
                <Icon name="arrow" />
              </Arrow>

              <input
                className={
                  header
                    ? media === "mobile"
                      ? openMobile
                        ? HeaderSearchOpen
                        : HeaderSearch
                      : HeaderSearch
                    : Search
                }
                placeholder={placeholder || "Materials, Projects and more..."}
                ref={ref => props.ref(ref)}
                {...props}
                onChange={e => {
                  if (header) window.scrollTo(0, 0);
                  props.onChange(e);
                }}
                onKeyDown={e => {
                  props.onKeyDown(e);
                  if (e.keyCode === 13) {
                    if (window.innerWidth < 768)
                      history.push(`/search?query=${e.target.value}`);
                    this.setState({
                      value: "",
                      open: false,
                      openMobile: false
                    });
                  }
                }}
                autoFocus={openMobile}
                onFocus={() => this.setState({ focused: true })}
              />
              <SearchIcon
                header={header}
                mobileHeaderSearch={header ? openMobile : null}
              >
                <Icon
                  onClick={e => {
                    if (window.innerWidth < 768 && header) {
                      onClick();

                      this.setState({
                        openMobile: !openMobile
                      });
                    }
                  }}
                  name="search"
                  style={{
                    height: 20,
                    fill: ctaGreen,
                    width: 20
                  }}
                />
              </SearchIcon>
            </React.Fragment>
          )}
          searchStyle={searchStyle}
          renderMenu={(items, value, style) => (
            <div
              style={{
                ...style,
                ...menuStyle,
                left: 0,
                top: 50,
                height: this.state.results.length ? "300px" : "50px"
              }}
              children={items}
            />
          )}
          renderItem={(item, isHighlighted) => (
            <Item key={item.ID || item.name || item.post_title}>
              {renderItem ? (
                renderItem({ item, term: value, isHighlighted })
              ) : (
                <DefaultItem
                  item={item}
                  term={value}
                  isHighlighted={isHighlighted}
                />
              )}
            </Item>
          )}
          onSelect={(selected,item) => {
            this.setState({ open: false, value: "", openMobile: false });
            if (onSelect) {
              let result = results.find(
                result => result.post_title === selected && result.ID == item.ID
              );

              return onSelect(result.ID, result);
            }
            if (onSelect) {
              let result = results.find(
                result => result.post_title === selected && result.ID
              );
              return onSelect(result.post_type, result);
            }

            let result = results.find(
              result =>
                result.post_title &&
                result.ID &&
                result.post_title.toLowerCase() === selected.toLowerCase()
            );
            if (!result) return history.push(`/search?query=${selected}`);
            let paths = {
              product: "product",
              post: "community/post",
              archsystems_gallery: "project",
              collection_cpt: "collections"
            };
            history.push(`/${paths[result.post_type]}/${result.post_name}`);
          }}
          getItemValue={item => item.filter || item.post_title}
        />
      </WrapperStyle>
    );
  }
}

export default withRouter(
  Media(SearchInput)(() => ["mobile", "standard", "standard"])
);
