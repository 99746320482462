import React from "react";
import Mobile from "./mobile";
import Standard from "./standard";
import ElasticSearch from "views/products/search/products/elastic-search";
import getTerms from "./queries/get-terms";
import RenderSize from "shared/render-size";

const SidebarRenderer = props => (
  <RenderSize
    mobile={Mobile}
    desktop={Standard}
    props={{ ...props }}
    page="main"
  />
);

export default getTerms(ElasticSearch(SidebarRenderer));

