import React from 'react';
import { Wrapper, Heading, HeadingWrapper } from './style';

export default ({ title, children, loading }) => (
  <Wrapper loading={loading}>
    {title ? (
      <HeadingWrapper>
        <Heading>{title}</Heading>{' '}
      </HeadingWrapper>
    ) : null}
    {children}
  </Wrapper>
);
