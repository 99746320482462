import gql from "graphql-tag.macro";
export const query = gql`
  fragment address on Address {
    id
    city
    state
    phone
    email
    line_1
    line_2
    zip_code
    isDefault
    last_name
    first_name
    company
  }

  fragment cart on Cart {
    id

    card {
      id
      last4
    }
    payment_method

    shipping {
      total
    }

    company
    project
    city
    state
    country
    instructions

    addresses {
      billing {
        ...address
      }
      shipping {
        ...address
      }
    }

    products {
      id
      qty
      box_qty
      name
      price
      total
      sku
      leadTime
      description
      unitOfMeasure
      image {
        thumbnail
      }
      product_id
    }

    samples {
      id
      qty
      type
      name
      sku
      stock
      description
      image {
        thumbnail
      }
      product_id

      address {
        ...address
      }
    }

    verboseSamples {
      id
      qty
      type
      name
      sku
      image {
        thumbnail
      }
      product_id

      address {
        ...address
      }
    }

    # customerID
    backorder
    AccountID
    OpportunityId
    isPaymentRequired {
      products
      samples
    }
    Name
    multiple_addresses
    Send_Via_Period__c
    Credit_Terms__c
    PoNumber
    Delivery_Requirements
    CloseDate
    Project_Location__c
    Project_Type__c
    Shipping_Acount_Number__c
  }
`;
export default query;
