import React from 'react';

export default class Icon extends React.Component {
  constructor({ name }) {
    super();
    this.state = { name };
  }

  static getDerivedStateFromProps({ name }, state) {
    if (name !== state.name) return { name };
    return null;
  }

  async componentDidUpdate({ name }) {
    if (this.state.name !== name) this.loadIcon();
  }

  async componentDidMount() {
    this.loadIcon();
  }

  async loadIcon() {
    let { name } = this.state;
    if (!name) return;

    try {
      let icon = await import(`./${name}`);
      this.setState({ icon });
    } catch (e) {}
  }

  render() {
    let { name, ...props } = this.props;
    let { icon } = this.state;

    if (!icon) return null;

    return React.createElement(icon.default, { ...props });
  }
}
