import React from 'react';
import Caret from 'icons/caret';
import { Flex } from 'shared/layout';
import { ctaGreen } from 'base/colors';
import { Link } from 'react-router-dom';
import { getQuery, mergeQuery } from 'shared/query-string';
import { Text, PaginateButton, PrevLink, NextLink } from './style';

export default ({ total, onChange = () => true }) => {
  if (!total || total <= 15) return null;
  let query = getQuery();
  let min = parseInt(query.from || 0, 10);
  let displayMax = min + 15;
  if (displayMax > total) displayMax = total;

  return (
    <Flex alignItemsCenter spaceBetween>
      <Text>
        <strong>
          {min + 1 || 1}-{displayMax}
        </strong>{' '}
        of <strong>{total}</strong> products
      </Text>
      {!min ? null : (
        <PrevLink>
          <Link
            to={
              min <= 0
                ? window.location.pathname + window.location.search
                : window.location.pathname +
                  mergeQuery({ from: min - 15 > 0 ? min - 15 : 0, size: 15 })
            }
            onClick={onChange}
          >
            <PaginateButton>
              <Caret color={ctaGreen} rotate="-180" />
            </PaginateButton>
          </Link>
        </PrevLink>
      )}

      {total > min + 15 ? (
        <NextLink>
          <Link
            to={
              total <= min + 15
                ? window.location.pathname +
                  mergeQuery({ from: total - 15, size: 15 })
                : window.location.pathname +
                  mergeQuery({ from: min + 15, size: 15 })
            }
            onClick={onChange}
          >
            <PaginateButton marginLeft>
              <Caret color={ctaGreen} />
            </PaginateButton>
          </Link>
        </NextLink>
      ) : null}
    </Flex>
  );
};
