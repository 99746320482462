import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans, Siliguri } from 'shared/layout/base/base';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Collection = styled('span')`
  font-family: ${Siliguri};
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.lightTextGrey};
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
`;

export const Title = styled('h3')`
  font-family: ${EncodeSans};
  font-size: 22px;
  line-height: 1.05;
  color: ${Colors.titleGrey};
  margin: 10px 0 20px;
  font-weight: 400;
`;

export const Color = styled('span')`
  font-family: ${EncodeSans};
  font-size: 22px;
  line-height: 1.05;
  color: ${Colors.titleGrey};
  display: block;
  margin-bottom: 10px;
`;

export const Wrapper = styled('div')`
  padding-bottom: 20px;

  a {
    margin-top: 10px;
    display: inline-block;
  }
`;

export const ColorsList = styled('div')`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid ${Colors.borderColor};
  border-bottom: 1px solid ${Colors.borderColor};
`;

export const List = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled('span')`
  font-family: ${Siliguri};
  font-size: 13px;
  font-weight: 500;
  line-height: 1.77;
  color: ${Colors.titleGrey};
  margin-bottom: 10px;
  display: block;
`;

export const Dimensions = styled('div')`
  padding: 20px 0;
`;

export const Table = styled('div')`
  width: 100%;
  border-top: solid 1px ${Colors.borderColor};
  border-left: solid 1px ${Colors.borderColor};
  border-right: solid 1px ${Colors.borderColor};
`;

export const Row = styled('div')`
  display: flex;
`;

export const Heading = styled('div')`
  width: 50%;
  background-color: #f3f2f2;
  border-bottom: solid 1px ${Colors.borderColor};
  border-right: solid 1px ${Colors.borderColor};
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  padding: 20px;
  color: ${Colors.titleGrey};
`;

export const Data = styled('div')`
  width: 50%;
  padding: 20px;
  font-family: ${EncodeSans};
  font-size: 13px;
  color: ${Colors.titleGrey};
  border-bottom: solid 1px ${Colors.borderColor};
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 20px;
    fill: ${Colors.ctaGreen};
    margin-left: 10px;
  }
`;

export const RequestSamples = styled('div')`
  padding: 20px 0;
  border-bottom: solid 1px ${Colors.borderColor};
`;

export const Notification = styled('div')`
  font-size: 12px;
  color: red;
  margin-top: 10px;
`;
