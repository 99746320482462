import React from "react";
import { ItemDetails, Details, Name, ID, Description } from "../style";
import { AlertNotification } from "shared/layout/base/base";

export default ({ product, qty, checkout }) => (
  <ItemDetails>
    <img src={product.image ? product.image.thumbnail : null} alt="text" />
    <Details>
      <Name>{product.name}</Name>
      <ID>Spec ID# {product.sku}</ID>
      {product.unitOfMeasure ? (
        <Name> Unit: {product.unitOfMeasure} </Name>
      ) : null}
      {product.description ? (
        <Description>{product.description}</Description>
      ) : null}
      {product.leadTime ? (
        <AlertNotification>
          {checkout
            ? `Approximate lead time is ${product.leadTime}`
            : `Approximate lead time is ${product.leadTime}`}
        </AlertNotification>
      ) : null}
    </Details>
  </ItemDetails>
);
