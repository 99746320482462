import React from 'react';

const Basket = props => (
  <svg
    {...props}
    viewBox="0 0 23 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(-494.000000, -79.000000)">
        <g transform="translate(494.000000, 79.000000)">
          <g>
            <g>
              <path d="M16.9303279,7.31557377 L12.3319672,0.418032787 C12.1229098,0.209016393 11.8094262,0 11.4959016,0 C11.182377,0 10.8688525,0.104508197 10.6598361,0.418032787 L6.06147541,7.31557377 L1.04508197,7.31557377 C0.418032787,7.31557377 0,7.73360656 0,8.36065574 C0,8.46516393 0,8.56967213 0,8.67418033 L2.61270492,18.3934426 C2.82172131,19.2295082 3.65778689,19.9610656 4.59836066,19.9610656 L18.1844262,19.9610656 C19.125,19.9610656 19.9610656,19.3340164 20.170082,18.3934426 L22.7827869,8.67418033 C22.7827869,8.56967213 22.7827869,8.46516393 22.7827869,8.36065574 C22.7827869,7.73360656 22.3647541,7.31557377 21.7377049,7.31557377 L16.9303279,7.31557377 Z M8.36065574,7.31557377 L11.4959016,2.71721311 L14.6311475,7.31557377 L8.36065574,7.31557377 Z M11.4959016,15.6762295 C10.3463115,15.6762295 9.4057377,14.7356557 9.4057377,13.5860656 C9.4057377,12.4364754 10.3463115,11.4959016 11.4959016,11.4959016 C12.6454918,11.4959016 13.5860656,12.4364754 13.5860656,13.5860656 C13.5860656,14.7356557 12.6454918,15.6762295 11.4959016,15.6762295 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Basket;
