import React from 'react';
import types from './types';
import { Container } from './style';
import { BaseForm } from 'react-validify';
import Input from 'shared/layout/form/input';
import { Button } from 'shared/layout/base/base';
import Dropdown from 'shared/layout/form/dropdown';
import { FieldWrapper, Section } from 'shared/user/form-style';

export default ({ values, errors, loading, update, submit }) => (
  <React.Fragment>
    <Container full>
      <p style={{ textAlign: 'center' }}>Or create account for new company</p>
    </Container>
    <BaseForm
      rules={{
        CompanyName: 'required|string',
        Fax: 'regex:/^\\d{3}-\\d{3}-\\d{4}$/',
        Website: 'url',
        Type: `required|in:${types.map(type => type.value).join()}`,
      }}
      values={values}
      errors={errors}
      onValues={values => update({ values })}
      onErrors={errors => update({ errors })}
    >
      <Section noPaddingTop>
        <FieldWrapper twoColumn>
          <Input name="CompanyName" label="Company name*" />
          <Dropdown
            items={types.map(type => ({
              label: type.label,
              value: type.value,
            }))}
            label="Type*"
            name="Type"
            placeholder="Select a Type"
          />
        </FieldWrapper>
        <FieldWrapper twoColumn>
          <Input name="Fax" label="Fax" />
          <Input name="Website" label="Website" />
        </FieldWrapper>
        <Container full>
          <Button submit loading={loading} onClick={values => submit(values)}>
            Submit
          </Button>
        </Container>
      </Section>
    </BaseForm>
  </React.Fragment>
);
