import React from 'react';
import styled, { css } from 'react-emotion';
import { ctaGreen, white } from 'base/colors';
import Icon from 'icons';
import { EncodeSans, Tablet } from 'shared/layout/base/base';

export const ButtonContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 20px;

  button + button {
    margin-top: 20px;
  }

  @media (${Tablet}) {
    display: flex;
    width: 50%;
    justify-content: center;
    margin: 0 auto;

    button + button {
      margin-left: 20px;
      margin-top: 0;
    }

    button {
      width: 100%;
    }
  }
`;

const Button = css`
  display: flex;
  align-items: center;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  color: ${ctaGreen};
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 220px;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.8;
  padding: 15px;
  border: 2px solid ${ctaGreen};
  border-radius: 3px;
  background-color: ${white};
  transition: background-color 0.25s, color 0.25s;

  svg {
    width: 20px;
    height: 20px;
    fill: ${ctaGreen};
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${white};
    background-color: ${ctaGreen};

    svg {
      fill: ${white};
    }
  }

  @media (${Tablet}) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;
export const AddButton = ({ text, newProject, innerRef, ...props }) => (
  <button
    className={Button}
    {...props}
    ref={ref => innerRef && innerRef(ref)}
    style={{ width: '100%', justifyContent: 'center' }}
  >
    <Icon name={newProject ? 'minus' : 'plus'} />
    {text}
  </button>
);

export const UploadWrapper = styled('div')`
  margin: 20px 0;
`;
