import styled from "react-emotion";
import { Tablet } from "shared/layout/base/base";

export const FormContainer = styled("div")`
  padding: 0 20px;

  @media (${Tablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;
    padding: ${props => props.pad_0_25 ? "0 20px" : "0"};
  }

  .Select-control {
    padding: 5px;
    border-color: #e0dfdf;
  }
  .Select-placeholder,
  .Select-value {
    padding: 5px;
    padding-left: 15px;
  }
`;

export const Container = styled("div")`
  border: none;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;

  @media (${Tablet}) {
    width: ${props => (props.full ? "100%" : "calc(50% - 20px);")};
    margin: 0 10px 20px 0px;
    ${props => (props.left ? "margin-right: auto;" : "20px;")};

    p {
      margin: 0;
    }
  }
`;

export const Text = styled("p")`
  display: block;
  font-family: "Encode Sans", sans-serif;
  font-size: 15px;
  line-height: 2.23;
  color: #000;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 20px !important;
`;
