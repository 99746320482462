import { cartId } from "shared/helper";
import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
import mutator from "shared/gql/mutate";
import cart from "shared/gql/fragments/cart";

export const product = gql`
  mutation AddProduct($input: AddCartProductInput!) {
    addProduct(input: $input) {
      ...cart
    }
  }
  ${cart}
`;

export const sample = gql`
  mutation AddSample($input: AddSamplesInput!) {
    addSample(input: $input) {
      ...cart
    }
  }
  ${cart}
`;
const types = {
  product,
  sample
};
export default type =>
  graphql(types[type], {
    props: ({ mutate }) => ({
      add: async input => {
        return mutator(() =>
          mutate({
            variables: { input: { ...input, id: cartId() } }
          })
        );
      }
    })
  });
