import React from 'react';
import { Query } from 'react-apollo';
import Select from 'react-select';
import Field from 'shared/layout/form/field';
let timer;
let searchValue;

export default ({
  query,
  name,
  items,
  label,
  onSelect,
  variables,
  value,
  onChange,
  error,
  success,
  placeholder,
}) => (
  <Query query={query}>
    {({ data, refetch, loading }) => {
      return (
        <Field {...{ label, error, success, hideLabel: false }}>
          <Select
            value={value}
            style={{ marginTop: '5px' }}
            name={name}
            placeholder={placeholder}
            options={items(data)}
            onSelectResetsInput={false}
            filterOption={data => true}
            onInputChange={async value => {
              searchValue = value;
              if (!value) return value;
              if (value.length === 1) {
                await refetch(variables(value));
                return value;
              }

              clearTimeout(timer);

              timer = setTimeout(() => refetch(variables(value)), 350);
              return value;
            }}
            onChange={async value => {
              if (onChange)
                return onChange({ target: { name, value: value.id } });
              if (!value) return onSelect();

              onSelect(value);
            }}
            noResultsText={
              loading
                ? 'Loading results...'
                : searchValue
                  ? 'No results'
                  : 'Search...'
            }
          />
        </Field>
      );
    }}
  </Query>
);
