import React, { Component } from 'react';
import { SiteContainer } from 'shared/layout/base/base';
import { Container, Title, Subtext } from './style';
import Head from 'shared/head';

class ErrorPage extends Component {
  render() {
    return (
      <SiteContainer>
        <Head title="Site Maintenance" />
        <Container>
          <Title>Site Maintenance</Title>
          <Subtext style={{fontSize: '22px'}}>Maintenance is in-progress... we will be back shortly.</Subtext>
        </Container>
      </SiteContainer>
    );
  }
}
export default ErrorPage;
