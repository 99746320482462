import React, { Component } from "react";

import Cart from "./cart";
import Search from "./search";
import LogoImage from "./logo.png";
import Navigation from "./navigation";
import menusQuery from "./gql/get-menus";
import Account from "./account/standard";
import { Link } from "react-router-dom";
import {
  SiteHeader,
  SiteHeaderTop,
  Logo,
  MenuButton,
  MenuButtonWrapper,
  URLWrapper
} from "./style";
import {
  CartIcon,
  CartWrapper,
  CartButton,
  CartItems,
  ShoppingCartBackButton,
} from './cart/style';
import { withRouter } from "react-router-dom";
import storage from 'shared/storage';

class Header extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidUpdate(prevProps) {
    if (this.props.location === prevProps.location || this.state.show === false)
      return;

    this.setState({ show: false, showSubmenu: null });
  }

  toggleShow(show) {
    this.setState({ show, showSubmenu: null });
    let body = document.querySelector("body").style;
    if (!show) {
      body.position = "";
      return (body.overflow = "");
    }
    body.overflow = "hidden";
  }

  render() {
    let { data } = this.props;
    let { show, showSubmenu } = this.state;
    return (
      <SiteHeader show={show}>
        <SiteHeaderTop>
          <MenuButton
            show={show}
            onClick={() =>
              //when a sub menu on mobile is displayed, show a back button, dont hide the whole nav
              showSubmenu && show
                ? this.setState({ showSubmenu: null })
                : this.toggleShow(!show)
            }
          >
            <MenuButtonWrapper backArrow={showSubmenu} close={show}>
              <span />
              <span />
              <span />
            </MenuButtonWrapper>
          </MenuButton>
          <Link to="/">
            <Logo>
              <img src={LogoImage} alt="Archsystems Logo" />
            </Logo>
          </Link>
          {storage.get('user') ? <Cart /> : (<CartWrapper>
            <CartButton>
              <CartIcon />
              <CartItems>0</CartItems>
            </CartButton>
          </CartWrapper>)}
          <Search
            onClick={() => this.setState({ showSubmenu: null, show: false })}
          />
          <Account />
          <URLWrapper>
            <p>www.archsystems.com</p>
          </URLWrapper>
        </SiteHeaderTop>
        {data.menus ? (
          <Navigation
            show={show}
            data={data}
            toggleShow={() => this.toggleShow(!show)}
            toggleSubmenu={name => this.setState({ showSubmenu: name })}
            showSubmenu={showSubmenu}
          />
        ) : null}
      </SiteHeader>
    );
  }
}
export default menusQuery(withRouter(Header));
