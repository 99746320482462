import React from 'react';
const Newspaper = props => (
  <svg
    {...props}
    viewBox="0 0 26 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g transform="translate(-827.000000, -348.000000)">
        <g transform="translate(231.000000, 268.000000)">
          <g transform="translate(574.125000, 80.000000)">
            <g transform="translate(22.625000, 0.000000)">
              <g>
                <path d="M21.5384615,3.07692308 L21.5384615,0 L0,0 L0,16.9230769 C0,16.9230769 0,20 3.07692308,20 L22.3076923,20 C22.3076923,20 24.6153846,19.9523077 24.6153846,16.9230769 L24.6153846,3.07692308 L21.5384615,3.07692308 Z M3.07692308,18.4615385 C1.53846154,18.4615385 1.53846154,16.9230769 1.53846154,16.9230769 L1.53846154,1.53846154 L20,1.53846154 L20,16.9230769 C20,17.6315385 20.2184615,18.1223077 20.52,18.4615385 L3.07692308,18.4615385 Z" />
                <rect
                  id="Rectangle-path"
                  x="3.07692308"
                  y="4.61538462"
                  width="15.3846154"
                  height="1.53846154"
                />
                <rect
                  id="Rectangle-path"
                  x="11.5384615"
                  y="13.8461538"
                  width="5.38461538"
                  height="1.53846154"
                />
                <rect
                  id="Rectangle-path"
                  x="11.5384615"
                  y="10.7692308"
                  width="6.92307692"
                  height="1.53846154"
                />
                <rect
                  id="Rectangle-path"
                  x="11.5384615"
                  y="7.69230769"
                  width="6.92307692"
                  height="1.53846154"
                />
                <rect
                  id="Rectangle-path"
                  x="3.07692308"
                  y="7.69230769"
                  width="6.92307692"
                  height="7.69230769"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Newspaper;
