import React from 'react';
import Check from './check';
import { Link } from 'react-router-dom';
import { ItemContainer } from './style';
import { Text } from 'shared/layout/text';
import { Flex } from 'shared/layout';
import filterNames from '../../filter';
import { ctaGreen, black } from 'base/colors';
import { mergeQuery } from 'shared/query-string';

export default ({ section, category, type, slug, collections }) => {
  let { name, value, isFirst, query, active } = filterNames(section, category);

  return (
    <ItemContainer>
      <Link
        to={`${
          collections ? window.location.pathname : `/${type}/search`
        }${mergeQuery({
          [name]: value,
          slug: slug || query.slug,
          first: isFirst ? section.name.toLowerCase() : query.first,
          from: 0
        })}`}
        onClick={() => (collections ? null : window.scrollTo(0, 0))}
      >
        <Flex alignItemsCenter>
          <Check checked={active} />
          <Text
            size={category.name.length > 22 ? 12 : 13}
            color={active ? ctaGreen : black}
          >
            {category.name}
          </Text>
          <Text
            size={10}
            style={{
              alignSelf: 'center',
              paddingLeft: '5px',
              color: black,
            }}
          >
            ({category.count})
          </Text>
        </Flex>
      </Link>
    </ItemContainer>
  );
};
