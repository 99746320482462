import qs from "qs";
import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
export const query = gql`
  query SearchSidebar($input: searchSidebarInput!) {
    searchSidebar(input: $input) {
      categories {
        name
        children {
          name
          slug
          children {
            name
            slug
          }
        }
      }

      filters {
        name
        children {
          name
          taxonomy
          count
          term_taxonomy_id
        }
      }
    }
  }
`;
export default graphql(query, {
  options: ({ type, location, slug }) => {
    let query = qs.parse(location.search.substring(1));
    let terms = Object.keys(query);

    let or = [];
    let and = [];

    terms
      .filter(
        term =>
          term !== "sort" &&
          term !== "first" &&
          term !== "query" &&
          term !== "size" &&
          term !== "from" &&
          term !== "slug"
      )
      .forEach(term =>
        term.match(query.first)
          ? or.push(...query[term])
          : and.push(...query[term])
      );

    return {
      variables: {
        input: {
          context: type,
          or: or.map(Number),
          and: and.map(Number),
          slug: slug || query.slug
        }
      },
      fetchPolicy: "cache-and-network"
    };
  }
});
