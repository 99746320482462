const polyfill = {
  data: {},
  getItem(key) {
    return this.data[key];
  },
  setItem(key, value) {
    this.data[key] = value;
  },
  removeItem(key) {
    delete this.data[key];
  },
  clear() {
    this.data = {};
  }
};

var storage = polyfill;

try {
  localStorage.setItem("test", true);
  localStorage.removeItem("test");
  storage = localStorage;
} catch (e) {
  try {
    sessionStorage.setItem("test", true);
    sessionStorage.removeItem("test");
    storage = sessionStorage;
  } catch (e) {
    storage = polyfill;
  }
}

export default {
  removeItem(key){
    try {
      return storage.removeItem(key);
    } catch (e) {
      return e;
    }
  },

  get(key, defaultValue) {
    try {
      return JSON.parse(storage.getItem(key)) || defaultValue;
    } catch (e) {
      return storage.getItem(key) || defaultValue;
    }
  },
  set(key, value) {
    return storage.setItem(
      key,
      typeof value === "object" ? JSON.stringify(value) : value
    );
  },
  clear() {
    let cartId = storage.getItem("cartId") || "";
    let authLink = storage.getItem("auth-link") || "";
    let viewedHome = storage.getItem("viewed-home") || "";
    let viewedBoards = storage.getItem("viewed-boards") || "";

    storage.clear();

    storage.setItem("cartId", cartId);
    storage.setItem("viewed-home", viewedHome);
    storage.setItem("viewed-boards", viewedBoards);
    storage.setItem("auth-link", authLink);
  },
  clearUser() {
    storage.setItem("user", "");
    storage.setItem("VbProject", "");
    storage.setItem("token", "");
    storage.setItem("apollo-cache-persist", "");
  }
};
