import React from 'react';
import { Table, Row, Heading, Data } from './style';

export default ({ data }) => (
  <Table>
    {data.map(item => (
      <Row key={item.key}>
        <Heading>{item.key}</Heading>
        <Data>{item.value}</Data>
      </Row>
    ))}
  </Table>
);
