import React, { Component } from "react";
import { Container, Title, Heading, Wrapper } from "./style";

class Sidebar extends Component {
  render() {
    return (
      <Container>
        <Title>Contact Us</Title>
        <Wrapper>
          <Heading>Corporate Office</Heading>
          <span>ASI | Architectural Systems, Inc.</span>
          <span>256 W. 36th Street</span>
          <span>10th Floor</span>
          <span>NY, NY 10018</span>
          <span>Toll-Free: 800.793.0224</span>
          <span>New York: 212.206.1730</span>
          <span>
            Email:{" "}
            <a href="@mailto:sales@archsystems.com">sales@archsystems.com</a>
          </span>
        </Wrapper>
      </Container>
    );
  }
}
export default Sidebar;
