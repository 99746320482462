/* globals analytics */

export default (name, data) =>
  new Promise(resolve => {
    if (typeof analytics === 'undefined') return resolve();

    analytics.track(name, data, resolve);

    if (data.id) return analytics.identify(data.id, { email: data.email });

    if (data.email) analytics.identify({ email: data.email });
  });
