import React from 'react';
import { Box, Container } from '../style';
import Arrow from 'icons/arrow';
import { Link } from 'react-router-dom';
import { css } from 'react-emotion';

const subtitleFormat =(subtitleArray) => {
  //removing  Builders Groups - 
  let BuilderGroupRegex = /Builders Group -?(.*)/
  return subtitleArray.map(subtitle => subtitle.replace(BuilderGroupRegex,"Builders Group"));
}

export default ({ item }) => (
  <Link
    to={`/collections/${item.slug}`}
    style={{
      backgroundImage: `url(${item.image ? item.image.full : null})`
    }}
    className={css`
      ${Container};
    `}
  >
    <Box key={item.id}>
      <h3>{item.title}</h3>
      {item.subtitle ? 
        subtitleFormat(item.subtitle.split(",")).map((element,index) => 
          (<h6 key={element+"_"+index}>{element}</h6>)
          )
      : null}
      <span>
        discover collection <Arrow />
      </span>
    </Box>
  </Link>
);
