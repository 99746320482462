import React from "react";
import Heading from "./heading";
import { ItemContainer } from "./style";
import TextItem from "./items/text";
import CheckboxItem from "./items/checkbox";

export default class Filter extends React.Component {
  constructor() {
    super();
    this.state = {
      show: true
    };
  }

  componentDidMount() {
    const headingContainer = document.querySelector("#filterContainer h4");
    let headingContainerHeight = headingContainer.offsetHeight + 20;
    this.setState({ headingContainerHeight });
  }

  toggleFilter(show) {
    this.setState({ show });
  }

  render() {
    let { section, checkbox, type, slug, collections } = this.props;
    let { show } = this.state;
    return (
      <ItemContainer
        id="filterContainer"
        style={{
          height: show ? "auto" : this.state.headingContainerHeight
        }}
        show={show}
      >
        <Heading closed={show} toggleFilter={() => this.toggleFilter(!show)}>
          {type == "products" && !collections
            ? "All Materials"
            : `Filter by ${" "} ${section.name.charAt(0).toUpperCase() +
                section.name.slice(1)}`}
        </Heading>
        {section.children.map((category, index) =>
          React.createElement(checkbox ? CheckboxItem : TextItem, {
              key: index,
              category,
              type,
              section,
              slug,
              collections
          })
        )}
      </ItemContainer>
    );
  }
}
