import React from "react";
import styled, { css } from "react-emotion";
import * as Colors from "base/colors";
import {
  EncodeSans,
  Tablet,
  Montserrat,
  Siliguri
} from "shared/layout/base/base";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  position: relative;

  @media (max-width: 1120px) {
    padding: 0 20px;
  }

  @media (${Tablet}) {
    flex-direction: row;
    margin: 80px 0;
  }

  .Select-control {
    height: 50px;
    background-color: #fafafa;
    border-color: #e0dfdf !important;

    .Select-arrow-zone {
      padding-right: 20px;
    }
  }

  input:not([type="checkbox"]) {
    border-radius: 3px;
    border: solid 1px #e0dfdf !important;
    height: 50px;
  }

  .Select-input {
    height: 40px;

    input {
      height: 20px;
      border: none !important;
      width: 100%;
      background-color: transparent;
      line-height: 1;
    }
  }

  .Select-placeholder {
    display: flex;
    align-items: center;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 300;
    padding-left: 10px;
    color: ${Colors.titleGrey};
  }
`;

export const HeadingWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled("h3")`
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
  margin-top: 40px;

  @media (${Tablet}) {
    font-size: 35px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &:after {
    width: 40px;
    height: 2px;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${Colors.brandHighLightPurple};

    @media (${Tablet}) {
      width: 80px;
    }
  }
`;

export const Label = styled("div")`
  font-family: ${Siliguri};
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.titleGrey};
  margin-bottom: 15px;

  @media (${Tablet}) {
    font-size: 18px;
  }
`;

export const SubLabel = styled("div")`
  color: ${Colors.titleGrey};
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  line-height: 1.69;
  margin-bottom: 10px;
`;

export const SubText = styled("div")`
  font-family: ${EncodeSans};
  font-size: 13px;
  line-height: 1.69;
  margin-bottom: 10px;
  color: ${Colors.lightTextGrey};
`;

export const Required = styled("span")`
  font-family: ${Siliguri};
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.lightTextGrey};
  margin-top: 20px;
  display: block;
`;

export const Section = styled(({ loading, ...props }) => <div {...props} />)`
  display: block;
  padding: 15px 0;
  border-bottom: solid 1px #d8d8d8;
  transition: opacity 0.5s;
  opacity: ${props => (props.loading ? 0.5 : 1)};

  @media (${Tablet}) {
    padding: 30px 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const FieldWrapper = styled(({ twoColumn, maxWidth, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: column;

  @media (${Tablet}) {
    flex-direction: row;
    max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};
  }

  input {
    flex-grow: 1;
  }

  div {
    &:first-child {
      margin-right: ${props => (props.twoColumn ? "20px" : "0")};
    }
  }
`;

export const AddButton = styled("button")`
  display: flex;
  align-items: center;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  color: ${Colors.ctaGreen};
  margin-bottom: 20px;
  cursor: pointer;
  width: 195px;

  &:focus {
    outline: none;
  }

  @media (${Tablet}) {
    margin-top: 20px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

export const List = styled(({ marginTop, ...props }) => <ul {...props} />)`
  padding: 0;
  list-style: none;
  ${props => (props.marginTop ? "margin-top: 20px;" : "margin-top: 0;")};

  li {
    display: flex;
    margin-bottom: 15px;

    div {
      align-items: center;
      padding: 0;

      input[type="radio"] {
        border-radius: 50%;
        height: 20px;

        &:checked {
          border-color: ${Colors.ctaGreen};
          background-color: ${Colors.white};

          &:after {
            border-color: ${Colors.ctaGreen};
          }
        }
      }
    }

    label {
      margin-bottom: 0;

      p {
        font-family: ${EncodeSans};
        font-size: 13px;
        font-weight: 500;

        svg {
          width: 70px;
          height: 50px;
          display: block;
        }
      }
    }

    input:not([type="checkbox"]) {
      border-radius: 3px;
      background-color: #fafafa;
      border: solid 1px #e0dfdf !important;
      height: 50px;
    }
  }
`;

export const OrderInfo = styled("div")`
  display: flex;
  flex-direction: column;

  @media (min-width: 1119px) {
    flex-direction: row;
  }
`;

export const InfoContainer = styled("div")`
  width: 100%;

  @media print {
    display: none;
  }

  @media (min-width: 1119px) {
    width: 33.33%;
    padding-right: 20px;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const Title = styled("div")`
  display: block;
  font-family: ${Siliguri};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
  color: ${Colors.titleGrey};

  @media (min-width: 1119px) {
    margin-top: 0;
  }
`;

export const ProjectInfo = styled("div")`
  p {
    margin: 0;
    font-weight: 400;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    font-style: normal;
    margin: 0 0 5px 0;
  }

  a {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    text-decoration: underline;
    color: ${Colors.ctaGreen};
  }
`;

export const Info = styled("div")`
  display: flex;
  font-family: ${EncodeSans};
  font-size: 13px;
  margin-bottom: 15px;

  span {
    font-weight: 600;
    margin-right: 10px;
    line-height: 1.54;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    font-style: normal;
  }

  address {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    font-style: normal;
    white-space: pre-wrap;
  }

  a {
    text-decoration: underline;
    color: ${Colors.ctaGreen};
  }
`;

export const Block = styled("div")`
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0 5px 0;
    padding: 0;
    border: none;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    font-style: normal;
  }

  a {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    text-decoration: underline;
    color: ${Colors.ctaGreen};
  }
`;

export const CardInfo = styled("div")`
  display: flex;
  margin-top: 20px;

  img {
    width: 34px;
    height: 23px;
    display: block;
    margin-right: 12px;
  }

  p {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    margin: 0 10px 0 0;
    display: block;
  }

  a {
    font-family: ${EncodeSans};
    font-size: 13px;
    line-height: 1.54;
    text-decoration: underline;
    color: ${Colors.ctaGreen};
  }
`;

export const OrderTable = styled("div")`
  display: block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #e6e6e6;
  margin-top: 40px;
`;

export const Table = styled(({ loading, ...props }) => <div {...props} />)`
  display: block;
  padding: 20px;
  opacity: ${props => (props.loading ? "0.5" : "1")};
  transition: opacity 0.25s ease-in-out;
`;

export const TableTitle = styled("p")`
  font-family: ${Siliguri};
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
`;

export const TableHeadings = styled("div")`
  display: flex;
  border-bottom: 1px solid ${Colors.borderColor};
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 20px;

  h4 {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;

    &:first-child {
      flex-grow: 1;

      @media (min-width: 1119px) {
        width: 100%;
        flex-grow: unset;
      }
    }

    &:last-child {
      @media (min-width: 1119px) {
        width: auto;
      }
    }
  }
`;

export const TableRow = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &:first-child {
    background: white;
    margin-top: 0;

    h4 {
      @media (min-width: 1119px) {
        color: black;
        border-color: ${Colors.borderColor};
      }
    }
  }

  h4 {
    @media (min-width: 1119px) {
      color: transparent;
    }
  }

  @media (min-width: 1119px) {
    flex-direction: row;
  }
`;

export const ShippingDetails = styled("div")`
  display: flex;
  margin-bottom: 20px;
  width: 100%;

  @media (${Tablet}) {
    margin-right: 30px;
    width: 296px;
  }

  img {
    width: 112px;
    height: 110px;
    display: block;
    padding: 5px;
    margin-right: 20px;
    border: 1px solid #e0dfdf;
  }
`;

export const Quantity = styled("span")`
  display: block;

  @media (min-width: 1119px) {
    padding-right: 25px;
  }

  div {
    padding: 0;
    width: 60px;

    @media (min-width: 1119px) {
      width: 70px;
    }
  }

  input {
    border-radius: 3px;
    background-color: #fafafa;
    border: solid 1px #e0dfdf;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

export const RemoveQTY = styled("button")`
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  color: ${Colors.ctaGreen};
  cursor: pointer;
`;

export const ShippingInfo = styled("div")`
  display: flex;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 1119px) {
    width: 50%;
    margin-top: 0;
  }
`;

export const Shipping = styled("div")`
  display: flex;
  align-items: flex-start;
`;

export const ShippingMethodWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  @media (min-width: 1119px) {
    width: 60%;
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: 24px;
    width: 100%;
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;

    @media (min-width: 1119px) {
      padding-bottom: 30px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
`;

export const ShippingAddress = styled("div")`
  width: 100%;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  display: flex;
  align-items: flex-start;
  padding-right: 20px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`;

export const AddLink = css`
  color: ${Colors.ctaGreen};

  &:hover {
    text-decoration: underline;
  }
`;

export const SalesKitWrapper = styled("div")`
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TermsAgreementWrapper = styled("div")`
  display: flex;
  align-items: center;

  div:first-child {
    width: auto;
  }

  p {
    margin: 0;
  }

  a {
    color: #61a60d;
  }
`;
