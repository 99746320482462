import React from "react";
import { SubNav } from "../style";
import BasketIcon from "icons/basket";
import styled, { css } from "react-emotion";
import * as Colors from "base/colors";
import ProjectStudioIcon from "icons/project-studio";

export const Basket = () => (
  <BasketIcon
    className={css`
      fill: ${Colors.ctaGreen};
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 23px;
    `}
  />
);

export const ProjectStudio = () => (
  <ProjectStudioIcon
    className={css`
      fill: ${Colors.ctaGreen};
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
    `}
  />
);

export const ProjectStudioSubNav = styled(SubNav)`
  column-count: unset;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  ul {
    width: 50%;

    @media (min-width: 900px) {
      width: 33.33%;
    }

    @media (min-width: 1020px) {
      width: 25%;
    }
  }

  span {
    color: #333;
  }
`;

export const Image = styled("div")`
  width: 100%;
  height: 130px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
`;
