import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
export const query = gql`
  query Social {
    socialPosts {
      pinterest {
        id
        image
        caption
        link
      }
      insta {
        id
        image
        caption
        link

        likes {
          count
        }
        comments {
          count
        }
      }
    }
  }
`;
export default graphql(query);
