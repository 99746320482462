import styled from "react-emotion";
import * as Colors from "base/colors";
import { EncodeSans } from "shared/layout/base/base";

export const SampleRequest = styled("div")`
  display: flex;
  flex-wrap: wrap;

  @media print {
    display: none;
  }

  .Select-control {
    height: 50px;
  }

  .Select-placeholder {
    left: 0;
  }

  @media (min-width: 930px) {
    flex-wrap: nowrap;
  }

  > div:first-child {
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: calc(100% - 70px);
    margin-right: auto;

    @media (min-width: 930px) {
      margin-right: 10px;
      margin-bottom: 0;
      min-width: 0;
    }

    .Select-menu-outer {
      background-color: ${Colors.white};
    }
  }
`;

export const SampleTypeDropdown = styled("div")`
  .Select-control {
    width: 100%;
    height: 50px;
    border: 1px solid ${Colors.borderColor};

    @media (min-width: 930px) {
      width: 150px;
    }

    .Select-placeholder {
      display: flex;
      align-items: center;
      left: 0;
    }

    input {
      line-height: 27px;
      padding: 0;

      @media (min-width: 930px) {
        line-height: 34px;
      }

      &:focus {
        outline: none;
      }
    }

    .Select-input {
      height: 26px;

      @media (min-width: 930px) {
        height: 30px;
      }
    }

    .Select-value {
      left: 0;
    }
  }
`;

export const Qty = styled("div")`
  height: 50px;
  width: 60px;

  @media (min-width: 930px) {
    height: 50px;
    width: 70px;
    margin-right: 10px;
  }

  > div {
    padding: 0 !important;
  }

  > input {
    font-family: ${EncodeSans};
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    border: solid 1px #e0dfdf !important;
    padding: 5px;
    display: flex;
    align-items: center;
    height: 40px;

    @media (min-width: 930px) {
      height: 50px;
      font-size: 13px;
      padding: 10px;
    }
  }
`;
