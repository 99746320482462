import qs from "qs";
import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";
export const query = gql`
  query Quote($id: Int!) {
    quote(id: $id) {
      products {
        sku
        qty
        id
      }
      Name
      OpportunityId
      file_url
      file
      hasSpecialPricing
      spartan_quote_number
      notes
      job_name

      shipping_id
      billing_id
      BillingName
      ShippingName
      Use_Custom_Address__c
      Delivery_Requirements
      shipping {
        fullAddress
      }
      LOC__c
      sales_rep
      region
    }
  }
`;
export default graphql(query, {
  skip: ({ location = window.location }) => {
    let item = qs.parse(location.search.substring(1));
    if (item.quoteId) return false;

    return true;
  },
  options: ({ location }) => {
    let item = qs.parse(location.search.substring(1));

    return {
      variables: { id: parseInt(item.quoteId, 10) }
    };
  }
});
