import React from 'react';
import {
  CommunitySubNavTabContainer,
  CommunitySubNavTabList,
  CommunitySubNavTabTitle,
} from './style';
import socialLoader from 'shared/social/loader';

export default socialLoader(
  ({ active, data, activateInsta, activatePinterest }) => {
    if (data) data = data.slice(0, 4);

    return (
      <CommunitySubNavTabContainer>
        <CommunitySubNavTabTitle
          active={active === 'insta'}
          onClick={activateInsta}
        >
          #ASIMaterials
        </CommunitySubNavTabTitle>
        <CommunitySubNavTabTitle
          active={active === 'pinterest'}
          onClick={activatePinterest}
        >
          ASI Pinterest
        </CommunitySubNavTabTitle>
        <CommunitySubNavTabList>
          {data
            ? data.map(data => (
                <li key={data.id}>
                  <a
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundImage: `url(${data.image})`,
                    }}
                  >
                    {data.caption}
                  </a>
                </li>
              ))
            : null}
        </CommunitySubNavTabList>
      </CommunitySubNavTabContainer>
    );
  },
);
