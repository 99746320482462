import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans, Montserrat, Tablet } from 'shared/layout/base/base';

export const Container = styled('div')`
  display: block;
  width: 100%;

  @media (${Tablet}) {
    .Select-control {
      background-color: #fafafa;
    }
  }
`;

export const Heading = styled('h1')`
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;
  color: ${Colors.titleGrey};
  margin-bottom: 20px;

  @media (${Tablet}) {
    margin-bottom: 0;
    font-size: 35px;
  }

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    margin-top: 6px;
    background-color: ${Colors.brandPurple};

    @media (${Tablet}) {
      margin-top: 10px;
      width: 80px;
    }
  }
`;

export const HeadingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (${Tablet}) {
    margin-bottom: 40px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const Wrapper = styled('div')`
  @media (max-width: 980px) {
    padding-left: 20px;
  }

  @media (max-width: 1119px) {
    padding-right: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const DropdownWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${Colors.dropdownGrey};
  border: solid 1px #d6d6d6;
  padding: 20px;

  > div {
    max-width: 260px;
    width: 100%;
  }

  .Select-control {
    input {
      line-height: 36px;
      padding: 0;
    }
  }

  @media (${Tablet}) {
    justify-content: flex-end;
    width: 50%;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  @media (max-width: 1119px) {
    padding-right: 20px;
  }
`;

export const Label = styled('span')`
  display: block;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  margin-right: 20px;
`;

export const Button = styled('button')`
  min-width: 127px;
  padding: 10px;
  font-family: ${EncodeSans};
  font-size: 13px;
  color: ${Colors.white};
  background-color: ${Colors.ctaGreen};
  border: 2px solid ${Colors.ctaGreen};
  transition: background-color 0.25s, color 0.25s;
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;

  @media (${Tablet}) {
    padding: 14px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${Colors.white};
    color: ${Colors.ctaGreen};

    svg {
      fill: ${Colors.ctaGreen};
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${Colors.white};
    display: inline-block;
    vertical-align: middle;
    transition: fill 0.25s;
    margin-right: 5px;
  }
`;
