import React from 'react';
import {
  ParagraphContainer,
  ListContainer,
  HeadingContainer,
  ImageContainer,
} from './style';

export default async (block, index, extraProps = {}, wrappers = {}) => {
  let children;

  try {
    let component = await import(`shared/blocks/${block.name}`);
    children = React.createElement(component.default, {
      ...extraProps,
      ...block.attrs,
    });
  } catch (e) {
    children = (
      <div key={index} dangerouslySetInnerHTML={{ __html: block.innerHtml }} />
    );
  }

  let wrapper = React.Fragment;

  if (block.name === 'core/paragraph') wrapper = ParagraphContainer;
  if (block.name === 'core/list') wrapper = ListContainer;
  if (block.name === 'core/heading') wrapper = HeadingContainer;
  if (block.name === 'core/image') wrapper = ImageContainer;
  if (wrappers[block.name]) wrapper = wrappers[block.name];

  return React.createElement(wrapper, { children, key: index });
};
