import styled from 'react-emotion';
import * as Colors from 'base/colors';
import {
  EncodeSans,
  Montserrat,
  Tablet,
  SiteContainer,
  Siliguri,
} from 'shared/layout/base/base';

export const ParagraphContainer = styled(SiteContainer)`
  @media (max-width: 1119px) {
    padding: 0 20px;
  }

  p {
    display: block;
    font-family: ${EncodeSans};
    font-size: 15px;
    line-height: 2.23;
    color: ${Colors.titleGrey};
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: ${Colors.ctaGreen};
    }
  }
`;

export const HeadingContainer = styled(SiteContainer)`
  @media (max-width: 1119px) {
    padding: 0 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${Colors.titleGrey};
    margin-bottom: 20px;
  }

  h1 {
    font-size: 20px;
    line-height: 1.3;
    font-family: ${Montserrat};
    font-weight: 300;

    @media (${Tablet}) {
      font-size: 35px;
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 1.3;
    font-family: ${Montserrat};
    font-weight: 300;

    @media (${Tablet}) {
      font-size: 30px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 1.5;
    font-family: ${Siliguri};
    font-weight: 400;

    @media (${Tablet}) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 1.6;
    font-family: ${Siliguri};
    font-weight: 400;

    @media (${Tablet}) {
      font-size: 18px;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 1.7;
    font-family: ${Siliguri};
    font-weight: 600;

    @media (${Tablet}) {
      font-size: 16px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 1.7;

    @media (${Tablet}) {
      font-size: 14px;
    }
  }
`;

export const ListContainer = styled(SiteContainer)`
  @media (max-width: 1119px) {
    padding: 0 20px;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      line-height: 1.6;
      font-size: 16px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${Colors.brandHighLightPurple};
      }
    }
  }

  ol {
    padding: 0;
    list-style: none;
    counter-reset: Asicounter;

    li {
      position: relative;
      margin-bottom: 10px;
      line-height: 1.6;
      padding-left: 30px;

      &:before {
        content: counter(Asicounter);
        counter-increment: Asicounter;
        font-size: 16px;
        color: ${Colors.brandHighLightPurple};
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
`;

export const ImageContainer = styled(SiteContainer)`
  @media (max-width: 1119px) {
    padding: 0 20px;
  }
`;
