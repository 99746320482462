import React from 'react';

export default ({ style, color, width, height }) => (
  <svg width={width || 9} height={height || 10} viewBox="0 0 9 7" style={style}>
    <title>Shape</title>
    <path
      d="M8.828.966l-.79-.788a.537.537 0 0 0-.393-.163.537.537 0 0 0-.395.163l-3.805 3.81-1.705-1.71a.537.537 0 0 0-.394-.163.537.537 0 0 0-.395.162l-.789.79A.537.537 0 0 0 0 3.46c0 .154.054.286.162.394l2.1 2.1.789.788c.108.109.24.163.394.163a.537.537 0 0 0 .395-.163l.788-.789 4.2-4.199a.537.537 0 0 0 .162-.394.537.537 0 0 0-.162-.395z"
      fill={color || '#FFF'}
      fillRule="nonzero"
    />
  </svg>
);
