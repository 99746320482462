import React, { Component } from 'react';
import {
  Container,
  Collection,
  Wrapper,
  Label,
  Dimensions,
  RequestSamples,
  Title,
} from './style';
import Content from 'shared/modals/section/material';
import ArrowLink from 'shared/layout/arrow-link';
import Table from './dimensions';
import Samples from 'views/product/sections/header/add-sample';
import Variations from 'views/product/sections/product/container/variations';
import Line from 'shared/layout/line';
import productQuery from './gql/product';

class Quickview extends Component {
  render() {
    let { data = {} } = this.props;
    if (!data.product)
      data.product = {
        collection: {},
        variations: [],
        samples: [],
        dimensions: [],
      };

    return (
      <Container>
        <Content data={data.product} Quickview>
          <Wrapper>
            <Collection>{data.product.collection.name}</Collection>
            <Title>{data.product.name}</Title>
            <Line style={{ marginTop: '20px', marginBottom: '10px' }} />
            <ArrowLink
              to={`/product/${data.product.sku}`}
              text="VIEW FULL PRODUCT DETAILS"
            />
            <Variations
              variations={data.product.variations.filter(
                variation => variation.type === 'color',
              )}
              currentSku={data.product.sku}
              noPadding
            />
            {data.product.samples ? (
              <RequestSamples>
                <Samples product={data.product} />
              </RequestSamples>
            ) : null}
            {data.product.dimensions.length ? (
              <Dimensions>
                <Label>Dimensions, Material & Installation</Label>
                <Table data={data.product.dimensions} />
                <ArrowLink
                  to={`/product/${data.product.sku}`}
                  text="VIEW FULL PRODUCT SPECIFICATIONS"
                />
              </Dimensions>
            ) : null}
          </Wrapper>
        </Content>
      </Container>
    );
  }
}

export default productQuery(Quickview);
