import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Siliguri, Tablet } from 'shared/layout/base/base';

export const FooterWrapper = styled('div')`
  display: block;
  background: #fafafa;
  margin-top: 40px;

  @media (${Tablet}) {
    margin-top: 80px;
  }

  @media print {
    display: none;
  }
`;

export const Address = styled('div')`
  padding: 10px 20px;
  border-top: 1px solid #eee;
  text-align: center;
  font-family: ${Siliguri};
  font-size: 13px;
  font-weight: 500;
  color: ${Colors.lightTextGrey};
  line-height: 2.13;

  @media (${Tablet}) {
    font-size: 15px;
  }
`;

export const ScrollToTopButton = styled('button')`
  padding: 10px;
  background-color: #fff;
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 20px;
  border: solid 1px #cccccc;
  z-index: 9999999;
  transition: background-color 0.25s;

  svg {
    transform: rotate(-90deg);
    fill: ${Colors.ctaGreen};
    width: 20px;
    height: 20px;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: ${Colors.ctaGreen};

      svg {
        fill: #fff;
        transition: fill 0.25s;
      }
    }
  }
`;
