import styled from 'react-emotion';

export const Content = styled('div')`
  width: 100%;

  @media (min-width: 768px) {
    max-width: 833px;
    width: 100%;
  }
`;

export const Container = styled('div')`
  margin: 0 0 40px 0;

  @media (min-width: 767px) {
    margin: 80px 0;
  }
`;

export const Wrapper = styled('div')`
  flex-direction: column;

  @media (min-width: 767px) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
`;
