import React from "react";
import { Link } from "react-router-dom";
import {
  ItemDetails,
  Details,
  Name,
  SubTitle,
  ID,
  Type,
  Description
} from "../style";
import { AlertNotification } from "shared/layout/base/base";

export default ({ sample, showBackorder, showQuantity }) => (
  <ItemDetails>
    <img src={sample.image ? sample.image.thumbnail : null} alt="text" />
    <Details>
      <Name>{sample.name}</Name>
      {showQuantity ? <SubTitle>Quantity: {sample.qty}</SubTitle> : null}
      <ID>Spec ID# {sample.sku}</ID>
      <Type>{sample.type ? `Sample Type: ${sample.type}` : null}</Type>
      <Description>{sample.description}</Description>
      {showBackorder ? (
        sample.stock ? null : (
          <AlertNotification>
            Item is Backordered.{" "}
            <Link to={`/product/${sample.sku}`}>Find Alternative?</Link>
          </AlertNotification>
        )
      ) : null}
    </Details>
  </ItemDetails>
);
