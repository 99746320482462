import React from 'react';
import Products from './products';
import { ProductsContainer } from './style';

export default ({
  match,
  location,
  collectionsPage,
  collection,
  type,
  ...props
}) => {
    if(!collectionsPage) {
        props.must = [
        {
          name: "meta.woo_product_show_list.value",
          value: "yes"
        }
      ].filter(Boolean)
    }
return (
  <ProductsContainer>
    <Products
      match={match}
      type={type}
      location={location}
      {...props}
      collectionsPage={collectionsPage}
      collection={collection}
    />
  </ProductsContainer>
)};
