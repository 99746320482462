import React from "react";
import Mobile from "./mobile";
import Standard from "./standard";
import sidebarQuery from "./queries/get";
import RenderSize from "shared/render-size";

const SidebarRenderer = props => (
  <RenderSize
    mobile={Mobile}
    desktop={Standard}
    props={{ ...props }}
    page="main"
  />
);

export default sidebarQuery(SidebarRenderer);
