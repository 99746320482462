export const regions = [
  { name: "Alabama", value: "alabama" },
  { name: "Austin", value: "austin" },
  { name: "Chicago", value: "chicago_il" },
  { name: "Connecticut", value: "connecticut" },
  { name: "Dallas", value: "dallas" },
  { name: "DC Metro", value: "dc_metro" },
  { name: "Delaware", value: "delaware" },
  { name: "East PA + Southern NJ", value: "east_pa_southern_nj" },
  { name: "Florida", value: "florida" },
  { name: "Georgia", value: "georgia" },
  { name: "Hospitality", value: "hospitality" },
  { name: "Illinois + Iowa", value: "illinois_iowa" },
  { name: "Indiana", value: "indiana" },
  { name: "Kairos", value: "kairos" },
  { name: "Kansas", value: "kansas" },
  { name: "Kentucky", value: "kentucky" },
  { name: "Louisiana + Mississippi", value: "louisiana_mississippi" },
  { name: "Maryland", value: "maryland" },
  { name: "Michigan + NW Ohio", value: "michigan_nw_ohio" },
  { name: "Mid Atlantic", value: "mid_atlantic" },
  { name: "Minnesota", value: "minnesota" },
  { name: "Missouri", value: "missouri" },
  { name: "North + South Carolina", value: "north_south_carolina" },
  { name: "North + South Dakota", value: "north_south_dakota" },
  { name: "Northern New Jersey", value: "northern_new_jersey" },
  { name: "NY Metro - Excluding NYC", value: "ny_metro_excluding_nyc" },
  { name: "Pennsylvania", value: "pennsylvania" },
  { name: "Tennessee", value: "tennessee" },
  { name: "Upstate + Western NY", value: "upstate_western_ny" },
  { name: "Vermont", value: "vermont" },
  { name: "Virginia", value: "virginia" },
  { name: "West Virginia", value: "west_virginia" },
  { name: "Wisconsin", value: "wisconsin" }
];
