import { graphql } from 'react-apollo';
import mutator from 'shared/gql/mutate';
import { query as shippingQuery } from '../queries/shipping';
import address from '../queries/address';

import gql from 'graphql-tag.macro';
export const query = gql`
  mutation CreateUserAddress($input: CreateAddressInput!) {
    createUserAddress(input: $input) {
      ...address
    }
  }
  ${address}
`;
export default graphql(query, {
  props: ({ mutate, ownProps }) => ({
    create: input =>
      mutator(() => {
        let isShipping = ownProps.type === 'shipping';

        return mutate({
          variables: {
            input: { ...input, type: isShipping ? 'SHIPPING' : 'BILLING' },
          },
          update: (store, { data: { createUserAddress } }) => {
            const data = store.readQuery({ query: shippingQuery });

            if (createUserAddress.isDefault)
              data.user[ownProps.type] = [
                createUserAddress,
                ...data.user[ownProps.type],
              ];
            else data.user[ownProps.type].push(createUserAddress);

            store.writeQuery({ query: shippingQuery, data });
          },
        });
      }),
  }),
});
