import styled from "react-emotion";
import * as Colors from "base/colors";
import { EncodeSans } from "shared/layout/base/base";

export const Container = styled("div")`
  display: block;
`;

export const SampleRequest = styled("div")`
  display: flex;
  flex-wrap: wrap;

  .Select-control {
    height: 50px;
  }

  .Select-placeholder {
    left: 0;
  }

  @media print {
    display: none;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }

  > div:first-child {
    margin-right: 10px;
    margin-bottom: 10px;
    flex-grow: 1;

    @media (min-width: 768px) {
      margin-right: 10px;
      margin-bottom: 0;
      flex-grow: 1;
    }

    .Select-menu-outer {
      background-color: ${Colors.white};
    }
  }
`;

export const SampleTypeDropdown = styled("div")`
  .Select {
    width: 100%;
  }

  .Select-control {
    width: 100%;
    height: 50px;
    border: 1px solid ${Colors.borderColor};

    @media (min-width: 768px) {
      width: 100%;
      height: 50px;
    }

    .Select-placeholder {
      display: flex;
      align-items: center;
    }

    input {
      line-height: 27px;
      padding: 0;

      @media (min-width: 930px) {
        line-height: 34px;
      }

      &:focus {
        outline: none;
      }
    }

    .Select-input {
      height: 26px;

      @media (min-width: 768px) {
        height: 30px;
      }
    }
  }
`;

export const Qty = styled("div")`
  height: 50px;
  width: 60px;

  @media (min-width: 768px) {
    margin-right: 10px;
  }

  > div {
    padding: 0 !important;
  }

  input {
    font-family: ${EncodeSans};
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    border: solid 1px #e0dfdf !important;
    padding: 5px;
    display: flex;
    align-items: center;
    height: 40px;

    @media (min-width: 768px) {
      height: 50px;
      font-size: 13px;
      padding: 10px;
    }
  }
`;

export const Title = styled("span")`
  font-family: ${EncodeSans};
  font-size: 22px;
  line-height: 1.05;
  color: ${Colors.titleGrey};
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.borderColor};
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    width: calc(100% - 261px);
  }
`;

export const SubTitle = styled("span")`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.77;
  color: ${Colors.titleGrey};
  padding-bottom: 20px;
  display: block;
`;

export const Option = styled("div")`
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  padding: 5px 25px;
  display: flex;
  align-items: center;

  img {
    width: 43px;
    height: 37px;
    display: block;
    margin-right: 15px;
  }

  span {
    &:first-of-type {
      margin-right: 20px;
    }
  }
`;
