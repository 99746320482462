import gql from 'graphql-tag.macro';
export const query = gql`
  fragment address on Address {
    id
    isDefault

    first_name
    last_name

    line_2

    phone
    email
    fullAddress
    placeid
    company
  }
`;
export default query;
