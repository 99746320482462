import React from 'react';
import { Container, DropdownTitle } from '../style';
import { Link } from 'react-router-dom';
import { ColorButton, ColorWrapper } from './style';

export default ({ data = [], title, noPadding, currentSku }) => {
  if (!data.length) return null;

  return (
    <Container noPadding={noPadding}>
      {title ? <DropdownTitle>{title}</DropdownTitle> : null}
      <ColorWrapper>
        {data.map((item, index) => (
          <Link key={item.id} to={`/product/${item.sku.toLowerCase()}`}>
            <ColorButton first={currentSku === item.sku}>
              <img
                src={
                  item.image
                    ? item.image.thumbnail
                      ? item.image.thumbnail
                      : item.image.shop_thumbnail
                    : ''
                }
                alt={item.sku}
              />
              { title === 'More Color Options' ? (<span style={{ display: "none" }}>{item.colorName}</span>) : null } 
              <span>{item.sku}</span>
            </ColorButton>
          </Link>
        ))}
      </ColorWrapper>
    </Container>
  );
};
