import React from 'react';
import { withRouter } from 'react-router-dom';
import Dropdown from 'shared/layout/dropdown';
import { Container, DropdownTitle, Option } from '../style';

export default withRouter(
  ({ placeholder, data = [], title, history, currentSku, noPadding }) => {
    if (!data.length) return null;
    return (
      <Container noPadding={noPadding}>
        {title ? <DropdownTitle>{title}</DropdownTitle> : null}
        <Dropdown
          searchable={false}
          items={data.map(item => ({
            label: `${item.name} Spec ID: ${item.sku}`,
            value: item.sku.toLowerCase(),
          }))}
          placeholder={placeholder}
          value={
            placeholder
              ? null
              : data.find(item => item.sku === currentSku).sku.toLowerCase()
          }
          optionRenderer={props => (
            <Option>
              <span>{props.variation}</span>
              <span>{props.label}</span>
            </Option>
          )}
          onChange={({ value }) => history.push(`/product/${value}`)}
        />
      </Container>
    );
  },
);
