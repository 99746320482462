import * as Colors from 'base/colors';
import styled from 'react-emotion';
import {
  Tablet,
  Siliguri,
  Montserrat,
  EncodeSans,
  ButtonWhite,
} from 'shared/layout/base/base';

export const Wrapper = styled('div')`
  background-color: ${Colors.brandHighLightPurple};
  display: flex;
  padding: 20px;

  @media (${Tablet}) {
    padding: 40px;
  }
`;

export const InfoWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 980px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Text = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 980px) {
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

export const Title = styled('span')`
  color: ${Colors.white};
  font-family: ${Montserrat};
  font-size: 30px;
  font-weight: 200;
  margin-bottom: 5px;
`;

export const Subtext = styled('span')`
  color: ${Colors.white};
  font-family: ${Siliguri};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
`;

export const Form = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  color: ${Colors.white};

  @media (min-width: 980px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    width: 50%;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    margin-bottom: 10px;

    @media (min-width: 980px) {
      margin-bottom: 0;
    }
  }

  label {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 500;
    color: ${Colors.white};
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
    transition: all 0.28s ease;
  }

  input {
    width: 100%;
    height: 55px;
    border-radius: 3px;
    border: 1px solid transparent;
    background-color: #8f4f81;
    padding: 10px;
    color: ${Colors.white};

    @media (min-width: 980px) {
      width: 205px;
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: none;

      ~ label {
        font-size: 12px;
        top: 0;
      }
    }

    &.has-value {
      ~ label {
        font-size: 12px;
        top: 0;
      }
    }
  }

  input:-webkit-autofill {
    background-color: transparent !important;
    background-image: none !important;
    color: white !important;
    -webkit-box-shadow: 0 0 0px 1100px #8f4e81 inset;
    border: 1px solid #8f4d81 !important;
    -webkit-appearance: none;
    -webkit-text-fill-color: white !important;
  }
`;

export const Button = styled(ButtonWhite)`
  min-width: 140px;

  @media (max-width: 1108px) {
    margin-top: 20px;
  }
`;
