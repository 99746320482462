import "./css/style.css";
import "./css/select.css";
import "./css/date-picker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import Header from "./header";
import Footer from "./footer";
import Apollo from "./apollo";
import loading from "./spinner";
import About from "views/about";
import ErrorPage from "views/404";
import MaintenancePage from "views/404/maintenance";
import Error from "./header/error";
import Redirects from "./redirects";
import Container from "./container";
import Loadable from "react-loadable";
import PageChange from "./page-change";
import CustomPages from "views/custom-pages";
import TabbedPage from "views/tabbed-page";
import CartProvider from "shared/cart/provider";
import PurchasingMaterials from "views/purchasing-materials";
import Materials from "views/products";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

export default () => (
  <BrowserRouter>
    <Apollo>
      <CartProvider>
        <PageChange>
          {search => (
            <React.Fragment>
              <Header />
              <Error />

              <Container>
              {process.env.REACT_APP_ASI_MAINTENANCE_MODE=== "true" ? (
                  <MaintenancePage />
                ) : (
                search === "?404" ? (
                  <ErrorPage />
                ) : (
                  <Switch>
                    <Route
                      path="/products/search/custom"
                      exact
                      render={({ match }) => <Redirect to={`/customization`} />}
                    />
                    <Route
                      path="/collections/custom"
                      exact
                      render={({ match }) => <Redirect to={`/customization`} />}
                    />
                    <Route
                      path="/search/:slug?"
                      component={Loadable({
                        loader: () => import("views/search"),
                        loading
                      })}
                    />

                    <Route
                      path="/products"
                      component={Materials}
                    />
                    <Route
                      path="/builders-group-2"
                      component={Loadable({
                        loader: () => import("views/products/builders-group"),
                        loading
                      })}
                    />
                    <Route
                      path="/admin/*"
                      component={Loadable({
                        loader: () => import("views/admin"),
                        loading
                      })}
                    />
                    <Route
                      path="/product/:slug"
                      component={Loadable({
                        loader: () => import("views/product"),
                        loading
                      })}
                    />

                    <Route
                      path="/projects"
                      component={Loadable({
                        loader: () => import("views/projects"),
                        loading
                      })}
                    />
                    <Route
                      path="/project/:slug"
                      component={Loadable({
                        loader: () => import("views/project"),
                        loading
                      })}
                    />

                    <Route
                      path="/auth"
                      component={Loadable({
                        loader: () => import("views/auth"),
                        loading
                      })}
                    />
                    <Route
                      path="/account"
                      component={Loadable({
                        loader: () => import("views/account"),
                        loading
                      })}
                    />
                    <Route
                      path="/board/:id"
                      component={Loadable({
                        loader: () => import("views/board"),
                        loading
                      })}
                    />
                    <Route
                      path="/checkout"
                      component={Loadable({
                        loader: () => import("views/checkout"),
                        loading
                      })}
                    />
                    <Route
                      path="/request-quote"
                      component={Loadable({
                        loader: () => import("views/request-quote"),
                        loading
                      })}
                    />

                    <Route
                      path="/community/post/:slug"
                      component={Loadable({
                        loader: () => import("views/community/post"),
                        loading
                      })}
                    />
                    <Route
                      path="/community"
                      component={Loadable({
                        loader: () => import("views/community"),
                        loading
                      })}
                    />
                    <Route
                      path="/collections/:slug"
                      component={Loadable({
                        loader: () => import("views/collections"),
                        loading
                      })}
                    />
                    <Route path="/404" component={ErrorPage} />

                    <Route path="/about/:slug?" component={About} />
                    <Route
                      path="/sales-portal/:slug?"
                      render={props => (
                        <TabbedPage {...props} slug={"sales-portal"} />
                      )}
                    />
                    <Route
                      path="/tradeshows/:slug?"
                      render={props => (
                        <TabbedPage {...props} slug={"tradeshows"} />
                      )}
                    />
                    <Route
                      path="/purchase-order"
                      component={Loadable({
                        loader: () => import("views/purchasing-materials"),
                        loading
                      })}
                    />
                    <Route
                      path="/sample-request"
                      component={Loadable({
                        loader: () => import("views/purchasing-samples"),
                        loading
                      })}
                    />
                    <Route
                      path="/specs-catalog"
                      component={Loadable({
                        loader: () => import("views/specs-catalog"),
                        loading
                      })}
                    />
                    {/* <Route
                      path="/custom-quote"
                      component={Loadable({
                        loader: () => import('views/custom-quote'),
                        loading
                      })}
                    /> */}

                    <Redirects />
                    <Route path="/:slug*" component={CustomPages} />
                  </Switch>
                ))}
              </Container>
              <Footer />
            </React.Fragment>
          )}
        </PageChange>
      </CartProvider>
    </Apollo>
  </BrowserRouter>
);
