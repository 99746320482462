import qs from "qs";
import { graphql } from "react-apollo";
import gql from "graphql-tag.macro";

export const query = gql`
  query Board($input: GetBoardInput!) {
    board(input: $input) {
      id
      name
      description
      user_id
      template
      textarea
      layout
      background
      stage_lines
      stage_texts
      preview_image
      canEdit
      vision_board_project_id
      status
      project {
        vision_board_project_id
        name
        status
        description
        user_id
        opportunity_id
      }
      user { 
        user_nicename
      }
      collaborators {
        id
        name
        phone
        email
        board_id
        can_edit
      }
      images {
        id
        url
        slot
      }
      tooltips {
        id
        text
        title
      }

      colors {
        id
        hex_code
        slot
      }
      items {
        item_id
        id
        name
        collection_slug
        item_type
        slug
        image {
          thumbnail
        }
        slot
      }
    }
  }
`;
export default graphql(query, {
  options: props => {
    let query = qs.parse(window.location.search.substring(1));
    let input = { email: query.email, id: parseInt(props.match.params.id, 10) };
   
    return {
      variables: { input },
      fetchPolicy: "cache-and-network"
    };
  }
});
