import React from 'react';
import Check from 'icons/check';
import { Square } from './style';

const Box = ({ checked }) => (
  <React.Fragment>
    <Square checked={checked}>
      <Check
        style={{
          position: 'absolute',
          padding: '2px',
        }}
        height={13}
        width={12}
      />
    </Square>
  </React.Fragment>
);

export default Box;
