import React from 'react';
import Field from './field';
import { css } from 'emotion';
import MaskedInput from 'react-text-mask';
import { brandHighLightPurple } from 'base/colors';

const Input = css`
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #e0dfdf;
    width: 100%;
    border-radius: 3px;
    font-size: 13px;
    line-height: 1.5;
    padding: 15px 20px;

    &:focus {
      border-color: ${brandHighLightPurple};
      outline: none;
    }
  }
`;
export default props => {
  let {
    label,
    type,
    error,
    success,
    customOnBlur,
    onBlur,
    onEnter,
    hideLabel,
    after,
    mask,
    ...rest
  } = props;
  return (
    <Field {...props} after={after} hideLabel={hideLabel} onEnter={onEnter}>
      {React.createElement(mask ? MaskedInput : 'input', {
        ...rest,
        mask,
        className: Input,
        type: type || 'text',
        onBlur: e => {
          if (onBlur) onBlur(e);
          if (customOnBlur) customOnBlur(e);
        },
      })}
    </Field>
  );
};
