import storage from "shared/storage";

export const isEmpty = (value) => { 
  if (value === '' || value === null || typeof value === 'undefined') {
    return true;
  }
  return false;
}

export const cartId = () => {
    return storage.get("user") ? storage.get('cartId-'+storage.get("user").id) : null;
}

export const isDark = (color) => {
    let c = color ? color.substring(1) : 'ffffff';  // strip #
    let rgb = parseInt(c, 16);   // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff;  // extract red
    let g = (rgb >>  8) & 0xff;  // extract green
    let b = (rgb >>  0) & 0xff;  // extract blue

    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    
    return luma < 128;
}

export const boardLayoutValue = (layout) => {
    if(isEmpty(layout)) return {};
    const layoutParse = JSON.parse(layout);
    return ('colorSlots' in layoutParse || 'showTitle' in layoutParse || 'showDescription' in layoutParse) ? layoutParse : {};
}

export const removeHtmlTags = (value) => {
    return value ? value.replace(/(<([^>]+)>)/ig, "").trim() : '';
}

export const imageExists = (url, callback) => {
  const img = new Image();
  img.src = url;
  
  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    
    img.onerror = () => {
      callback(false);
    };
  }
}