import React from "react";
import Search from "./search";
import Head from "shared/head";
import Sidebar from "./sidebar";
import SidebarFilters from "views/products/sidebar/collection-sidebar";
import Flex from "shared/layout/flex";
import { Route } from "react-router-dom";
import { Content, Container, Wrapper } from "./style";
import BlockRenderer from "shared/blocks/render";
import { SiteContainer } from "shared/layout/base/base";
import CategoryCollections from "./search/products/category-collections/index";

const Materials = ({ type = "products", location }) => {
    
    let type2 = (type === "builders-group-2")?"buildersgroup":type;
 return (    
  <Container>
    <Head title={(type === "products" || type === "builders-group-2") ? "Materials" : "Projects"} />
    <SiteContainer>
      <Wrapper>
        { location.pathname === '/products/search' ? (<SidebarFilters
                  type="products"
                  location={location}
                  collections={true}
                  allRecords={true}
                  must={[
                      {
                        name: "meta.woo_product_show_list.value",
                        value: "yes"
                      }
                    ].filter(Boolean)}
                />):
                (<Sidebar type={type2} location={location} page={"main"} />)}

        <Flex column style={{ width: "100%" }}>
          <Route
            path={`/${type}/search/:category?`}
            component={props =>
              type === "products" &&
              (location.search == "" || location.search == "?slug=") ? (
                <CategoryCollections />
              ) : (
                <Search {...props} type={type} />
              )
            }
          />

          <Route
            path={`/${type}`}
            exact
            render={() => (
              <Content>
                <BlockRenderer
                  slug={type}
                  extraProps={{
                    landingPage: true
                  }}
                />
              </Content>
            )}
          />
        </Flex>
      </Wrapper>
    </SiteContainer>
  </Container>
 );
}

export default Materials;
