import React from 'react';
import styled from 'react-emotion';
import { EncodeSans, Tablet, Montserrat } from 'shared/layout/base/base';
import * as Colors from 'base/colors';

export const Text = styled('p')`
  font-family: ${EncodeSans};
  font-size: 15px;
  line-height: 1.53;
  color: ${Colors.lightTextGrey};

  a {
    color: ${Colors.ctaGreen};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonWrapper = styled('div')`
  background-color: ${Colors.ctaGreen};
  color: ${Colors.white};
  cursor: pointer;
  border-radius: 3px;
  border: 2px solid ${Colors.ctaGreen};
  transition: background-color 0.25s, color 0.25s;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  margin-top: 20px;

  @media (${Tablet}) {
    margin-top: 0;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    min-width: 130px;
    width: auto;
  }

  &:hover {
    background-color: ${Colors.white};
    a {
      color: ${Colors.ctaGreen};
    }
  }

  a {
    display: block;
    color: ${Colors.white};
    padding: 16px 20px;
    text-align: center;
  }
`;

export const SearchWrapper = styled(({ noTopBorder, ...props }) => (
  <div {...props} />
))`
  padding: 40px 0;
  margin: 40px 0;
  border-bottom: 1px solid ${Colors.borderColor};
  ${props =>
    props.noTopBorder ? ' ' : `border-top: 1px solid ${Colors.borderColor};`}
  ${props => (props.noTopBorder ? 'padding-top:0; ' : '')}
`;

export const Heading = styled('h3')`
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 10px;

  @media (${Tablet}) {
    font-size: 35px;
    margin-bottom: 20px;
  }
`;

export const Section = styled('div')`
  margin: 40px 0;
  padding-bottom: 40px;
  border-bottom: 1px solid ${Colors.borderColor};
`;
