import styled from 'react-emotion';
import { Tablet } from 'shared/layout/base/base';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  display: none;

  @media (${Tablet}) {
    order: 4;
    display: block;
    position: relative;
  }
`;
