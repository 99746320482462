import React from 'react';

const board = props => (
  <svg
    {...props}
    width="492px"
    height="412px"
    viewBox="0 0 492 412"
    version="1.1"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="corkboard" fillRule="nonzero">
        <path
          d="M472,0 L20,0 C8.954,0 0,8.954 0,20 L0,392 C0,403.046 8.954,412 20,412 L472,412 C483.046,412 492,403.046 492,392 L492,20 C492,8.954 483.046,0 472,0 Z"
          id="XMLID_753_"
          fill="#61A60D"
        />
        <rect
          id="XMLID_752_"
          fill="#FFFFFF"
          x="44"
          y="44"
          width="404"
          height="324"
        />
        <rect
          id="XMLID_1757_"
          fill="#61A60D"
          x="72"
          y="89"
          width="88"
          height="152"
        />
        <rect
          id="XMLID_1762_"
          fill="#61A60D"
          x="329"
          y="67"
          width="88"
          height="96"
        />
        <rect
          id="XMLID_1763_"
          fill="#61A60D"
          x="202"
          y="73"
          width="82"
          height="96"
        />
        <rect
          id="XMLID_1763_-Copy"
          fill="#61A60D"
          x="333"
          y="198"
          width="88"
          height="60"
        />
        <rect
          id="XMLID_1763_-Copy-4"
          fill="#61A60D"
          x="196"
          y="198"
          width="88"
          height="60"
        />
        <rect
          id="XMLID_1763_-Copy-3"
          fill="#61A60D"
          x="76"
          y="284"
          width="82"
          height="60"
        />
        <g
          id="Group"
          transform="translate(63.000000, 64.000000)"
          fill="#61A60D"
        >
          <g id="XMLID_756_">
            <g id="XMLID_754_">
              <path
                d="M93.462963,197 L9.53703704,197 C4.27068519,197 0,189.396819 0,180.017241 L0,16.9827586 C0,7.60318103 4.27068519,0 9.53703704,0 L93.462963,0 C98.7293148,0 103,7.60318103 103,16.9827586 L103,180.017241 C103,189.396819 98.7293148,197 93.462963,197 Z M19.0740741,163.034483 L83.9259259,163.034483 L83.9259259,33.9655172 L19.0740741,33.9655172 L19.0740741,163.034483 Z"
                id="XMLID_208_"
              />
            </g>
          </g>
        </g>
        <g
          id="XMLID_776_"
          transform="translate(325.000000, 64.000000)"
          fill="#61A60D"
        >
          <g id="XMLID_779_">
            <path
              d="M92.5555556,111 L9.44444444,111 C4.22922222,111 0,106.715974 0,101.431034 L0,9.56896552 C0,4.28402586 4.22922222,0 9.44444444,0 L92.5555556,0 C97.7707778,0 102,4.28402586 102,9.56896552 L102,101.431034 C102,106.715974 97.7707778,111 92.5555556,111 Z M18.8888889,91.862069 L83.1111111,91.862069 L83.1111111,19.137931 L18.8888889,19.137931 L18.8888889,91.862069 Z"
              id="XMLID_204_"
            />
          </g>
        </g>
        <g
          id="XMLID_781_"
          transform="translate(186.000000, 64.000000)"
          fill="#61A60D"
        >
          <g id="XMLID_788_">
            <path
              d="M106.452239,111 L11.1861835,111 C5.20823853,111 0.360495413,106.715974 0.360495413,101.431034 L0.360495413,9.56896552 C0.360495413,4.28402586 5.20823853,0 11.1861835,0 L106.452239,0 C112.430183,0 117.277927,4.28402586 117.277927,9.56896552 L117.277927,101.431034 C117.277927,106.715974 112.430183,111 106.452239,111 Z M22.0118716,91.862069 L95.6265505,91.862069 L95.6265505,19.137931 L22.0118716,19.137931 L22.0118716,91.862069 Z"
              id="XMLID_200_"
            />
          </g>
        </g>
        <g
          id="XMLID_788_"
          transform="translate(63.000000, 280.000000)"
          fill="#61A60D"
        >
          <path
            d="M98,68 L10,68 C4.478,68 0,65.3755517 0,62.137931 L0,5.86206897 C0,2.62444828 4.478,0 10,0 L98,0 C103.522,0 108,2.62444828 108,5.86206897 L108,62.137931 C108,65.3755517 103.522,68 98,68 Z M21,56.1395349 L89,56.1395349 L89,11.8604651 L21,11.8604651 L21,56.1395349 Z"
            id="XMLID_200_"
          />
        </g>
        <g
          id="XMLID_788_-Copy"
          transform="translate(325.000000, 193.000000)"
          fill="#61A60D"
        >
          <path
            d="M93.462963,68 L9.53703704,68 C4.27068519,68 0,65.3755517 0,62.137931 L0,5.86206897 C0,2.62444828 4.27068519,0 9.53703704,0 L93.462963,0 C98.7293148,0 103,2.62444828 103,5.86206897 L103,62.137931 C103,65.3755517 98.7293148,68 93.462963,68 Z M19.2266667,56.6054054 L83.7733333,56.6054054 L83.7733333,11.7621622 L19.2266667,11.7621622 L19.2266667,56.6054054 Z"
            id="XMLID_200_"
          />
        </g>
        <g
          id="XMLID_788_-Copy-2"
          transform="translate(186.000000, 193.000000)"
          fill="#61A60D"
        >
          <path
            d="M107.074074,68 L10.9259259,68 C4.89262963,68 0,65.3755517 0,62.137931 L0,5.86206897 C0,2.62444828 4.89262963,0 10.9259259,0 L107.074074,0 C113.10737,0 118,2.62444828 118,5.86206897 L118,62.137931 C118,65.3755517 113.10737,68 107.074074,68 Z M21.6513761,56.6054054 L95.266055,56.6054054 L95.266055,11.7621622 L21.6513761,11.7621622 L21.6513761,56.6054054 Z"
            id="XMLID_200_"
          />
        </g>
        <g
          id="XMLID_788_-Copy-4"
          transform="translate(191.000000, 280.000000)"
          fill="#61A60D"
        >
          <path
            d="M215.055556,68 L21.9444444,68 C9.82672222,68 0,65.3755517 0,62.137931 L0,5.86206897 C0,2.62444828 9.82672222,0 21.9444444,0 L215.055556,0 C227.173278,0 237,2.62444828 237,5.86206897 L237,62.137931 C237,65.3755517 227.173278,68 215.055556,68 Z"
            id="XMLID_200_"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default board;
