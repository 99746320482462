import React from "react";
import Heading from "./heading";
import ElasticSearch from "./elastic-search";
import { withRouter, Redirect } from "react-router-dom";
import Product from "shared/blocks/products/tabs/card";
import { getQuery, mergeQuery } from "shared/query-string";
import Pagination from "shared/blocks/about/projects/pagination";
import Project from "shared/blocks/home/featured-projects/project";
import {
  Cards,
  Wrapper,
  HeaderWrapper
} from "shared/blocks/products/tabs/style";
import Samples from "views/collections/samples";
import Paginate from "./heading/paginate";
import { Wrapper as PaginateWrapper } from "./heading/style";

const Products = ({
  products,
  total,
  type,
  history,
  hideDropdown,
  collectionsPage,
  collection
}) => {
  let isProject = type === "projects";
  //if no search string and not on a category, redirect to root

  if (
    window.location.pathname.split("/").length === 3 &&
    !window.location.search &&
    !window.location.pathname.match(/collection/)
  )
  return <Redirect to={`/${type}`} />;
  return (
    <React.Fragment>
      {hideDropdown ? (
        <Heading
          total={total}
          hideDropdown={hideDropdown}
          collectionsPage={collectionsPage}
        />
      ) : null}
      <HeaderWrapper>
        {collectionsPage ? (
          <Samples
            collection={collection}
            samples={collection.samples}
            title={collection.title}
            collectionsPage={collectionsPage}
          />
        ) : null}
      </HeaderWrapper>

      <Cards searchPage>
        {products
          ? products.map(product => (
              <Wrapper key={product.id} project={isProject ? true : null}>
                {React.createElement(isProject ? Project : Product, {
                  product: isProject ? null : product,
                  project: isProject ? product : null,
                  elasticSearch: true
                })}
              </Wrapper>
            ))
          : null}
      </Cards>

      {!collectionsPage ? (
        <Pagination
          maxPages={Math.ceil(total / 15)}
          currentPage={getQuery().from ? getQuery().from / 15 + 1 : 1}
          changePage={page =>
            history.push(
              `${window.location.pathname}${mergeQuery({
                size: 15,
                from: page * 15 - 15
              })}`
            )
          }
        />
      ) : null}

      {collectionsPage ? (
        <PaginateWrapper>
          <Paginate
            total={total}
            collectionsPage
            onChange={() =>
              document.getElementById("materials").scrollIntoView()
            }
          />
        </PaginateWrapper>
      ) : null}
    </React.Fragment>
  );
};

export default ElasticSearch(withRouter(Products));
