import React from 'react';
import { css } from 'emotion';
import { Tablet, Montserrat, Siliguri } from 'shared/layout/base/base';
import * as Colors from 'base/colors';

const Heading = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 40px;
  padding-top: 60px;

  @media (${Tablet}) {
    margin: 0 20px 40px;
    padding-top: 80px;
  }

  h2 {
    font-family: ${Montserrat};
    font-weight: 200;
    text-align: center;
    color: ${Colors.titleGrey};
    padding-bottom: 20px;
    position: relative;
    font-size: 20px;

    @media (${Tablet}) {
      font-size: 35px;
    }

    &:after {
      content: '';
      width: 80px;
      height: 2px;
      background-color: ${Colors.brandHighLightPurple};
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h3 {
    font-family: ${Siliguri};
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    color: ${Colors.titleGrey};
    font-size: 16px;
    text-align: center;
    margin-top: 20px;

    @media (${Tablet}) {
      font-size: 22px;
    }
  }
`;

export default ({ text, subText }) => (
  <div className={Heading}>
    <h2>{text}</h2>
    {subText ? <h3>{subText}</h3> : false}
  </div>
);
