import { graphql } from "react-apollo";

import gql from "graphql-tag.macro";
export const query = gql`
  query GetCollectionsByCategory($slug: String!) {
    getCollectionsByCategory(slug: $slug) {
      id
      slug
      title
      subtitle
      image {
        full
      }
    }
  }
`;
export default graphql(query, {
  options: () => {
    let url = window.location.pathname;
    let slugString = url.split("/products/search/").pop();

    return {
      variables: { slug: slugString }
    };
  }
});
