import React from 'react';
import { IntroText } from './style';
import Container from 'views/checkout/shared/container';

export default ({ children, title, introText, red }) => (
  <React.Fragment>
    <Container title={title}>
      <IntroText red={red}>{introText}</IntroText>
      {children}
    </Container>
  </React.Fragment>
);
