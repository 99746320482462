import React from 'react';
import Icon from 'icons';
import { SocialSidebar } from './style';

export default ({ youtube, pinterest, instagram, facebook }) => (
  <SocialSidebar>
    <h3>Be Social</h3>
    <span>Follow, Tag, Share</span>
    <ul>
      {youtube ? (
        <li>
          <a href={youtube}>
            <Icon name="youtube" />
          </a>
        </li>
      ) : null}
      {pinterest ? (
        <li>
          <a href={pinterest}>
            <Icon name="pinterest" />
          </a>
        </li>
      ) : null}
      {instagram ? (
        <li>
          <a href={instagram}>
            <Icon name="instagram" />
          </a>
        </li>
      ) : null}
      {facebook ? (
        <li>
          <a href={facebook}>
            <Icon name="facebook" />
          </a>
        </li>
      ) : null}
      <li>
        <a href="/community">
          <Icon name="materialista" />
        </a>
      </li>
    </ul>
  </SocialSidebar>
);
