import core from './core';
import columns from './columns';
import defaultBlock from './default';

export default (block, index, extraProps, wrappers) => {
  if (block.name.match(/core\/columns/))
    return columns(block, index, extraProps, wrappers);
  if (block.name.match(/core/)) return core(block, index, extraProps, wrappers);

  return defaultBlock(block);
};
