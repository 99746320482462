import React from "react";
import styled from "react-emotion";
import {
  ctaGreen,
  white,
  backgroundGrey,
  brandHighLightPurple,
  titleGrey,
  brandPink,
  black,
  brandPurple,
  borderColor
} from "../../../base/colors";

export const Tablet = "min-width: 768px";
export const Phone = "max-width: 767px";
export const Desktop = "min-width: 1020px";

export const Siliguri = "'Hind Siliguri', sans-serif";
export const Montserrat = "'Montserrat', sans-serif;";
export const EncodeSans = "'Encode Sans', sans-serif;";

export const SiteContainer = styled("div")`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 1120px;
  }

  @print {
    margin: 0;
    padding: 0;
  }
`;

const ButtonStyle = styled(({ loading, children, ...props }) => (
  <button {...props}>
    {loading ? <div className="loader">Loading...</div> : children}
  </button>
))`
  background-color: ${ctaGreen};
  padding: 10px 20px;
  color: ${white};
  cursor: pointer;
  border-radius: 3px;
  border: 2px solid ${ctaGreen};
  transition: background-color 0.25s, color 0.25s;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;

  &:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    padding: 16px 20px;
    min-width: 130px;
    width: auto;
  }

  &:hover {
    background-color: ${white};
    color: ${ctaGreen};

    .loader {
      color: ${ctaGreen};
    }
  }

  img {
    width: 20px;
    height: 20px;
    pointer-events: none;
  }

  .loader {
    color: #ffffff;
    font-size: 4px;
    margin: 5px auto;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
        0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
        0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
        0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
        0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
`;

export class Button extends React.Component {
  constructor() {
    super();
    this.state = { loading: false };
  }
  componentWillUnmount() {
    this.unmount = true;
  }
  render() {
    let { loading } = this.state;
    let { loadOnClick, onClick, ...props } = this.props;
    return (
      <ButtonStyle
        {...props}
        loading={loading || props.loading}
        onClick={async e => {
          if (loading || props.loading) return;
          if (!loadOnClick) {
            if (onClick) return onClick(e);
            return;
          }
          this.setState({ loading: true });
          await onClick(e);
          if (!this.unmount) this.setState({ loading: false });
        }}
      />
    );
  }
}

export const ButtonWhite = styled(Button)`
  background-color: transparent;
  border-color: ${white};

  &:hover {
    background-color: ${white};
    color: ${ctaGreen};
  }
`;

export const ButtonWhiteGreenBorder = styled(Button)`
  background-color: transparent;
  border-color: ${ctaGreen};
  color: ${ctaGreen};

  &:hover {
    background-color: ${ctaGreen};
    color: ${white};
  }
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const GreyBackground = styled("div")`
  background-color: ${backgroundGrey};
`;

export const WysiwygWrapper = styled("div")`
  display: block;
  margin: 40px 0;
  overflow: hidden;

  @media (${Tablet}) {
    margin: 80px 0;
  }

  &:after {
    content: "";
    display: block;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 1px;
    background-color: ${borderColor};
    margin-top: 40px;
    width: 100vw;

    @media (${Tablet}) {
      margin-top: 80px;
    }
  }
`;

export const P = styled("p")`
  display: block;
  font-family: ${EncodeSans};
  font-size: 13px;
  line-height: 2.23;
  color: ${titleGrey};
  text-align: left;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const UL = styled("ul")`
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    line-height: 1.6;
    font-size: 13px;
    color: ${titleGrey};

    @media (${Tablet}) {
      font-size: 16px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${brandHighLightPurple};
    }
  }
`;

export const OL = styled("ol")`
  padding: 0;
  list-style: none;
  counter-reset: Asicounter;

  li {
    position: relative;
    margin-bottom: 10px;
    line-height: 1.6;
    padding-left: 30px;
    font-size: 13px;
    color: ${titleGrey};

    @media (${Tablet}) {
      font-size: 16px;
    }

    &:before {
      content: counter(Asicounter);
      counter-increment: Asicounter;
      font-size: 16px;
      color: ${brandHighLightPurple};
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

export const Image = styled("img")`
  width: 100%;
  height: auto;
  display: block;
`;

export const Blockquote = styled("blockquote")`
  font-family: ${Montserrat};
  font-size: 16px;
  font-weight: 300;
  line-height: 1.52;
  color: ${titleGrey};
  padding: 40px 60px;
  margin: 20px 0;
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  width: 100%;
  position: relative;

  @media (min-width: 880px) {
    font-size: 25px;
    padding: 40px 120px;
    margin: 40px 0;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: #f3f2f2;
    position: absolute;

    @media (min-width: 880px) {
      width: 60px;
      height: 60px;
    }

    &:first-child {
      top: 40px;
      left: 20px;

      @media (min-width: 880px) {
        top: 40px;
        left: 0;
      }
    }

    &:last-child {
      bottom: 40px;
      right: 20px;
      transform: rotate(-180deg);

      @media (min-width: 880px) {
        right: 0;
      }
    }
  }

  span {
    font-family: ${EncodeSans};
    font-size: 13px;
    font-weight: 600;
    color: ${black};
    display: flex;
    align-items: center;
    margin-top: 20px;

    &:before {
      margin-right: 10px;
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      background-color: ${brandPurple};
    }

    @media (${Tablet}) {
      font-size: 15px;
    }
  }
`;

export const ListTitle = styled("span")`
  font-family: ${Siliguri};
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid ${borderColor};
  display: flex;
  margin: 40px 20px 10px 20px;

  @media (max-width: 767px) {
    margin: 40px 20px 10px 20px;
  }

  @media (${Tablet}) {
    font-size: 22px;
    margin: 40px 0 20px 0;
  }

  @media (max-width: 1119px) {
    margin: 40px 20px 10px 20px;
  }
`;

export const Wysiwyg = styled("div")`
  display: block;
  font-family: ${EncodeSans};
  font-size: 13px;
  line-height: 2.23;
  color: ${titleGrey};
  text-align: center;

  @media (max-width: 1119px) {
    padding: 0 20px;
  }

  a {
    color: ${ctaGreen};

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3 {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 35px;
    line-height: 1.2;
    font-family: ${Montserrat};
    font-weight: 300;
  }

  h2 {
    font-size: 30px;
    line-height: 1.3;
    font-family: ${Montserrat};
    font-weight: 300;
  }

  h3 {
    font-size: 22px;
    line-height: 1.4;
    font-family: ${Siliguri};
    font-weight: 400;
  }

  h4 {
    font-size: 18px;
    line-height: 1.5;
    font-family: ${Siliguri};
    font-weight: 400;
  }

  h5 {
    font-size: 16px;
    line-height: 1.6;
    font-family: ${Siliguri};
    font-weight: 600;
  }

  h6 {
    font-size: 16px;
    line-height: 1.6;
  }

  p {
    font-size: 14px;
    line-height: 1.6;
    margin-top: 0;

    a {
      color: ${ctaGreen};
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      line-height: 1.6;
      font-size: 16px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${brandHighLightPurple};
      }
    }
  }

  ol {
    padding: 0;
    list-style: none;
    counter-reset: Asicounter;

    li {
      position: relative;
      margin-bottom: 10px;
      line-height: 1.6;
      padding-left: 30px;

      &:before {
        content: counter(Asicounter);
        counter-increment: Asicounter;
        font-size: 16px;
        color: ${brandHighLightPurple};
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
`;

export const Heading = styled(({ left, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.left ? "flex-start" : "center")};
  margin: ${props => (props.left ? "0 0 20px 0" : "0 20px 20px")};

  @media (${Tablet}) {
    margin: ${props => (props.left ? "80px 0 20px 0" : "40px 20px 40px")};
  }

  h2 {
    font-family: ${Montserrat};
    font-weight: 200;
    text-align: ${props => (props.left ? "left" : "center")};
    color: ${titleGrey};
    padding-bottom: 10px;
    position: relative;
    font-size: 20px;

    @media (${Tablet}) {
      font-size: 35px;
      padding-bottom: 20px;
    }

    &:after {
      content: "";
      width: 80px;
      height: 2px;
      background-color: ${brandHighLightPurple};
      position: absolute;
      bottom: 0;
      left: ${props => (props.left ? "0" : "50%")};
      transform: ${props =>
        props.left ? "translateX(0)" : "translateX(-50%)"};
    }
  }

  h3 {
    font-family: ${Siliguri};
    font-size: 22px;
    font-weight: 500;
    line-height: 1.45;
    color: ${titleGrey};
    font-size: 16px;
    text-align: ${props => (props.left ? "left" : "center")};
    margin-top: 10px;

    @media (${Tablet}) {
      font-size: 22px;
    }
  }
`;

export const HeadingNoSubtitle = styled(({ left, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.left ? "flex-start" : "0")};
  margin: ${props => (props.left ? "40px 20px 20px 0" : "40px 20px 20px")}; 
  ${props => (props.noMargin ? "margin: 0;" : " ")}

  @media (${Tablet}) {
    margin: ${props => (props.left ? "80px 20px 40px 0" : "80px 20px 40px")};
    ${props => (props.landingPage ? "margin: 40px 20px 40px 0;" : " ")}
    ${props => (props.noMargin ? "margin: 0;" : " ")}
  }

  font-family: ${Montserrat} !important;
  font-weight: 200 !important;
  text-align: ${props => (props.left ? "left" : "center")};
  color: ${titleGrey} !important;
  padding-bottom: 10px;
  position: relative;
  font-size: 20px;

  @media (${Tablet}) {
    font-size: 35px;
  }

  &:after {
    content: '';
    width: 80px;
    height: 2px;
    background-color: ${brandHighLightPurple};
    position: absolute;
    bottom: 0;
    left:  ${props => (props.left ? "0" : "50%")};
    transform: ${props =>
      props.left ? "translateX(0)" : "translateX(-50%)"};ranslateX(-50%);
  }

  @media(max-width: 1119px) {
    margin: ${props => (props.left ? "40px 20px 20px 20px" : "40px 20px 20px")};
  }
`;

export const IconHeading = styled(Heading)`
  margin: 0 20px 40px;

  svg {
    width: 57px;
    height: 57px;
    margin-bottom: 10px;
    fill: ${brandPink};

    @media (${Tablet}) {
      width: 80px;
      height: 80px;
    }
  }
`;

export const PaginationWrapper = styled("div")`
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const PrevButton = styled("button")`
  width: 50px;
  height: 50px;
  background-color: ${white};
  border: solid 1px #e0dfdf;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${ctaGreen};

    svg {
      fill: ${white};
    }
  }

  svg {
    width: 10px;
    height: 17px;
    fill: ${ctaGreen};
    transform: rotate(-180deg);
    transition: fill 0.25s;
  }
`;

export const NextButton = styled("button")`
  width: 50px;
  height: 50px;
  background-color: ${white};
  border-radius: 3px;
  border: solid 1px #e0dfdf;
  cursor: pointer;
  margin-left: 5px;
  transition: background-color 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${ctaGreen};

    svg {
      fill: ${white};
    }
  }

  svg {
    width: 10px;
    height: 17px;
    fill: ${ctaGreen};
    transition: fill 0.25s;
  }
`;

export const Pagination = styled("ul")`
  display: flex;
  padding: 0;
  list-style: none;
`;

export const PaginationButton = styled(({ active, ...props }) => (
  <button {...props} />
))`
  font-family: ${EncodeSans};
  font-size: 18px;
  font-weight: 600;
  width: 50px;
  height: 50px;
  background-color: ${white};
  border-radius: 3px;
  border: solid 1px #e0dfdf;
  cursor: pointer;
  color: ${props => (props.active ? `${black}` : `${ctaGreen}`)};
  margin: 0 5px;
  transition: color 0.25s, background-color 0.25s;

  &:hover {
    color: ${white};
    background-color: ${ctaGreen};
  }
`;

export const WarningContainer = styled("div")`
  position: fixed;
  top: 65px;
  right: 20px;
  width: 80%;
  z-index: 9999;
  background-color: ${brandPink};
  color: #fff;
  padding: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  text-align: left;

  @media (${Tablet}) {
    max-width: 400px;
    width: 100%;
  }

  button {
    width: 100%;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-right: 10px;
  }
`;

export const AlertNotification = styled("span")`
  margin: 0;
  font-family: ${Siliguri};
  font-size: 14px;
  line-height: 1.57;
  color: #d0021b;
  margin-top: 5px;
`;

export const WarningWrapper = styled("div")`
  display: flex;
  align-items: flex-start;

  div {
    color: #fff;
    margin-bottom: 20px;
    font-family: ${Montserrat};
    font-size: 15px;
    width: calc(100% - 20px);
  }
`;
