import React from 'react';
import { css } from 'emotion';
import Icon from 'icons';
import { ctaGreen } from 'base/colors';
import { EncodeSans, Tablet } from 'shared/layout/base/base';

const AddButton = css`
  display: flex;
  align-items: center;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  color: ${ctaGreen};
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 220px;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.8;

  svg {
    width: 20px;
    height: 20px;
    fill: ${ctaGreen};
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }

  @media (${Tablet}) {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 0;
  }
`;
export default ({ text, ...props }) => (
  <button className={AddButton} {...props}>
    <Icon name="plus" />
    {text}
  </button>
);
