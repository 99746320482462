import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { EncodeSans } from 'shared/layout/base/base';

export const Table = styled('div')`
  width: 100%;
  border-top: solid 1px ${Colors.borderColor};
  border-left: solid 1px ${Colors.borderColor};
  border-right: solid 1px ${Colors.borderColor};
`;

export const Row = styled('div')`
  display: flex;
`;

export const Heading = styled('div')`
  width: 50%;
  background-color: #f3f2f2;
  border-bottom: solid 1px ${Colors.borderColor};
  border-right: solid 1px ${Colors.borderColor};
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  padding: 20px;
  color: ${Colors.titleGrey};
`;

export const Data = styled('div')`
  width: 50%;
  padding: 20px;
  font-family: ${EncodeSans};
  font-size: 13px;
  color: ${Colors.titleGrey};
  border-bottom: solid 1px ${Colors.borderColor};
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 20px;
    fill: ${Colors.ctaGreen};
    margin-left: 10px;
  }
`;
