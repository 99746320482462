import React from 'react';
import { Arrow, Title } from './style';
import Flex from 'shared/layout/flex';
import Caret from 'icons/caret';
import { ctaGreen } from 'base/colors';

const Heading = ({ children, closed, toggleFilter }) => (
  <Flex onClick={toggleFilter} style={{ cursor: 'pointer' }}>
    <Title>{children}</Title>
    <Arrow>
      <Caret rotate={closed ? '90' : '-90'} color={ctaGreen} />
    </Arrow>
  </Flex>
);

export default Heading;
