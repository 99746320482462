import React from 'react';
import styled from 'react-emotion';
import { titleGrey } from 'base/colors';
import { EncodeSans } from 'shared/layout/base/base';

export const Container = styled(({ loading, ...props }) => <div {...props} />)`
  @media (max-width: 767px) {
    margin: 10px;
    padding: 10px;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    min-width: 255px;
    box-shadow: rgba(50, 50, 50, 0.2) 0px 0px 2px 0px;
    margin-right: 32px;
    position: sticky;
    top: 123px;
  }

  height: 100%;
  ${props => (props.loading ? 'min-height: 500px' : '')};

  padding: 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1px;

  @media (max-width: 1119px) {
    margin-left: 20px;
  }
`;

export const ItemContainer = styled(({ show, ...props }) => <div {...props} />)`
  font-size: 13px;
  line-height: 1.5;
  font-family: 'Encode Sans', sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const Arrow = styled('div')`
  margin-left: 10px;
  margin-top: 1px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const Title = styled('h4')`
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 600;
  color: ${titleGrey};
`;
