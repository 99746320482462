import React from 'react';
import { Provider } from 'shared/cart/context';

//Once this is done ill split it out, not sure if i can consolidate anything yet

export default class CartProvider extends React.Component {
  constructor() {
    super();
    this.state = { errors: {} };
    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
    this.addPromo = this.addPromo.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  async update(values, fetch = true) {
    let { loading } = this.state;
    if (loading) return;
  }

  async add(key, value) {}

  async addPromo(coupon) {}

  async load() {}

  render() {
    let { children } = this.props;
    let { cart, loading, promoError, loadingPromo } = this.state;
    return (
      <Provider
        value={{
          cart,
          loading,
          promoError,
          loadingPromo,
          add: this.add,
          update: this.update,
          addPromo: this.addPromo,
        }}
      >
        {children}
      </Provider>
    );
  }
}
