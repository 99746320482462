import React from 'react';
import Cart from 'icons/cart';
import * as Colors from 'base/colors';
import styled, { css } from 'react-emotion';
import { Tablet } from 'shared/layout/base/base';

export const CartWrapper = styled('div')`
  display: flex;
  margin-left: auto;

  @media print {
    display: none;
  }

  @media (${Tablet}) {
    order: 3;
    margin-left: 0;
    position: relative;
  }
`;

export const CartIcon = () => (
  <Cart
    className={css`
      width: 25px;
      height: 25px;
      fill: ${Colors.black};
    `}
  />
);

export const ShoppingCartWrapper = styled('div')`
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: ${Colors.white};
  position: absolute;
  right: 0;
  transform: ${props => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.25s;
  z-index: 3;

  @media (${Tablet}) {
    top: 100%;
    height: auto;
    transform: ${props =>
      props.show ? 'scaleY(1) translateX(-50%)' : 'scaleY(0) translateX(-50%)'};
    transition: transform 0.25s ease-in-out, height 0.25s ease-in-out,
      opacity 0.25s;
    opacity: ${props => (props.show ? '1' : '0')};
    transform-origin: top center;
    z-index: 2;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #e6e6e6;
    left: 50%;
    max-height: 60vh;
    width: 100vw;
    max-width: 300px;
  }
`;

export const CartButton = styled('button')`
  border: none;
  cursor: pointer;
  position: relative;
  transition: border-bottom-color 0.15s;

  &:hover {
    @media (${Tablet}) {
      border-bottom-color: ${Colors.ctaGreen};
    }
  }

  &:focus {
    outline: none;
  }

  @media (${Tablet}) {
    border-bottom: 3px solid transparent;
    padding: 7px 20px 3px 10px;
    margin-left: 20px;
    margin-right: 10px;
  }
`;

export const ShoppingCartBackButton = styled('button')`
  width: 100%;
  color: ${Colors.black};
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  display: block;

  @media (${Tablet}) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  svg {
    fill: ${Colors.black};
    width: 30px;
    height: 15px;
    float: left;
    transform: rotate(180deg);
  }
`;

export const CartItems = styled('span')`
  border-radius: 50%;
  background-color: ${Colors.ctaGreen};
  min-width: 15px;
  min-height: 15px;
  font-size: 10px;
  padding: 2px;
  font-weight: 700;
  color: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  right: 6px;

  @media (${Tablet}) {
    min-width: 17px;
    min-height: 17px;
    top: 0px;
    right: 10px;
  }
`;
