import qs from 'qs';
import { cartId } from "shared/helper";
import { graphql } from 'react-apollo';
import gql from 'graphql-tag.macro';
import cart from 'shared/gql/fragments/cart';

export const query = gql`
  query Cart($input: GetCartInput!) {
    cart(input: $input) {
      ...cart
    }
  }
  ${cart}
`;
export default graphql(query, {
  options: () => {
    let input = { type: 'ALL' };

    if (cartId()) input.id = cartId();
    
    let query = qs.parse(window.location.search.substring(1));
    if (query.cart_id) input.id = query.cart_id;

    return {
      variables: { input },
    };
  },
});
