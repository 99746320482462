import React from "react";
import Icon from "icons";
import Play from "icons/play";
import { white } from "base/colors";
import Caret from "icons/caret";
import { Link } from "react-router-dom";
import {
  Container,
  ProjectDetails,
  LinkWrapper,
  ProjectTitle,
  Designer,
  //Image,
  Info,
  MarketSegment,
  IconWrapper,
  ImageWrapper
} from "./style";
import Image from "shared/blocks/products/tabs/card/image";
//TODO remove check here when elastic search has the image stuff
const Project = ({ project, noSave, hasVideo = false, props }) => {
  if (!project) return null;
  let category = project.category || {};

  return (
    <Container>
      <ImageWrapper>
        <Image project={project} noSave={noSave} />
        {hasVideo ? (
          <IconWrapper>
            <Play fill={white} />
          </IconWrapper>
        ) : null}
      </ImageWrapper>
      <ProjectDetails>
        <Info>
          <MarketSegment>
            <Link to={`/projects/search/${category.slug}`}>
              <Icon name="project-studio" />
              {category.name}
            </Link>
          </MarketSegment>
          <Link to={`/project/${project.slug}`}>
            <ProjectTitle>{project.name}</ProjectTitle>
          </Link>
          <Designer>{project.concept}</Designer>
        </Info>
        <LinkWrapper>
          <Link to={`/project/${project.slug}`}>
            Explore the project
            <Caret />
          </Link>
        </LinkWrapper>
      </ProjectDetails>
    </Container>
  );
};
export default Project;
