import React from 'react';
import { ListItem, LinkArrowWrapper } from '../style';
import {
  NewsPaper,
  CommunitySocialIconList,
  CommunitySocialText,
  CommunitySubNav,
  CommunitySubNavListContainer,
  CommunitySubNavTitle,
  CommunitySubNavWrapper,
  YouTube,
  Facebook,
  Pinterest,
  LinkedIn,
  Instagram,
} from './style';
import Social from './social';
import { Link } from 'react-router-dom';
import LinkArrow from 'shared/layout/arrow-link';

export default class Community extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { show } = this.state;
    let { data = {}, hideDropdown } = this.props;
    let { topMarketSegments, featuredPress } = data.menus;

    return (
      <ListItem
        onMouseEnter={() => {
          if (hideDropdown) return;
          //show the nav after half second delay due to client request ....
          this.hide = false;

          setTimeout(
            () => (this.hide ? null : this.setState({ show: true })),
            250,
          );
        }}
        onMouseLeave={() => {
          if (hideDropdown) return;
          this.hide = true;
          this.setState({ show: false });
        }}
        onClick={() => this.setState({ show: false })}
      >
        <Link to="/community">
          <NewsPaper />
          COMMUNITY
        </Link>
        <CommunitySubNavWrapper show={show}>
          <CommunitySubNav>
            <div>
              <CommunitySubNavListContainer>
                <CommunitySubNavTitle>Featured Press</CommunitySubNavTitle>
                <ul>
                  {featuredPress.links.map((item, index) => (
                    <li key={index}>
                      <Link to={item.path}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
                <CommunitySocialIconList>
                  <li>
                    <a href="http://www.facebook.com/pages/Architectural-Systems-Inc/134671993210225?v=app_4949752878#!/pages/Architectural-Systems-Inc/134671993210225">
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a href="http://instagram.com/archsystems">
                      <Instagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/user/Archsystems/videos">
                      <YouTube />
                    </a>
                  </li>
                  <li>
                    <a href="http://pinterest.com/archsystems/">
                      <Pinterest />
                    </a>
                  </li>
                  <li>
                    <a href="http://www.linkedin.com/company/architectural-systems-inc">
                      <LinkedIn />
                    </a>
                  </li>
                </CommunitySocialIconList>
                <CommunitySocialText>Follow. Tag. Share.</CommunitySocialText>

                <CommunitySubNavTitle>Latest</CommunitySubNavTitle>
                <ul>
                  {topMarketSegments
                    ? topMarketSegments.links.map((item, index) => (
                        <li key={index}>
                          <Link to={item.path}>{item.name}</Link>
                        </li>
                      ))
                    : null}
                </ul>
              </CommunitySubNavListContainer>
              <Social />
            </div>
            <LinkArrowWrapper>
              <LinkArrow text="Explore All Community" to="/community" />
            </LinkArrowWrapper>
          </CommunitySubNav>
        </CommunitySubNavWrapper>
      </ListItem>
    );
  }
}
