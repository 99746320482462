import React from 'react';
import styled from 'react-emotion';

export default styled(
  ({
    wrap,
    center,
    dropToColumn,
    margin,
    column,
    spaceBetween,
    alignItemsCenter,
    alignItemsStart,
    ...props
  }) => <div {...props} />,
)`
  display: flex;
  ${props => (props.alignItemsCenter ? 'align-items: center;' : null)};
  ${props => (props.alignItemsStart ? 'align-items: flex-start;' : null)};
  ${props => (props.alignItemsEnd ? 'align-items: flex-end;' : null)};
  ${props => (props.center ? 'justify-content: center;' : null)};
  ${props => (props.spaceBetween ? 'justify-content: space-between;' : null)};

  @media (max-width: 768px) {
    ${props => (props.dropToColumn ? 'flex-direction: column;' : null)};
  }

  ${props => (props.wrap ? `flex-wrap: wrap;` : null)};
  flex-direction: ${props => (props.column ? 'column' : 'row')};

  @media (min-width: 768px) {
    ${props => (props.margin ? `margin: 0px 25px 0px 25px;` : null)};
  }
`;
