import React from 'react';
import ContentLoader from 'react-content-loader';

export default class Loader extends React.Component {
  constructor() {
    super();
    this.state = { show: false };
  }

  componentDidMount() {
    setTimeout(
      () => (this.unmount ? null : this.setState({ show: true })),
      this.props.timeout || 250,
    );
  }
  componentWillUnmount() {
    this.unmount = true;
  }
  render() {
    if (!this.state.show) return null;

    return (
      <div style={{ width: '100%', padding: 20 }}>
        <ContentLoader
          height={300}
          width={800}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="40" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="80" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="120" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="160" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="200" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="240" rx="5" ry="5" width="790" height="20" />
          <rect x="0" y="280" rx="5" ry="5" width="790" height="20" />
        </ContentLoader>
      </div>
    );
  }
}
