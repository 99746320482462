import { graphql } from "react-apollo";
import mutator from "shared/gql/mutate";
import gql from "graphql-tag.macro";
import { query as searchOrders } from "views/account/my-orders/gql/searchOrders";

export const query = gql`
  mutation CreatePurchaseOrder(
    $input: CreatePOInput!
    $products: [QuoteItemInput!]!
  ) {
    createPurchaseOrder(input: $input, products: $products)
  }
`;
export default graphql(query, {
  props: ({ mutate }) => ({
    createPurchaseOrder: variables => {
      return mutator(() =>
        mutate({
          variables,
          context: {
            hasUpload: true
          },
          refetchQueries: [
            {
              query: searchOrders,
              variables: { input: { queryString: "", limit: 5, page: 1 } }
            }
          ]
        })
      );
    }
  })
});
