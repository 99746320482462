import React from 'react';
import { Button } from '../style';
import QuickView from './quick-view';
import Modal from 'shared/modals/desktop';

export default class QuickViewModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { data } = this.props;
    let { show } = this.state;

    const close = () => this.setState({ show: false });

    return (
      <React.Fragment>
        <Button onClick={() => this.setState({ show: !show })}>
          Quick View
        </Button>
        <Modal show={show} title="Quick View" onClose={close}>
          <QuickView data={data} close={close} />
        </Modal>
      </React.Fragment>
    );
  }
}
