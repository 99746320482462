import React, { useState, useEffect } from "react";
import Item from "./item";
import multipleProducts from "./products";
import { Table, OrderTable, TableTitle } from "./style";

const RequestQuote = ({
  data,
  items,
  onValue,
  onDelete,
  title,
  isValid = () => true,
  isDisabled,
  noMinimum
}) => {
  let [valids, setValids] = useState([true]);

  // these two effects kind of suck, only fix would be to move this query up to the purchasing materials
  // it is ideal but dont have time :/

  // add a default of true to the validity when an item is added
  useEffect(() => {
    if (!data.multipleProducts || !data.multipleProducts.length) return;

    if (data.multipleProducts.length > valids.length) {
      isValid(valids.filter(Boolean).length === valids.length);
      setValids([...valids, true]);
    }
  }, [data.multipleProducts]);

  if (!data.multipleProducts) return null;
  return (
    <OrderTable>
      <TableTitle>{title ? title : "Requested Materials"}</TableTitle>
      <Table>
        {data.multipleProducts.length ? (
          data.multipleProducts.map((item, index) => {
            let row = items.find(row => row.id === item.id);
            if (!row) return null;

            return (
              <Item
                isDisabled={isDisabled}
                key={item.id}
                item={noMinimum ? { ...item, minimumQty: 1 } : item}
                isValid={valid => {
                  valids[index] = valid;
                  setValids([...valids]);
                  if (
                    valids.filter(Boolean).length ===
                    data.multipleProducts.length
                  ) {
                    isValid(true);
                  } else {
                    isValid(false);
                  }
                }}
                onDelete={() => {
                  onDelete(item.id);
                  let deleteIndex = data.multipleProducts.findIndex(
                    current => current.id === item.id
                  );
                  setValids(valids.filter((_, index) => index !== deleteIndex));
                }}
                onChange={qty => {
                  onValue({ id: item.id, qty });
                }}
                qty={row.qty}
              />
            );
          })
        ) : (
          <p>Add some materials below</p>
        )}
      </Table>
    </OrderTable>
  );
};

export default multipleProducts(RequestQuote);
