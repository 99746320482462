import React from "react";
import Footer from "base/footer";
import { Address } from "base/footer/style";
import { SiteContainer } from "shared/layout/base/base";

export default class FooterAddress extends React.Component {
  render() {
    let { address } = this.props;

    return (
      <Address>
        <SiteContainer>{address}</SiteContainer>
      </Address>
    );
  }
}
