import React from "react";
import { BaseForm } from "react-validify";
import {
  Qty,
  SampleRequest,
  Title,
  Container,
  SubTitle,
  SampleTypeDropdown,
  Option
} from "./style";
import Dropdown from "shared/layout/form/dropdown";
import { Button } from "shared/layout/base/base";
import addSample from "views/product/gql/add-sample";
import { AlertNotification } from "shared/layout/base/base";
import storage from "shared/storage";
import { Redirect } from 'react-router-dom';

class AddSample extends React.Component {
  constructor() {
    super();
    this.state = { values: {} };
  }

  render() {
    let { values, errors, loading } = this.state;
    let { samples, title, add, close, collection } = this.props;

    if (!samples) return null;
    if (!storage.get("user"))
      return (
        <Redirect
          to={`/auth/login?redirect=${window.location.pathname}`}
        />
      );
      
    if(storage.get("user").data_entry_access ||
      storage.get("user").special_users ||
      storage.get("user").order_access ||
      storage.get("user").quote_access ||
      storage.get("user").user_role === "spartan_sales" ||
      storage.get("user").user_role === "subscriber" ||
      collection.title === "Wellness Safety Shields")
    {
      return <Redirect to={`/`} />;
    }
    return (
      <Container>
        <Title>{title}</Title>
        <SubTitle>Request Samples</SubTitle>
        <SampleRequest>
          <BaseForm
            values={values}
            errors={errors}
            rules={{ type: "required", qty: "required" }}
            onValues={values => this.setState({ values })}
            onErrors={errors => this.setState({ errors })}
          >
            <SampleTypeDropdown>
              <Dropdown
                searchable={false}
                name="type"
                hideLabel
                placeholder="Select Samples"
                items={samples.map(item => ({
                  label: item.name,
                  value: item.id,
                  image: item.image ? item.image : ""
                }))}
                optionRenderer={props => (
                  <Option>
                    <img src={props.image} alt={props.label} />
                    <span>{props.label}</span>
                  </Option>
                )}
              />
            </SampleTypeDropdown>
            <Qty>
              <Dropdown
                searchable={false}
                name="qty"
                hideLabel
                placeholder="QTY"
                items={[
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 }
                ]}
              />
            </Qty>
            <Button
              submit
              onClick={async values => {
                this.setState({ loading: true });
                await add({
                  samples: [
                    {
                      qty: parseFloat(values.qty),
                      product_id: parseInt(values.type)
                    }
                  ]
                });
                close();
              }}
              loading={loading}
            >
              ADD SAMPLE TO CART
            </Button>
          </BaseForm>
        </SampleRequest>

        {values.type &&
        !samples.find(sample => sample.id === values.type).stock ? (
          <AlertNotification>
            Item is currently on backorder, estimated lead time is 30 days from
            requested date.{" "}
            <a
              href={`/products/search/${collection.subtitle
                .toLowerCase()
                .replace(/ /g, "-")}`}
              style={{ color: "#61A60D" }}
            >
              Review Options.
            </a>
          </AlertNotification>
        ) : null}
      </Container>
    );
  }
}
export default addSample("sample")(AddSample);
