import React from 'react';
import Line from 'shared/layout/line';
import { backgroundGrey } from 'base/colors';
import { Button, ButtonWhiteGreenBorder } from 'shared/layout/base/base';
import { Container, Image, Content, ButtonContainer } from './style';

//Used for add FAQ and add Review
export default ({ data, children, loading, close }) => (
  <React.Fragment>
    {children({
      render: children => (
        <Container loading={loading}>
          <Image>
            <img
              src={
                data.gallery && data.gallery[0]
                  ? data.gallery[0].full
                  : 'http://via.placeholder.com/254x254'
              }
              height="254px"
              alt={data.sku}
            />
          </Image>

          <Content>{children}</Content>
        </Container>
      ),
      buttons: onClick => (
        <React.Fragment>
          <Line color={backgroundGrey} />

          <ButtonContainer>
            <ButtonWhiteGreenBorder onClick={close}>
              Cancel
            </ButtonWhiteGreenBorder>
            <Button submit onClick={onClick}>
              Save To Vision Board
            </Button>
          </ButtonContainer>
        </React.Fragment>
      ),
    })}
  </React.Fragment>
);
