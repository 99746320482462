import React from "react";
import { BaseForm } from "react-validify";
import { Qty, SampleRequest, SampleTypeDropdown } from "./style";
import { Button } from "shared/layout/base/base";
import Dropdown from "shared/layout/form/dropdown";
import addProduct from "views/product/gql/add-product";
import { AlertNotification } from "shared/layout/base/base";
import { ExcludedProducts } from "shared/excludedProducts";
import storage from "shared/storage";

class AddSample extends React.Component {
  constructor() {
    super();
    this.state = { values: {} };
  }

  componentDidMount() {
    if (this.props.product.samples.length === 1)
      this.setState({
        values: {
          type: this.props.product.samples[0].key
        }
      });
  }

  render() {
    let { add, product } = this.props;
    let { values, errors, loading } = this.state;
    let { samples } = this.props.product;

    if (!samples) return null;
    if (product.role === "subscriber") return null;

    console.log(product)

    return (
      <React.Fragment>
        {(( storage.get("user") && storage.get("user").special_users && ExcludedProducts.includes(product.sku)) && process.env.REACT_APP_VERSION_6 == "true") ? null : (
        <SampleRequest>
          <BaseForm
            values={values}
            errors={errors}
            rules={{ type: "required", qty: "required" }}
            onValues={values => this.setState({ values })}
            onErrors={errors => this.setState({ errors })}
          >
            <SampleTypeDropdown>
              <Dropdown
                searchable={false}
                name="type"
                hideLabel
                placeholder="Select Type"
                items={samples.map(item => ({
                  label: item.label,
                  value: item.key
                }))}
              />
            </SampleTypeDropdown>
            <Qty>
              <Dropdown
                searchable={false}
                name="qty"
                hideLabel
                placeholder="QTY"
                items={[
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 }
                ]}
              />
            </Qty>

            <Button
              submit
              onClick={async values => {
                this.setState({ loading: true });
                await add({
                  samples: [{ ...values, product_id: parseInt(values.type) }]
                });
                this.setState({
                  loading: false,
                  values: { type: samples.length === 1 ? values.type : null }
                });
              }}
              loading={loading}
            >
              ADD SAMPLES TO CART
            </Button>
          </BaseForm>
        </SampleRequest> )}
        {values.type &&
        !samples.find(sample => sample.key === values.type).stock ? (
          <AlertNotification>
            Item is currently on backorder, estimated lead time is 30 days from
            requested date.
          </AlertNotification>
        ) : null}
      </React.Fragment>
    );
  }
}

export default addProduct("sample")(AddSample);
