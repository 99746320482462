import styled, { css } from 'react-emotion';
import * as Colors from 'base/colors';
import { Tablet, Phone } from 'shared/layout/base/base';

export const MenuWrapper = styled('div')`
  position: relative;
  height: 100vh;
  overflow: auto;
  z-index: ${props => (props.show ? '-1' : '1')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};

  @media print {
    display: none;
  }
`;

export const Menu = styled('div')`
  position: relative;
  max-width: 340px;
  width: calc(100% - 40px);
  height: 100%;
  background-color: ${Colors.white};
  transform: ${props => (props.show ? 'translateX(0)' : 'translateX(-100%)')};
  opacity: ${props => (props.show ? '1' : '0')};
  transition: transform 0.25s;
  z-index: 1;
  border-top: solid 1px #979797;
  overflow: hidden;

  @media (${Tablet}) {
    transform: ${props => (props.show ? 'translateX(0)' : 'translateX(100%)')};
    margin-left: auto;
  }
`;

export const MenuList = styled('div')`
  padding: 0;
  list-style: none;
  overflow: auto;
  height: 100%;
  padding-bottom: 200px;

  li {
    a {
      padding: 20px;
      display: block;
      text-decoration: none;
      color: ${Colors.lightTextGrey};
      transition: color 0.25s;
      font-size: 13px;
      font-weight: 500;
      transition: font-size 0.25s;

      @media (${Tablet}) {
        font-size: 16px;
        border-bottom: 1px solid #e6e6e6;
      }

      &:hover {
        color: ${Colors.ctaGreen};
      }
    }
  }
`;

export const MegaNav = styled('ul')`
  padding: 0;
  list-style: none;
  display: block;
  @media (${Tablet}) {
    display: none;
  }
`;

export const CaracIcon = css`
  width: 8px;
  height: 13px;
  fill: ${Colors.ctaGreen};
  margin-left: auto;
`;

export const MegaNavButton = styled('button')`
  padding: 20px;
  font-size: 13px;
  font-weight: 500;
  color: ${Colors.titleGrey};
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media print {
    display: none;
  }

  &:hover {
    color: ${Colors.ctaGreen};
  }

  &:focus {
    outline: none;
  }
`;

export const SecondaryNav = styled('div')`
  width: 340px;
  height: 100vh;
  width: 340px;
  background-color: ${Colors.white};
  overflow-y: auto;
  position: absolute;
  top: 0;
  transition: transform 0.25s;
  transform: ${props => (props.show ? 'translateX(0)' : 'translateX(100%)')};

  @media (${Phone}) {
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }

  > a > span {
    display: block;
    color: ${Colors.ctaGreen};
    text-transform: uppercase;
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
  }
  padding-bottom: 170px;
`;

export const SecondaryNavList = styled('ul')`
  padding: 0;
  list-style: none;
  li {
    &:first-child {
      button {
        padding-top: 20px;
      }
    }
  }
`;

export const SecondaryNavLink = styled('span')`
  display: block;

  span {
    font-size: 13px;
    font-weight: 500;
    color: ${Colors.lightTextGrey};
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    text-decoration: none;

    svg {
      fill: ${Colors.ctaGreen};
    }
  }
`;
