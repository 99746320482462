import React from 'react';
import Community from './community';
import Materials from './materials';
import Boards from './boards';
import { Wrapper, List } from './style';
import ProjectStudio from './project-studio';
import SideMenu from '../side-menu';

export default ({ show, data, toggleSubmenu, showSubmenu, toggleShow }) => (
  <Wrapper show={show} id="navigation">
    <List>
      <Materials hideDropdown={show} data={data} />
      <ProjectStudio hideDropdown={show} data={data} />
      <Community hideDropdown={show} data={data} />
      <Boards />
    </List>
    <SideMenu
      show={show}
      data={data}
      toggleShow={toggleShow}
      showSubmenu={showSubmenu}
      toggleSubmenu={toggleSubmenu}
      offcanvas={data.menus.offcanvas}
    />
  </Wrapper>
);
