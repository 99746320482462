import React from 'react';
import Highlighter from 'react-highlight-words';
import { Content, Category } from './style';

export default ({ item, isHighlighted, term }) => {
  return (
    <React.Fragment>
      {item.name ? (
        <Category isHighlighted={isHighlighted}>{item.name}</Category>
      ) : (
        <Content isHighlighted={isHighlighted}>
          <Highlighter
            searchWords={term.split('')}
            autoEscape={true}
            textToHighlight={item.post_title}
          />
        </Content>
      )}
    </React.Fragment>
  );
};
