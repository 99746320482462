import styled from 'react-emotion';
import * as Colors from 'base/colors';
import { Tablet, EncodeSans } from 'shared/layout/base/base';

export const Button = styled('button')`
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  color: ${Colors.lightTextGrey};
  height: 100%;
  padding: 5px 20px 7px 0px;
  border-bottom: 3px solid transparent;
  transition: border-bottom-color 0.15s;

  &:hover {
    border-bottom-color: ${Colors.ctaGreen};
  }

  &:focus {
    outline: none;
  }
`;

export const InfoWrapper = styled('div')`
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: ${Colors.white};
  position: absolute;
  right: 0;
  transform: ${props => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.25s;
  z-index: 3;

  @media (${Tablet}) {
    width: 250px;
    top: 100%;
    height: auto;
    transform: ${props =>
      props.show ? 'scaleY(1) translateX(-50%)' : 'scaleY(0) translateX(-50%)'};
    transition: transform 0.25s ease-in-out, height 0.25s ease-in-out,
      opacity 0.25s;
    opacity: ${props => (props.show ? '1' : '0')};
    transform-origin: top center;
    z-index: 3;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #e6e6e6;
    left: calc(50% - 18px);
  }
`;

export const ProfileWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 20px 20px 0;

  svg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;

export const ProfileInfo = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoLinks = styled('ul')`
  padding: 20px;
  list-style: none;
  border-bottom: 1px solid ${Colors.borderColor};

  li {
    &:first-child {
      a {
        padding-top: 0;
      }
    }

    &:last-child {
      a {
        padding-bottom: 0;
      }
    }
  }

  a {
    display: block;
    font-family: 'Encode Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: ${Colors.titleGrey};
    padding: 10px 0;
    text-decoration: none;
    transition: color 0.25s;

    &:hover {
      color: ${Colors.ctaGreen};
    }
  }
`;

export const ProfileText = styled('span')`
  color: ${Colors.titleGrey};
  font-family: 'Hind Siliguri', sans-serif;
  font-size: 13px;
  font-weight: 600;
`;

export const ProfileSubtext = styled('span')`
  color: ${Colors.titleGrey};
  font-family: 'Encode Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
`;

export const SignOut = styled('div')`
  margin: 20px;
  background-color: ${Colors.ctaGreen};
  padding: 10px 20px;
  color: ${Colors.white};
  cursor: pointer;
  border-radius: 3px;
  border: 2px solid ${Colors.ctaGreen};
  transition: background-color 0.25s, color 0.25s;
  font-family: ${EncodeSans};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  width: calc(100% - 40px);

  &:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    padding: 10px 20px;
  }

  &:hover {
    background-color: ${Colors.white};
    color: ${Colors.ctaGreen};
  }
`;
