import styled from "react-emotion";
import * as Colors from "base/colors";
import { Tablet, Montserrat } from "shared/layout/base/base";

export const Heading = styled("h1")`
  display: flex;
  margin: 40px 20px 20px 0;
  align-items: flex-start;
  justify-content: center;

  @media (${Tablet}) {
    margin: 80px 20px 40px 0;
  }

  font-family: ${Montserrat};
  font-weight: 200 !important;
  text-align: center;
  color: #fff;
  padding-bottom: 10px;
  position: relative;
  font-size: 20px;
  z-index: 2;

  @media (${Tablet}) {
    font-size: 45px;
  }

  @media (max-width: 1119px) {
    margin: 40px 20px 20px 20px;
  }

  svg {
    width: 40px;
    height: 40px;
    fill: ${Colors.brandHighLightPurple};
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
