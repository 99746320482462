import React from 'react';
import Field from './field';
import { css } from 'emotion';
import { brandHighLightPurple } from 'base/colors';

const Textarea = css`
    -webkit-appearance: none;
    background-color: #fff;
    border: solid 1px #e0dfdf;
    width: 100%;
    border-radius: 3px;
    font-size: 13px;
    height: 100px;

    padding: 15px 20px;
    

    &:focus {
      border-color: ${brandHighLightPurple};
      outline: none;
    }
  }
`;

export default props => {
  let {
    label,
    type,
    error,
    success,
    customOnBlur,
    onBlur,
    onEnter,
    hideLabel,
    ...rest
  } = props;
  return (
    <Field {...props} onEnter={onEnter}>
      <textarea
        {...rest}
        className={Textarea}
        type={type || 'text'}
        onBlur={e => {
          onBlur(e);
          if (customOnBlur) customOnBlur(e);
        }}
      />
    </Field>
  );
};
