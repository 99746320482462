import React from 'react';
import styled from 'react-emotion';
import { dropdownGrey } from 'base/colors';
import * as Colors from 'base/colors';
import { Tablet, Montserrat, EncodeSans, Phone } from 'shared/layout/base/base';

export const Container = styled(({ collectionPage, ...props }) => (
  <div {...props} />
))`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;

  @media (${Tablet}) {
    padding: 0;
    float: ${props => (props.collectionPage ? `right` : `none`)};
  }

  > div {
    width: 100%;

    @media (${Tablet}) {
      width: auto;
    }
  }
`;

export const Wrapper = styled('div')`
  display: block;
  padding: 20px;

  @media (min-width: 768px) {
    float: right;
    padding: 0;
  }
`;

export const TitleContainer = styled('div')`
  @media (max-width: 768px) {
    margin-top: 20px;
    padding: 0 20px;
  }
`;

export const Title = styled(({ collectionsPage, children, ...props }) => (
  <h1 {...props}>{children}</h1>
))`
  font-family: ${Montserrat};
  font-size: 20px;
  font-weight: 200;
  margin-bottom: ${props => (props.collectionsPage ? `0` : `20px`)};
  padding-bottom: 10px;
  border-bottom: 2px solid ${Colors.brandPurple};

  @media (${Tablet}) {
    font-size: 30px;
  }
`;

export const DropdownContainer = styled('div')`
  min-width: 208px;
  display: none;

  @media (${Tablet}) {
    display: block;
  }
`;

export const Text = styled('p')`
  margin: 0 10px 0 0;
  font-family: ${EncodeSans};
  font-size: 13px;
  flex-grow: 1;

  @media (${Phone}) {
    order: 2;
    text-align: center;
  }
`;

export const PrevLink = styled('div')`
  @media (${Phone}) {
    order: 1;
  }
`;

export const NextLink = styled('div')`
  @media (${Phone}) {
    order: 3;
  }
`;

export const PaginateButton = styled('div')`
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  border: solid 1px #e0dfdf;
  background-color: ${dropdownGrey};
  ${props => (props.marginLeft ? 'margin-left: 10px' : '')};
`;
