import gql from "graphql-tag.macro";
export const query = gql`
  fragment product on Product {
    id
    sku

    name
    reviewCount
    reviewStars

    hasColors
    hasSamples
    hasOptions

    sqFtPerBox
    unitOfMeasure

    featuredImage {
      full
    }

    collection {
      name
      slug
    }

    upsells {
      id
      sku
      name
      image {
        full
        thumbnail
        shop_thumbnail
      }
    }

    image {
      thumbnail
      full
      shop_thumbnail
    }

    gallery {
      thumbnail
      full
      shop_thumbnail
    }

    crosssell {
      id
      name
      sku
      image {
        full
        thumbnail
        shop_thumbnail
      }
      tiers {
        range
        price
      }
      collection {
        name
      }
      reviewCount
      reviewStars
      material
    }
  }
`;
export default query;
